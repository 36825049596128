/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('TaskTemplateController', ['$scope', '$http', '$state', '$compile', '$filter', 'DTColumnBuilder', 'apiAdmin', 'AnnexaFormlyFactory', 'CommonAdminModals', '$rootScope', 'HeaderService', 'AdminFactory', 'RestService', 'AnnexaModalFactory', 'TableFilter','Language', 'HelperService', 'ErrorFactory', 'dialogs', 'GlobalDataFactory', 'CustomFieldFactory', 'TaskFactory', '$q',
        function ($scope, $http, $state, $compile, $filter, DTColumnBuilder, apiAdmin, AnnexaFormlyFactory, CommonAdminModals, $rootScope, HeaderService, AdminFactory, RestService, AnnexaModalFactory, TableFilter, Language, HelperService, ErrorFactory, dialogs, GlobalDataFactory, CustomFieldFactory, TaskFactory, $q) { 

            $scope.languageColumn = Language.getActiveColumn();
            $scope.descriptionLanguageColumn = "descriptionLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);

            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement($scope.descriptionLanguageColumn, 'text', 0, 'global.literals.name');
            $scope.tfilter.addElement('type', 'select', 1, 'global.literals.type', 3, HelperService.addAllSelect(angular.copy(GlobalDataFactory.taskTypes),Language.getActiveColumn(), $scope.app.language), true, Language.getActiveColumn(), false)/*.setFilterCallAux(true)*/;

            $scope.dtDefTaskTemplate = new TableData('tasks', 'TaskTemplate', './api/common/byfiler').setSort([[3, 'asc']]).setSortName('days');

            var TaskTemplateNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name',  $scope.descriptionLanguageColumn);
            var TaskTemplateTypeColumn = new DatabaseTranslatedColumn($filter, 'global.literals.type', Language.getActiveColumn());
            var TaskTemplateActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','viewTaskTemplate([data])','fa-eye'),[
                new ActionButton('global.commonAdmin.datatable.options.delete','deleteTaskTemplate([data])')
                ]);
            $scope.getFilterCall = function(){
                var filterCall = $scope.tfilter.getFilterCall();
                if(filterCall.entryType && filterCall.entryType.id){
                    filterCall.entryType = filterCall.entryType.id;
                }
                return filterCall;
            };

            $scope.getFilterCallAux = function(){
                var filterCallAux = $scope.tfilter.getFilterCallAux();
                if(filterCallAux.procedure && filterCallAux.procedure.id){
                    filterCallAux.procedure = filterCallAux.procedure.id;
                }
                filterCallAux.langColumn = Language.getActiveColumn();
                return filterCallAux;
            };


            $scope.tableTaskTemplate = {
                dtColumns:[
                    DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn(TaskTemplateNameColumn.getColumn()).withTitle(TaskTemplateNameColumn.getTitle()).notSortable(),
                    DTColumnBuilder.newColumn('type.' + TaskTemplateTypeColumn.getColumn()).withTitle(TaskTemplateTypeColumn.getTitle()).renderWith(TaskTemplateTypeColumn.getRender),
                    DTColumnBuilder.newColumn('days').withTitle($filter('translate')('global.literals.days')),
                    DTColumnBuilder.newColumn('id').withOption('width', '120px').withOption('className', 'text-center').withTitle(TaskTemplateActionColumn.getTitle()).notSortable().renderWith(TaskTemplateActionColumn.getRender)
                ],
                filterCall: $scope.getFilterCall(),
                filterCallAux : { langColumn: Language.getActiveColumn() },
                reloadInternalData: function (resetPaging) {
                    this.filterCall = $scope.getFilterCall();
                    this.filterCallAux =$scope.getFilterCallAux ();
                    this.table.reloadData(function callbackInstance(json){}, resetPaging);
                }
            };
            // ----------------

            // Modal de nou Perfil
            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.task_templates') {
                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-task-template','fa fa-plus','global.commonAdmin.newTemplate',undefined,'openNewTaskTemplate')
                    ];
                }
            });
            HeaderService.changeState($state.current);

        $scope.doSearchFilter=function(queued) {
            $scope.tableTaskTemplate.reloadInternalData(true);
        }

        // Edició plantilles
        // ---------------
        $scope.viewTaskTemplate = function (id) {
            RestService.findOne('TaskTemplate', id)
                .then(function (data) {
                    var languages = $rootScope.app.languagedef;
                    var modal = angular.copy(CommonAdminModals.taskTemplateEdit);
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = $scope.descriptionLanguageColumn;
                    modal.alerts = [];
                    modal.printTaskTemplate = function(){
                        if(modal.data && modal.data[$scope.descriptionLanguageColumn]){
                            return modal.data[$scope.descriptionLanguageColumn];
                        }
                        return '';
                    }

                    modal.languageColumn = Language.getActiveColumn();
                    modal.taskTypes = GlobalDataFactory.taskTypes;

                    modal.updateTaskStatuses = function(taskType) {
                    	if (taskType && taskType.id && modal.data.type && modal.data.type.id && taskType.id != modal.data.type.id) {
                        	modal.data.status = undefined;
                    		modal.data.type = taskType;
                    	}
                    	modal.taskStatuses = [];

                    	if (taskType.statuses) {
                			modal.taskStatuses = taskType.statuses;
                		}
                		if (taskType.parent && taskType.parent.statuses) {
                			modal.taskStatuses = modal.taskStatuses.concat(taskType.parent.statuses);
                			modal.taskStatuses = $linq(modal.taskStatuses).orderBy("x => x." + modal.languageColumn, linq.caseInsensitiveComparer).toArray();
                		}
                    }
                    
                    modal.updateTaskStatuses(modal.data.type);
                    
                    modal.printTaskType = function (modelValue) {
                    	return modelValue[modal.languageColumn];
                    }

                    modal.printTaskStatus = function (modelValue) {
                    	if (modelValue) {
                    		return modelValue[modal.languageColumn];	
                    	} else {
                    		return '';
                    	}
                    }
                    
                    modal.updateCustomField = function (field, callBack) {
                        var model = {
                            taskTemplate: { id: modal.data.id },
                            customField: { id: field.customField.id },
                            required: field.required,
                            viewOrder: field.viewOrder,
                            value: field.value,
                            id: field.id,
                            descriptionLanguage1: field.descriptionLanguage1,
                            descriptionLanguage2: field.descriptionLanguage2,
                            descriptionLanguage3: field.descriptionLanguage3
                        };

                        AdminFactory.updateTaskTemplateCustomField(model)
                            .then(function(data) {
       	                        if(callBack && field.customField.frontendType == 'SELECT') {
	       	                        callBack();
                                }
                            }).catch(function(error) {
                                //Empty
                        });
                    };

                    modal.updateTaskTemplate = function(val, prop){
                        if(prop) {
                            if(prop == 'descriptionLanguage') {
                                if(languages){
                                    var valid = true;
                                    angular.forEach(languages, function (value, key) {
                                        if (!modal.data[prop + value.column.slice(-1)]) {
                                            valid = false;
                                        }
                                    });
                                }
                                if(!valid){
                                    return $filter('translate')('global.validation.required');
                                }
                            }else {
                                if(val){
                                    modal.data[prop] = val;
                                }else{
                                    return $filter('translate')('global.validation.required');
                                }
                            }
                            AdminFactory.insertOrUpdateTaskTemplate(modal.data).then(function (data) {
                                $scope.tableTaskTemplate.reloadInternalData(false);
                            }).catch(function (error) {
                                var errorTranslate = { msg: ErrorFactory.getErrorMessage('taskTemplates', 'save', error.data) };
                                modal.alerts.push({ msg: $filter('translate')(errorTranslate.msg)});
                            });
                        };
                    };
                    AnnexaModalFactory.showModal('modalEditTaskTemplate', modal);
                 })
        };
        // ---------------

        // Desat plantilles
        // --------------
        $scope.completedNew = function($modal) {
            if($modal.annexaFormly.form.$valid) {
            	var calculateCFTGroups = function(newGroups){
                	var groups = [];
                	if(newGroups){
                		_.forEach(newGroups, function(ng){
                			_.forEach(ng.groups, function(g){
                				g.groups = calculateCFTGroups(g.newGroups); 
                				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                				groups.push(g);
                			})
                		})
                	}
                	return groups;
                }
            	
                var data = $modal.annexaFormly.model.modal_body;

                var model = {
                    descriptionLanguage1: 'NotTranslated',
                    descriptionLanguage2: 'NotTranslated',
                    descriptionLanguage3: 'NotTranslated'
                };
                if(data.descriptionLanguage1) {
                    model.descriptionLanguage1 = data.descriptionLanguage1;
                }
                if(data.descriptionLanguage2) {
                    model.descriptionLanguage2 = data.descriptionLanguage2;
                }

                if(data.descriptionLanguage3) {
                    model.descriptionLanguage3 = data.descriptionLanguage3;
                }

                if(data.days){
                    model.days = data.days;
                }
                
                if (data.type) {
                	model.type = { id: data.type };
                }
                
                if (data.status) {
                	model.status = { id: data.status }
                }
                
            	var setValueCustomFields = function(customFields, taskType, cf) {
                    _.forEach(customFields, function(taskTypeField) {
                    	var taskTemplateCustomField = angular.copy(taskTypeField);
                    	taskTemplateCustomField.id = undefined;
                    	taskTemplateCustomField.customField = { id: taskTypeField.customField.id };
                    	taskTemplateCustomField.taskType = { id: taskTypeField.taskType.id };
                    	taskTemplateCustomField.groups = calculateCFTGroups(taskTemplateCustomField.newGroups);

                        var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + taskType.id  + "_" + taskTypeField.customField.id + "'");

                        if(cfValue) {
                            if(taskTypeField.customField.frontendType == 'CHECKBOX'){
                                var custom_field_selected = $linq(cf).where(function(x){
                                    if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                                ).toArray();
                                if(custom_field_selected && custom_field_selected.length > 0){
                                    taskTemplateCustomField.value = '[';
                                    _.forEach(custom_field_selected, function (item, key) {
                                        if(item.value) {
                                            if(taskTypeField.customField && taskTypeField.customField.listValues && taskTypeField.customField.listValues.length > 0) {
                                                var custom_field_value_selected = $linq(taskTypeField.customField.listValues).where(function(x){
                                                    if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                                ).toArray();
                                                if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                    taskTemplateCustomField.value += ((taskTemplateCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                                }
                                            }
                                        }
                                    });
                                    taskTemplateCustomField.value += ']';
                                }
                            }else if(Array.isArray(cfValue.value)) {
                                if(taskTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                    taskTemplateCustomField.value = '[';

                                    _.forEach(cfValue.value, function (item) {
                                        taskTemplateCustomField.value += ((taskTemplateCustomField.value == '[') ? item : ',' + item);
                                    });

                                    taskTemplateCustomField.value += ']';
                                } else {
                                    taskTemplateCustomField.value = cfValue.value[0];
                                }
                            } else {
                                if(cfValue.value instanceof Date) {
                                    taskTemplateCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                                } else {
                                    taskTemplateCustomField.value = cfValue.value;
                                }
                            }
                        }

                        model.customFields.push(taskTemplateCustomField);
                    });
            	}
            	
                var cf = CustomFieldFactory.getModelValues(data.taskCustomFields);
                model.customFields = [];
                var tType = $linq(GlobalDataFactory.taskTypes).singleOrDefault(undefined, "x => x.id == " + model.type.id);
                if(tType && cf && cf.length > 0) {
                	setValueCustomFields(tType.customFields, tType, cf);
                	
                	//setegem també els valors dels customField dels tipus de tasca pare
                    if (tType.parent) {
                    	setValueCustomFields(tType.parent.customFields, tType, cf);
                    }
                }
                
                AdminFactory.insertOrUpdateTaskTemplate(model)
                    .then(function (data) {
                        $scope.tableTaskTemplate.reloadInternalData(false);
                        $modal.close();
                }).catch(function(error){
                    var errorTranslate = { msg: ErrorFactory.getErrorMessage('taskTemplates', 'save', error.data) };
                    $modal.alerts.push({ msg: $filter('translate')(errorTranslate.msg)});
                });
            }
        };
        // --------------

        // MODAL Nou Perfil
        // -----------------
        $scope.openNewTaskTemplate = function (id, readonly) {
            var data = {
                row: true,
                colClass: ' col-sm-12',
                labelClass: 'label-strong'
            };
            var modal = angular.copy(CommonAdminModals.taskTemplateNew);
            modal.annexaFormly = new AnnexaFormly();
            modal.alerts = [];
            var fields = [];
            fields.push(modal.annexaFormly.createField('descriptionLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('descriptionLanguage','descriptionLanguage','global.literals.description','horitzontal',true,true,true,$rootScope.app.languagedef),data));
            fields.push(modal.annexaFormly.createField('days','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.literals.days',true,false),data));
            var taskType = modal.annexaFormly.createField('type','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskType','id',$scope.languageColumn,GlobalDataFactory.taskTypes,true),data);
            fields.push(taskType);
            
            taskType.templateOptions.onSelected = function(type){
            	var indexFieldStatus = $linq(fields).indexOf("x => x.key == 'status'");
                if(indexFieldStatus != -1){
                	fields.splice(indexFieldStatus, 1);
                }

            	var statuses = [];
            	if (type) {
            		if (type.statuses) {
            			statuses = type.statuses;
            		}
            		if (type.parent && type.parent.statuses) {
            			statuses = statuses.concat(type.parent.statuses);
            			statuses = $linq(statuses).orderBy("x => x." + $scope.languageColumn, linq.caseInsensitiveComparer).toArray();
            		}

            		var defaultStatus = $linq(statuses).firstOrDefault(undefined, "x => x.defaultValue == true");
            		if (defaultStatus) {
            			modal.annexaFormly.model.modal_body.status = defaultStatus.id;
            		} else {
            			modal.annexaFormly.model.modal_body.status = undefined;
            		}
            	}

                var data = {
                    row: true,
                    colClass: 'col-sm-12',
                    labelClass: 'label-strong small m-b-0 ',
                    extralabel: true
                };
            	
            	var status = modal.annexaFormly.createField('status','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskStatus','id',$scope.languageColumn,statuses,true),data);
            	fields.splice(indexFieldStatus, 0, status);
            	if(type && modal.annexaFormly.model.modal_body.origTaskType !== type.id){
            		if(modal.annexaFormly.model.modal_body.taskCustomFields){
            			var keys = Object.keys(modal.annexaFormly.model.modal_body.taskCustomFields);
                        _.forEach(keys, function(key){
                        	delete modal.annexaFormly.model.modal_body.taskCustomFields[key];
                        });
            		}else{
            			modal.annexaFormly.model.modal_body.taskCustomFields = {};
            		}
            		modal.annexaFormly.model.modal_body.taskCustomFieldsShow = false;
            		var taskCustomFieldsField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'taskCustomFields'");
            		if(taskCustomFieldsField && taskCustomFieldsField.fieldGroup){
            			taskCustomFieldsField.fieldGroup.length = 0;
            		}
            		$rootScope.loadingDiv = true;
	        		try{
	        			var promises = [];
		                promises.push(RestService.findOne('TaskType', type.id));
		                promises.push(TaskFactory.calculateTaskTypeCustomField(type.id));
		                $q.all(promises)
		                .then(function(data) {
		                	if(data && data[0].data){
				        		var typeAux = JSOG.decode(data[0].data);
				        		type.customFields = typeAux.customFields;
				        		type.parent = typeAux.parent;
				        		var typeGDF = $linq(GlobalDataFactory.taskTypes).singleOrDefault(undefined, "x => x.id == " + type.id);
				        		if(typeGDF){
				        			typeGDF.customFields = typeAux.customFields;
					        		typeGDF.parent = typeAux.parent;
				        		}
				        	}
		                	if((!type && modal.annexaFormly.model.modal_body.origTaskType) || (type && modal.annexaFormly.model.modal_body.origTaskType !== type.id)){
		                		if(type.customFields){
		                			var deleteValueFromGroups = function(groups){
		                				if(groups && groups.length > 0){
		                					_.forEach(groups, function(g){
		                						if(g.groups && g.groups.length > 0){
		                							deleteValueFromGroups(g.groups);
		                						}else{
		                							g.value = undefined;
		                						}
		                					});
		                				}
		                			}
		                			_.forEach(type.customFields, function(ttcf){
		                				if(ttcf.groups && ttcf.groups.length > 0){
		                					deleteValueFromGroups(ttcf.groups);
		                				}
		                			});
		                		}
		                		TaskFactory.updateFormCustomFields(taskCustomFieldsField, type, modal.annexaFormly, undefined, JSOG.decode(data[1])).then(function(data) {
		                			if(data.haveTaskCustomFields){
		                				modal.annexaFormly.model.modal_body.taskCustomFieldsShow = true;
		                			}
		                			$rootScope.loadingDiv = false;
		                		}).catch(function() {
		                			taskCustomFieldsField.fieldGroup.length = 0;
		                			$rootScope.loadingDiv = false;
		                        });
		                	}else{
				        		$rootScope.loadingDiv = false;
				        	}
		                	modal.annexaFormly.model.modal_body.origTaskType = ((type)?type.id:undefined);
		                }).catch(function(error){
			        		$rootScope.loadingDiv = false;
			        		console.error(error);
			        		modal.annexaFormly.model.modal_body.origTaskType = ((type)?type.id:undefined);
			        	});
	        		}catch(e){
	        			$rootScope.loadingDiv = false;
	        			console.error(e);
	        			modal.annexaFormly.model.modal_body.origTaskType = ((type)?type.id:undefined);
	        		}
            	}else{
            		modal.annexaFormly.model.modal_body.origTaskType = ((type)?type.id:undefined);
            	}
            };
            fields.push(modal.annexaFormly.createField('status','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskStatus','id',$scope.languageColumn,[],true),data));
            fields.push({key: 'taskCustomFields',className: '',templateOptions: {},fieldGroup: [],
                hideExpression: function ($viewValue, $modelValue, scope) {
                	if(scope.model && scope.model.taskCustomFieldsShow){
                		return false;
                	}else{
                		return true;
                	}
            }});
            
            taskType.data.clear = function ($event, model, key, $select, to) {
                $event.stopPropagation();
                model[key] = undefined;
                if ($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
                if(to.onSelected) {
                    to.onSelected(undefined);
                }
            }
            

            modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {days:30, descriptionLanguage1:'', descriptionLanguage2:'', descriptionLanguage3:'', taskCustomFields: {}, origTaskType:undefined};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.extra = this;

            AnnexaFormlyFactory.showModal("modalNewTaskTemplate", modal, $scope.completedNew, false);
        }
        // -----------------------------------

        $scope.deleteTaskTemplate = function (id){
            AdminFactory.deleteTaskTemplate(id)
                .then(function (data) {
                    var decodedData = JSOG.decode(data.data);
                    $scope.tableTaskTemplate.reloadInternalData(true);
            }).catch(function (error) {
                var errorTranslate = { msg: ErrorFactory.getErrorMessage('taskTemplates', 'delete', error.data) };
                var err = dialogs.error(
                    $filter('translate')('DIALOGS_ERROR_MSG'),
                    $filter('translate')(errorTranslate.msg),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );
            });
        }
    }]);
