/**
 * Created by aguerrero on 03/04/2016.
 */

angular
    .module('annexaApp')
    .controller('ExpressionEvaluatorController', ['$http','$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'ErrorFactory', 'CommonService', function($http, $scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, ErrorFactory, CommonService) {

		$scope.filter = [
            { id: 'name', type: 'text', order: 0, label: 'global.literals.name' },
			{ id: 'catalog', type: 'checkbox', order: 1, label: 'global.literals.catalog'}
        ]

        $scope.additionalColumns = [
        	{ id: 'name', title: $filter('translate')('global.literals.name')},
        	{ id: 'functionName', title: $filter('translate')('global.literals.functionName')},
			{ id: 'catalog', column: new BooleanColumn($filter, 'global.literals.catalog', 'catalog'), sortable: false },
			{ id: 'returns', title: $filter('translate')('global.literals.returns')},
			{ id: 'id', width: '12%', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), [
				new ActionButton('global.literals.remove','deleteElement([data])', undefined, undefined,function(data, type, full, meta){
    				if(full && full.catalog){
    	    			return false;
    	    		}else{
    	    			return true;
    	    		}
    			})
			])}
        ]


        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.spelFunctionNew);
            modal.annexaFormly.options = {};
            AnnexaFormlyFactory.showModal('modalNewTramClassification', modal, $scope.saveSpelFunction, false);
        }

        $scope.saveSpelFunction = function (spelFunction) {
            var modal = this;

            var model = {
                name: spelFunction.annexaFormly.model.modal_body.name,
                functionName: spelFunction.annexaFormly.model.modal_body.functionName,
                spel: spelFunction.annexaFormly.model.modal_body.spel,
                returns: spelFunction.annexaFormly.model.modal_body.returns,
                params: []
            }
			var paramsField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'params'");
	        if(paramsField && paramsField.fieldGroup && paramsField.fieldGroup.length > 0 && paramsField.fieldGroup[0].data && paramsField.fieldGroup[0].data.params){
	            model.params = paramsField.fieldGroup[0].data.params;
	        }
            RestService.insert('./api/tram/spel_function', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
					if(GlobalDataFactory.spelFunctions){
                    	GlobalDataFactory.spelFunctions.length = 0;
                    }else{
                    	GlobalDataFactory.spelFunctions = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('spelFunctions')){
                    	CacheFactory.get('globalDataCache').remove('spelFunctions');
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.close();
                }).catch(function (error) {
                	modal.alerts.push({ msg: ErrorFactory.getErrorMessage('spelFunction', 'create', error.data) });
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('SpelFunction', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);
                    var modal = angular.copy(CommonAdminModals.spelFunctionEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
					modal.canEdit = ((!decodeddata || !decodeddata.catalog)?true:false);
                    modal.updateSpelFunction = $scope.updateSpelFunction;
                    AnnexaModalFactory.showModal('modalSpelFunctionEdit', modal);
                });
        }

        $scope.updateSpelFunction = function(val, prop) {
            var model = angular.copy(this.data);
            var modal = this;
            if( _.contains(['name','functionName','spel','returns'], prop)){
		        model[prop] = val;
	            RestService.update('./api/tram/spel_function/' + model.id, model)
	                .then(function () {
	                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
						if(GlobalDataFactory.spelFunctions){
	                    	GlobalDataFactory.spelFunctions.length = 0;
	                    }else{
	                    	GlobalDataFactory.spelFunctions = [];
	                    }
	                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('spelFunctions')){
	                    	CacheFactory.get('globalDataCache').remove('spelFunctions');
	                    }
	                    GlobalDataFactory.loadGlobalData();
	                }).catch(function (error) {
	                	modal.alerts.push({ msg: ErrorFactory.getErrorMessage('spelFunction', 'create', error.data) });
	            });
			}
        }
		
		$scope.executeButton = function(){
	        CommonService.executeExpression();
		}
    }]);
