angular
    .module('annexaApp')
	.controller('TreatmentController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory',
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory) {

			$scope.filter = [
				{ id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'}
			];

			$scope.newElement = function() {
				var modal = angular.copy(CommonAdminModals.treatmentNew);
				modal.annexaFormly.model = {};
				modal.annexaFormly.model.modal_body = {};
				modal.annexaFormly.options = {};
				
				AnnexaFormlyFactory.showModal('modalNewTreatment', modal, $scope.saveTreatment, false);
			}

			$scope.saveTreatment = function (treatment) {
				var modal = this;
				
				var model = {
					language1: treatment.annexaFormly.model.modal_body.language1 ? treatment.annexaFormly.model.modal_body.language1 : 'NotTranslated',
					language2: treatment.annexaFormly.model.modal_body.language2 ? treatment.annexaFormly.model.modal_body.language2 : 'NotTranslated',
					language3: treatment.annexaFormly.model.modal_body.language3 ? treatment.annexaFormly.model.modal_body.language3 : 'NotTranslated'
				}
				
				RestService.insert('./api/thirds/treatment', model).then(function () {
					$rootScope.$broadcast('annexaAdminBaseReloadTable'); 
					modal.close();
				}).catch(function (error) {
					modal.alerts.push({ msg: $filter('translate')('global.errors.errorTreatment')});
				})
			}

			$scope.viewElement = function (id) {
				RestService.findOne('Treatment', id).then(function(data) {
					var decodeddata = JSOG.decode(data.data);
					
					var modal = angular.copy(CommonAdminModals.treatmentEdit);
					modal.alerts = [];
					modal.data = decodeddata;
					modal.languageColumn = Language.getActiveColumn();
					modal.updateTreatment = $scope.updateTreatment;
					
					AnnexaModalFactory.showModal('modalTreatmentEdit', modal);
				});
			}

			$scope.updateTreatment = function(val, prop) {
				var self = this;
				var model = angular.copy(this.data);
				
				RestService.update('./api/thirds/treatment/' + model.id, model).then(function (data) {
					$rootScope.$broadcast('annexaAdminBaseReloadTable');
				}).catch(function (error) {
					DialogsFactory.error($filter('translate')('global.errors.errorTreatment'));
				});
			}
	}])