/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('RolesController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'CommonAdminService', 'Language', 'AdminFactory', 'RestService', 'AnnexaModalFactory', function($scope, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, CommonAdminService, Language, AdminFactory, RestService, AnnexaModalFactory) {
        $rootScope.loading(false);

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');

        $scope.dtDefRoles = new TableData('common', 'Role', './api/common/byfiler').setSort([[1, 'asc']]).setSortName(Language.getActiveColumn());

        var RolesNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn())
        var RolesActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                  new ActionButton('global.literals.see','viewRole([data])','fa-eye'));
        $scope.filterData = { roleStatus: ''};
        var roleActiveColumn = new ActiveColumn($scope, 'filterData', 'roleStatus',
            [
                new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                new IconSearchOption(1, 1, 'm-l-xs fa fa-circle text-success', 'm-l-xs fa fa-circle text-success', 'global.commonAdmin.filter.statuses.active'),
                new IconSearchOption(2, 2, 'm-l-xs fa fa-circle text-danger', 'm-l-xs fa fa-circle text-danger', 'global.commonAdmin.filter.statuses.inactive'),
            ], 'tableRoles');

        $scope.tableRoles = {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn('active').withOption('width', '5%').withTitle(roleActiveColumn.getTitle()).renderWith(roleActiveColumn.getRender).notSortable(),
                DTColumnBuilder.newColumn(RolesNameColumn.getColumn()).withTitle(RolesNameColumn.getTitle()),
                DTColumnBuilder.newColumn('id').withOption('width', '90px').withOption('className', 'text-center').withTitle(RolesActionColumn.getTitle()).notSortable().renderWith(RolesActionColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux : { langColumn: Language.getActiveColumn() },
            reloadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                if($scope.filterData.roleStatus){
                	var filterValue = false
                	if($scope.filterData.roleStatus == 1) {
                		filterValue = true;
                	}
                	this.filterCall.active = filterValue;
                }
                this.filterCallAux = { langColumn: Language.getActiveColumn() };
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.roles') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mb-md-0 mb-sm-pt mr-sm-pt',undefined,'#admin-roles','fa fa-plus','global.commonAdmin.modal.role.titleNew',undefined,'openNewRole')
                ];
            }
        })

        HeaderService.changeState($state.current)

        $scope.saveRole = function($modal) {
            if($modal.annexaFormly.form.$valid) {
                var data = $modal.annexaFormly.model.modal_body;

                var model = {
                    language1: data.language1,
                    language2: 'NotTranslated',
                    language3: 'NotTranslated',
                    active: data.active_true || false,
                    actionPermissions: []
                };

                if(data.language2) {
                    model.language2 = data.language2;
                }

                if(data.language3) {
                    model.language3 = data.language3;
                }

                if(data.actionPermissions) {
                    angular.forEach(data.actionPermissions, function (val) {
                        model.actionPermissions.push( { actionPermission: { id: val  } });
                    })
                }

                AdminFactory.newRole(model)
                    .then(function (data) {
                        $scope.tableRoles.reloadInternalData(false);
                        $modal.close();
                    })
            }
        };

        $scope.openNewRole = function (id, readonly) {
           var modal = CommonAdminModals.roleNew;
           modal.annexaFormly.model = {};
           modal.annexaFormly.model.modal_body = {};
           modal.annexaFormly.options = {};
           modal.annexaFormly.options.formState = { readOnly: readonly};
           modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = [ { label: $filter('translate')('global.literals.active'), value: true }];
           modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.labelProp = Language.getActiveColumn();
           modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.options = AdminFactory.actionPermissions;
           AnnexaFormlyFactory.showModal("modalRoles", modal, $scope.saveRole, false);
       }

        $scope.updateRole = function (val, prop) {
            switch (prop) {
                case 'language1':
                case 'language2':
                case 'language3':
                    AdminFactory.roleEdit.language1 = val.language1;
                    AdminFactory.roleEdit.language2 = val.language2;
                    AdminFactory.roleEdit.language3 = val.language3;
                    break;
                case 'active':
                    AdminFactory.roleEdit.active = val;
                    break;
                case 'actionPermissions':
                    angular.forEach(val, function (val) {
                        var index = $linq(AdminFactory.roleEdit.actionPermissions).indexOf("x => x.actionPermission.id == " + val.id);
                        if(index != -1) {
                            if(val.checked) {
                                AdminFactory.roleEdit.actionPermissions[index].deleted = false;
                                AdminFactory.roleEdit.actionPermissions[index].removedDate = null;
                            } else {
                                AdminFactory.roleEdit.actionPermissions[index].deleted = true;
                                AdminFactory.roleEdit.actionPermissions[index].removedDate = new Date();
                                AdminFactory.roleEdit.actionPermissions[index].actionPermission = val;
                            }
                        } else {
                            if(val.checked) {
                                delete val.checked;
                                AdminFactory.roleEdit.actionPermissions.push( { actionPermission: val, role: { id: -1 }});
                            }
                        }
                    })
                    break;
            }

            var self = this;

            AdminFactory.updateRole(AdminFactory.roleEdit)
                .then(function (data) {
                    var decodedData = JSOG.decode(data.data);
                    AdminFactory.roleEdit = decodedData;
                    $scope.tableRoles.reloadInternalData(false);
                    self.actionPermissions = [];
                    angular.forEach(angular.copy(AdminFactory.actionPermissions), function (val) {
                        var exist = $linq(decodedData.actionPermissions).count("x => x.actionPermission.id == " + val.id);
                        if(exist == 0) {
                            val.checked = false;
                        } else {
                            val.checked = true;
                        }
                        self.actionPermissions.push(val);
                    })
                }).catch(function (error) {
            });
        };
        
        $scope.viewRole = function (id) {
            RestService.findOne('Role', id)
                .then(function (data) {
                    var modal = CommonAdminModals.roleEdit;
                    modal.data = JSOG.decode(data.data);
                    AdminFactory.roleEdit = angular.copy(modal.data);
                    delete AdminFactory.roleEdit.content;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateRole = $scope.updateRole;
                    modal.printActive = function (model) {
                        if(model) {
                            return $filter('translate')('global.commonAdmin.modal.role.roleActive');
                        } else {
                            return $filter('translate')('global.commonAdmin.modal.role.roleNoActive');
                        }
                    };
                    modal.actionPermissions = angular.copy(AdminFactory.actionPermissions.sort(function(a,b) { var valA = a[modal.languageColumn].toLowerCase(); var valB = b[modal.languageColumn].toLowerCase(); return ((valA < valB) ? -1 : (valA > valB ? 1 : 0)); }));
                    modal.printActionPermissions = function (model) {
                        var actionPermissions = '';

                        angular.forEach(model, function (val, key) {
                            if(val.checked) {
                                if (actionPermissions != '') {
                                    actionPermissions += ', ';
                                }

                                actionPermissions += val[Language.getActiveColumn()];
                            }
                        })

                        return actionPermissions;
                    };
                    modal.actionPermissions = [];
                    angular.forEach(angular.copy(AdminFactory.actionPermissions), function (val) {
                        var exist = $linq(modal.data.actionPermissions).count("x => x.actionPermission.id == " + val.id);
                        if(exist == 0) {
                            val.checked = false;
                        } else {
                            val.checked = true;
                        }
                        modal.actionPermissions.push(val);
                    })
                    AnnexaModalFactory.showModal("modalRoleEdit", modal);
                })
        };

        $scope.deleteRole = function (id) {

        };

        $scope.doSearchFilter=function(queued) {
            $scope.tableRoles.reloadInternalData(true);
        }
    }]);