/**
 * Created by aguerrero on 03/04/2016.
 */

angular
    .module('annexaApp')
    .controller('CustomFieldsController', ['$http','$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'ErrorFactory', 'CommonService', 'CustomFieldFactory', 'HelperService', function($http, $scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, ErrorFactory, CommonService, CustomFieldFactory,HelperService) {

    	$scope.backendOptions = GlobalDataFactory.backendOptions;
    	$scope.frontendOptions = GlobalDataFactory.frontendOptions;
    	var selectYesNo = [
            { id: 1, name: 'global.literals.yes' },
            { id: 2, name: 'global.literals.no' }
        ];
		$scope.filter = [
            { id: 'name', type: 'text', order: 0, label: 'global.literals.name', callAux: true },
			{ id: 'templateTag', type: 'text', order: 1, label: 'global.commonAdmin.modal.tramTemplate.templateTag'},
			{ id: 'frontendType', type: 'select', order: 2, label: 'global.literals.fieldType', dataType: 'LOCAL', data: $scope.frontendOptions, addAll: true, nameProperty: 'description', callAux: true},
			{ id: 'backendType', type: 'select', order: 3, label: 'global.literals.dataType', dataType: 'LOCAL', data: $scope.backendOptions, addAll: true, nameProperty: 'description', callAux: true},
			{ id: 'active', type: 'select', order: 4, label: 'global.literals.active', dataType: 'LOCAL', data: HelperService.addAllSelectAngularTranslate(selectYesNo), addAll: true, nameProperty: 'name', callAux: true},
        ];
		
		$scope.activeCFFunction = function(cfId){
			CustomFieldFactory.enableOrDisableCustomFields(cfId).then(function(data){
				if(GlobalDataFactory.customFields){
    				GlobalDataFactory.customFields.length = 0;
       			}else{
       				GlobalDataFactory.customFields = [];
       			}
       			if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('customFields')){
       				CacheFactory.get('globalDataCache').remove('customFields')
       			}
       			GlobalDataFactory.loadGlobalData();
				$rootScope.$broadcast('annexaAdminBaseReloadTable');
			}).catch(function (error) {
				DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
			});	
		}
		var showEnableAction = function (data, type, full, meta) {
            if(full.active){
                return false;
            } else {
                return true;
            }
        }

        var showDisableAction = function (data, type, full, meta) {
            if(full.active){
                return true;
            } else {
                return false;
            }
        }
		$scope.additionalActions = [
            { label: 'global.commonAdmin.datatable.options.enable', click: $scope.activeCFFunction, clickName: 'activeCFFunction', icon:undefined, href:undefined, showFunction:showEnableAction, permissions:undefined, addProperties:undefined},
			{ label: 'global.commonAdmin.datatable.options.disable', click: $scope.activeCFFunction, clickName: 'activeCFFunction', icon:undefined, href:undefined, showFunction:showDisableAction, permissions:undefined, addProperties:undefined},
        ];
        
		
		var frontendRender = function(data, type, object, meta) {
			if(data && $scope.frontendOptions){
				var selected = $linq($scope.frontendOptions).firstOrDefault(undefined, 'x => x.id == "'+data+'"');
				if(selected && selected.description){
					return $filter('translate')(selected.description);
				}
			}
            return "";
        };
        var backendRender = function(data, type, object, meta) {
			if(data && $scope.backendOptions){
				var selected = $linq($scope.backendOptions).firstOrDefault(undefined, 'x => x.id == "'+data+'"');
				if(selected && selected.description){
					return $filter('translate')(selected.description);
				}
			}
            return "";
        };
        
        $scope.additionalColumns = [
        	{ id: 'templateTag', title: $filter('translate')('global.commonAdmin.modal.tramTemplate.templateTag')},
			{ id: 'frontendType', title: $filter('translate')('global.literals.fieldType'), render: frontendRender},
			{ id: 'backendType', title: $filter('translate')('global.literals.dataType'), render: backendRender},
			{ id: 'active', column: new BooleanColumn($filter, 'global.literals.active', 'active'), sortable: false }
        ]
        $scope.reloadTable = function(){
        	$rootScope.$broadcast('annexaAdminBaseReloadTable');
        }
        $scope.newElement = function() {
        	CustomFieldFactory.customFieldModal(undefined, $scope.reloadTable);
        }

        $scope.viewElement = function (id) {
        	CustomFieldFactory.customFieldModal(id, $scope.reloadTable);
        }

    }]);
