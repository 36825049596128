angular
    .module('annexaApp')
        .controller('ReportController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'RestService', 'Language', 'ErrorFactory','DialogsFactory', 'GlobalDataFactory', 'globalModals', '$http', 'QuerysModals', 'QueryFactory', 'CommonService', 'HelperService',
            function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, RestService, Language, ErrorFactory, DialogsFactory, GlobalDataFactory, globalModals, $http, QuerysModals, QueryFactory, CommonService, HelperService) {
                $scope.showLoadingdiv = false;
                $scope.languageColumn = Language.getActiveColumn();
                $scope.tfilter = new TableFilter();
                $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');
                $scope.modalReport;

                $scope.dtDefReport = new TableData('reports', 'Report', './api/report/byfilter').setSort([[1, 'asc']]).setSortName($scope.languageColumn);

                var nameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
                var reportActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see', 'openReport([data])', 'fa-eye'),
                    [new ActionButton('global.literals.remove', 'deleteReport([data])')]);

                $scope.tableReport = {
                    dtColumns: [
                        DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                        DTColumnBuilder.newColumn(nameColumn.getColumn()).withTitle(nameColumn.getTitle()).renderWith(nameColumn.getRender),
                        DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(reportActionsColumn.getTitle()).renderWith(reportActionsColumn.getRender).notSortable()
                    ],
                    filterCall: $scope.tfilter.getFilterCall(),
                    filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
                    reloadInternalData: function (resetPaging) {
                        this.filterCall = $scope.tfilter.getFilterCall();
                        this.filterCallAux = $scope.tfilter.getFilterCallAux();
                        this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        this.filterCallAux.langColumn = $scope.languageColumn;
                        this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                    }
                };

                HeaderService.onChangeState($scope, function (message) {
                    if(message.state.name == 'annexa.admin.report') {
                        $rootScope.subHeadButtons = [
                            new HeadButtonSearch("#tableFilter"),
                            new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#admin-report','fa fa-plus','global.commonAdmin.modal.report.titleNew',undefined,'openNewReport')
                        ];
                    }
                });

                HeaderService.changeState($state.current);

                $scope.doSearchFilter = function() {
                    $scope.tableReport.reloadInternalData(true);
                };

                $scope.deleteReport = function(id) {
                    if(id) {
                        RestService.checkDelete('./api/report/check_delete/' + id)
                            .then(function(data) {
                                if(data) {
                                    RestService.delete('./api/report/' + id)
                                        .then(function(data) {
                                            $scope.doSearchFilter();
                                        }).catch(function (error) {
                                        console.log(error);
                                    });
                                }
                            }).catch(function(error) {
                                DialogsFactory.error(ErrorFactory.getErrorMessage('report', 'checkDelete', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
                            });
                    }
                };

                $scope.openNewReport = function () {
                    var modal = CommonAdminModals.reportNew;
                    modal.alerts = [];
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {column2:{queries:[]}};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = { readOnly: false};
                    modal.extra = $scope.tableReport;
                    AnnexaFormlyFactory.showModal('modalReport', modal, $scope.saveNewReport, false, undefined);
                };

                $scope.openReport = function (id) {
                    if(id) {
                        RestService.findOne('Report', id)
                            .then(function(data) {
                                var modal = CommonAdminModals.reportEdit;
                                modal.alerts = [];
                                modal.data = JSOG.decode(data.data);
                                modal.data.queries = $linq(modal.data.queries).orderBy("x => x.viewOrder").toArray();
                                modal.reportQueries = modal.data.queries;
                                modal.languageColumn = Language.getActiveColumn();
                                modal.extra = $scope.tableReport;
                                modal.data.profiles.profile = $linq(modal.data.profiles).select("x => x.profile").toArray();

                                modal.printProfiles = function (model) {
                                    var perfiles = '';
                                    angular.forEach(model, function (val, key) {
                                        if(perfiles != '') {
                                            perfiles += ', ';
                                        }
                                        perfiles += val[$scope.languageColumn];
                                    });

                                    return perfiles;
                                };

                                modal.printJoinCommonParams = function (model) {
                                    if(model) {
                                        return $filter('translate')('global.commonAdmin.modal.report.joinCommonParams');
                                    } else {
                                        return $filter('translate')('global.commonAdmin.modal.report.unjoinCommonParams');
                                    }
                                };
                                
                                modal.profiles = GlobalDataFactory.allProfiles;
                                modal.updateReport = $scope.updateReport;
                                modal.replaceFileReportModal = $scope.replaceFileReportModal;
                                modal.downloadFileReport = $scope.downloadFileReport;
                                modal.editOnline = function (report) {
                                    CommonService.getEditOnlineUrl(report.idDocumentManager).then(function(eou) {
                                        if(eou != null && eou != undefined && eou != ''){
                                            window.location.href=eou;
                                        }
                                    }).catch(function(){
                                    });
                                }
                                modal.extraSubmitLabel = 'global.querys.report.generate';
	                            modal.extraSubmitFunction = function() {
	                            	RestService.findOne('Report', id, 'reportsModule')
	                                .then(function(data) {
	                                    if(data && data.data) {
	                                        var report = JSOG.decode(data.data);
	                                        var modalExecute = angular.copy(QuerysModals.reportExecute);
	                                        modalExecute.annexaFormly.model = {};
	                                        modalExecute.annexaFormly.model.row1 =  {name: report[$scope.languageColumn], description:report.description};
	                                        modalExecute.annexaFormly.model.additionalFilter =  {description:report.description};
	                                        modalExecute.annexaFormly.options = {};
	                                        modalExecute.annexaFormly.options.formState = {readOnly: false};
	                                        if(report.queries && report.queries.length > 0){
	                                            if(modalExecute.annexaFormly.fields && modalExecute.annexaFormly.fields.length > 1 && modalExecute.annexaFormly.fields[1].fieldGroup) {
	                                                var newField = {
	                                                    key: 'description',
	                                                    type: 'annexaLabel',
	                                                    className: 'col-sm-12',
	                                                    templateOptions: {
	                                                        label: '',
	                                                        value: ''
	                                                    },
	                                                    data: {
	                                                        informed: true,
	                                                        row: true,
	                                                        colClass: ' col-sm-12',
	                                                        labelClass: 'label-strong'
	                                                    },
	                                                    controller: function ($scope, $filter) {
	                                                        $scope.to.value = $filter("translate")("global.querys.report.querys") + ":";
	                                                    }
	                                                };
	                                                modalExecute.annexaFormly.fields[1].fieldGroup.push(newField);
	                                                var queryFields = [];
	                                                _.forEach(report.queries, function (query) {
	                                                    var newQueryField = {
	                                                        key: query.alias,
	                                                        className: '',
	                                                        templateOptions: {},
	                                                        fieldGroup: [{
	                                                            key: 'description',
	                                                            type: 'annexaLabel',
	                                                            className: 'col-sm-12',
	                                                            templateOptions: {
	                                                                label: '',
	                                                                value: ''
	                                                            },
	                                                            data: {
	                                                                informed: true,
	                                                                row: true,
	                                                                colClass: ' col-sm-12',
	                                                                labelClass: 'label-strong'
	                                                            },
	                                                            controller: function ($scope, $filter, Language) {
	                                                                $scope.to.value = query.query[Language.getActiveColumn()];
	                                                            }
	                                                        }]
	                                                    };
	                                                    modalExecute.annexaFormly.model.additionalFilter[query.alias] = {};
	                                                    var parameters = ((query && query.query && query.query.parameters)?$linq(query.query.parameters).orderBy("x => x.viewOrder").toArray():[]);
	                                                    _.forEach(parameters, function (field, index) {
	                                                        var fieldClass = 'col-sm-4';
	                                                        if (!report.joinCommonParams) {
	                                                            if (index % 3 === 0) {
	                                                                fieldClass += ' col-0';
	                                                            }
	                                                        }
	                                                        var newParameterField = QueryFactory.createQueryParameterField(field, false, false, fieldClass);
	                                                        if(newParameterField){
	                                                            newQueryField.fieldGroup.push(newParameterField);
	                                                            queryFields.push(newParameterField);
	                                                            if(field.queryParameter.queryParameterType == 'DATE' && field.value) {
	                                                                var dateParts = field.value.split("/");
	                                                                var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
	                                            					if(dateObject != "Invalid Date") {
	                                            						modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = dateObject;
	                                            					} else {
	                                            						dateParts = HelperService.dateMaskToDate(dateParts);
	                                            						modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
	                                            					}
	                                                            }else if(field.queryParameter.queryParameterType == 'INTEGER'&& field.value){
	                                                                modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] =  parseInt(field.value);
	                                                            }else if(field.queryParameter.queryParameterType == 'DOUBLE'&& field.value){
	                                                                var valueLiteral_parts = (field.value).split('.');
	                                                                if(valueLiteral_parts && valueLiteral_parts.length > 0){
	                                                                    if(valueLiteral_parts[1]){
	                                                                        modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
	                                                                    }else{
	                                                                        modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
	                                                                    }
	                                                                }
	                                                            }else if(field.queryParameter.queryParameterType == 'BOOLEAN'){
	                                                                if(field.value && field.value != '-1'){
	                                                                    modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = field.value;
	                                                                }
	                                                            }else if(field.queryParameter.queryParameterType == 'BOOLEAN_NULL'){
                                                                    if(field.value && field.value != '-1'){
                                                                        modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = field.value;
                                                                    }else{
                                                                        modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = undefined;
                                                                    }
                                                                }else if(field.value){
	                                                                modalExecute.annexaFormly.model.additionalFilter[query.alias][newParameterField.key] = field.value;
	                                                            }
	                                                        }
	                                                    });
	                                                    modalExecute.annexaFormly.fields[1].fieldGroup.push(newQueryField);
	                                                });
	                                                if (report.joinCommonParams) {
	                                                    var newCommonField = {
	                                                        key: 'commonParams',
	                                                        className: '',
	                                                        templateOptions: {},
	                                                        fieldGroup: [{
	                                                            key: 'description',
	                                                            type: 'annexaLabel',
	                                                            className: 'col-sm-12',
	                                                            templateOptions: {
	                                                                label: '',
	                                                                value: ''
	                                                            },
	                                                            data: {
	                                                                informed: true,
	                                                                row: true,
	                                                                colClass: ' col-sm-12',
	                                                                labelClass: 'label-strong'
	                                                            },
	                                                            controller: function ($scope, $filter, Language) {
	                                                                $scope.to.value = $filter("translate")("global.querys.report.commonParams") + ":";
	                                                            }
	                                                        }]
	                                                    };
	                                                    modalExecute.annexaFormly.fields[1].fieldGroup.splice(1,0,newCommonField);

	                                                    var search = queryFields.reduce(function(acc, field) {
	                                                        var clave = JSON.stringify(field);
	                                                        acc[clave] = ++acc[clave] || 0;
	                                                        return acc;
	                                                    }, {});
	                                                    var duplicates = angular.copy(queryFields).filter(function(field) {
	                                                        return search[JSON.stringify(field)];
	                                                    });

	                                                    modalExecute.annexaFormly.model.additionalFilter.commonParams = {};
	                                                    for (var i = 0; i < duplicates.length; i++) {
	                                                        var indexCommon = $linq(newCommonField.fieldGroup).indexOf("x => x.key == '" + duplicates[i].key + "'");
	                                                        if (indexCommon == -1) {
	                                                            _.forEach(modalExecute.annexaFormly.fields[1].fieldGroup, function (fieldGroup) {
	                                                                var index = $linq(fieldGroup.fieldGroup).indexOf("x => x.key == '" + duplicates[i].key + "'");
	                                                                if (index != -1) {
	                                                                    fieldGroup.fieldGroup[index].hideExpression = function () {return true;};
	                                                                    modalExecute.annexaFormly.model.additionalFilter.commonParams[duplicates[i].key] = modalExecute.annexaFormly.model.additionalFilter[fieldGroup.key][duplicates[i].key];
	                                                                }
	                                                            });
	                                                            newCommonField.fieldGroup.push(duplicates[i]);
	                                                        }
	                                                    }
	                                                }
	                                            }
	                                        }
	                                        modalExecute.extra = ((report && report.id)?report.id:undefined);
	                                        $scope.$on('executeReportResultError',function(event,args){
	                                            if(args && args.reportId && args.reportId == report.id) {
	                                            	if (args.error && args.error.status == 500) {
	                                            		modalExecute.alerts.push({msg: $filter('translate')('global.querys.report.reportError'), msgAux:((args.error.message)?args.error.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
	                                            	} else {
	                                            		modalExecute.alerts.push({msg: $filter('translate')('global.querys.report.error')});
	                                            	}
	                                            }
	                                        });
	                                        AnnexaFormlyFactory.showModal('modalReportExecute', modalExecute, $scope.executeReport, false, undefined, $filter('translate')('global.querys.report.generate'));
	                                    }
	                                });
	                            }
	                            
	                            modal.openModalCronSchedule = function() {
	                                var modalCron = angular.copy(QuerysModals.cronScheduleForm);
	                                var cronSchedule = modal.data.cronSchedule;
	                                if (!cronSchedule) {
	                                	cronSchedule = {};
	                                }
	                                modalCron.cronScheduleId = cronSchedule.id;
	                                modalCron.objectId = modal.data.id;
	                                
	                                var emailsToBtn = $linq(modalCron.annexaFormly.fields[3].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsToBtn'");
	                                if(emailsToBtn && emailsToBtn.templateOptions){
	                                	emailsToBtn.templateOptions.executeFunction = function(){
	                                		var addMailTo = function(){
	                                			if(this.annexaFormly.model.modal_body.email){
	                                				 var emailsTO = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsTO'");
	                                				 if(emailsTO && emailsTO.templateOptions){
	                                					 if(!_.contains(emailsTO.templateOptions.labels, this.annexaFormly.model.modal_body.email)){
	 	 	                                				emailsTO.templateOptions.labels.push(this.annexaFormly.model.modal_body.email);
	                                					 }
	 	                                				this.close();
	                                				 }else{
	 	                                				this.close();
	                                				 }
	                                			}else{
	                                				this.close();
	                                			}
	                                		}
	                                		var modalmail = angular.copy(QuerysModals.addMail);
	                                		modalmail.annexaFormly.model = {};
	                                		AnnexaFormlyFactory.showModal('modalQueryMail', modalmail, addMailTo, false);
	                                	}
	                                }
	                                var emailsCCBtn = $linq(modalCron.annexaFormly.fields[3].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCBtn'");
	                                if(emailsCCBtn && emailsCCBtn.templateOptions){
	                                	emailsCCBtn.templateOptions.executeFunction = function(){
	                                		var addMailCC = function(){
	                                			if(this.annexaFormly.model.modal_body.email){
	                                				 var emailsCC = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCC'");
	                                				 if(emailsCC && emailsCC.templateOptions){
	                                					 if(!_.contains(emailsCC.templateOptions.labels, this.annexaFormly.model.modal_body.email)){
	 	 	                                				emailsCC.templateOptions.labels.push(this.annexaFormly.model.modal_body.email);
	                                					 }
	 	                                				this.close();
	                                				 }else{
	 	                                				this.close();
	                                				 }
	                                			}else{
	                                				this.close();
	                                			}
	                                		}
	                                		var modalmail = angular.copy(QuerysModals.addMail);
	                                		modalmail.annexaFormly.model = {};
	                                		AnnexaFormlyFactory.showModal('modalQueryMail', modalmail, addMailCC, false);
	                                	}
	                                }
	                                var emailsCCOBtn = $linq(modalCron.annexaFormly.fields[3].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCOBtn'");
	                                if(emailsCCOBtn && emailsCCOBtn.templateOptions){
	                                	emailsCCOBtn.templateOptions.executeFunction = function(){
	                                		var addMailCCO = function(){
	                                			if(this.annexaFormly.model.modal_body.email){
	                                				 var emailsCCO = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCO'");
	                                				 if(emailsCCO && emailsCCO.templateOptions){
	                                					 if(!_.contains(emailsCCO.templateOptions.labels, this.annexaFormly.model.modal_body.email)){
	 	                                					emailsCCO.templateOptions.labels.push(this.annexaFormly.model.modal_body.email);
	                                					 }
	 	                                				this.close();
	                                				 }else{
	 	                                				this.close();
	                                				 }
	                                			}else{
	                                				this.close();
	                                			}
	                                		}
	                                		var modalmail = angular.copy(QuerysModals.addMail);
	                                		modalmail.annexaFormly.model = {};
	                                		AnnexaFormlyFactory.showModal('modalQueryMail', modalmail, addMailCCO, false);
	                                	}
	                                }
	                                var emailsTO = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsTO'");
	                                if(emailsTO && emailsTO.templateOptions){
	                                	emailsTO.templateOptions.labels = [];
	                                	if(cronSchedule.emailsTo){
	                                		var emailsTO_parts = cronSchedule.emailsTo.split(';');
                                            if(emailsTO_parts && emailsTO_parts.length > 0){
                                            	_.forEach(emailsTO_parts, function(emailTO){
                                            		emailsTO.templateOptions.labels.push(emailTO);
                                            	});
                                            }
										}
	                                	emailsTO.templateOptions.deleteObject = function(label,index){
	                                		if(emailsTO.templateOptions && emailsTO.templateOptions.labels && emailsTO.templateOptions.labels.length > index){
	                                			emailsTO.templateOptions.labels.splice(index,1);
	                                		}
	                                	}
	                                }
	                                var emailsCC = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCC'");
	                                if(emailsCC && emailsCC.templateOptions){
	                                	emailsCC.templateOptions.labels = [];
	                                	if(cronSchedule.emailsCc){
	                                		var emailsCC_parts = cronSchedule.emailsCc.split(';');
                                            if(emailsCC_parts && emailsCC_parts.length > 0){
                                            	_.forEach(emailsCC_parts, function(emailCC){
                                            		emailsCC.templateOptions.labels.push(emailCC);
                                            	});
                                            }
										}
	                                	emailsCC.templateOptions.deleteObject = function(label,index){
	                                		if(emailsCC.templateOptions && emailsCC.templateOptions.labels && emailsCC.templateOptions.labels.length > index){
	                                			emailsCC.templateOptions.labels.splice(index,1);
	                                		}
	                                	}
	                                }
	                                var emailsCCO = $linq(modalCron.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCO'");
	                                if(emailsCCO && emailsCCO.templateOptions){
	                                	emailsCCO.templateOptions.labels = [];
	                                	if(cronSchedule.emailsCco){
	                                		var emailsCCO_parts = cronSchedule.emailsCco.split(';');
                                            if(emailsCCO_parts && emailsCCO_parts.length > 0){
                                            	_.forEach(emailsCCO_parts, function(emailCCO){
                                            		emailsCCO.templateOptions.labels.push(emailCCO);
                                            	});
                                            }
										}
	                                	emailsCCO.templateOptions.deleteObject = function(label,index){
	                                		if(emailsCCO.templateOptions && emailsCCO.templateOptions.labels && emailsCCO.templateOptions.labels.length > index){
	                                			emailsCCO.templateOptions.labels.splice(index,1);
	                                		}
	                                	}
	                                }										
	                                
	                                modalCron.annexaFormly.model = {};
	                                modalCron.annexaFormly.model.row1 = { cronExpression: cronSchedule.cronExpression };
	                                modalCron.annexaFormly.model.row2 = { 
	                                		sentByMail_1: (((cronSchedule.profiles && cronSchedule.profiles.length > 0) || (cronSchedule.emailsTo && cronSchedule.emailsTo !== '') || (cronSchedule.emailsCc && cronSchedule.emailsCc !== '') || (cronSchedule.emailsCco && cronSchedule.emailsCco !== '')) ? true : false)
	                                };
	                                modalCron.annexaFormly.model.row2Mail = { 
	                                		sentByMail_1: (((cronSchedule.profiles && cronSchedule.profiles.length > 0) || (cronSchedule.emailsTo && cronSchedule.emailsTo !== '') || (cronSchedule.emailsCc && cronSchedule.emailsCc !== '') || (cronSchedule.emailsCco && cronSchedule.emailsCco !== '')) ? true : false)
	                                };
	                                modalCron.annexaFormly.model.row2MailBtn = { 
	                                		sentByMail_1: (((cronSchedule.profiles && cronSchedule.profiles.length > 0) || (cronSchedule.emailsTo && cronSchedule.emailsTo !== '') || (cronSchedule.emailsCc && cronSchedule.emailsCc !== '') || (cronSchedule.emailsCco && cronSchedule.emailsCco !== '')) ? true : false)
	                                };
	                                modalCron.annexaFormly.model.row2MailBody = { 
	                                		sentByMail_1: (((cronSchedule.profiles && cronSchedule.profiles.length > 0) || (cronSchedule.emailsTo && cronSchedule.emailsTo !== '') || (cronSchedule.emailsCc && cronSchedule.emailsCc !== '') || (cronSchedule.emailsCco && cronSchedule.emailsCco !== '')) ? true : false),
	                                		body: ((cronSchedule.emailBody)?cronSchedule.emailBody:undefined)
	                                };
	                                modalCron.annexaFormly.model.row2.profilesIds = [];
	                                if (cronSchedule.profiles) {
	                                	modalCron.annexaFormly.model.row2.profilesIds = $linq(cronSchedule.profiles).select("x => x.profile.id").toArray();
	                                }
	                                
	                                modalCron.annexaFormly.model.row3 = { 
                                			sentByFTP_1: cronSchedule.host || cronSchedule.port || cronSchedule.userFtp || cronSchedule.passwordFtp || cronSchedule.folder ? true : false,
                                			host: cronSchedule.host,
                                			port: cronSchedule.port,
                                			userFtp: cronSchedule.userFtp,
                                			passwordFtp: cronSchedule.passwordFtp,
                                			folder: cronSchedule.folder
	                                };
	                                modalCron.annexaFormly.options = {};
	                                
	                                $scope.$on('cronScheduleReport', function(event, data) { 
	                                	modal.cronAlerts = [];
	                                	modal.data.cronSchedule = data;
	                                    
	                                    if (modal.data.cronSchedule) {
		                                    modal.cronAlerts.push({ msg: 'global.commonAdmin.modal.cron.alertReportReady', type: 'success', link: function(){ modal.openModalCronSchedule() }, labelLink: 'global.commonAdmin.modal.cron.configure' })

		                                    _.forEach(data.profiles, function(dataProfileCS) {
		                                    	_.forEach(modal.data.cronSchedule.profiles, function(modalDataProfileCS) {
		                                    		if (modalDataProfileCS.profile.id === dataProfileCS.profile.id) {
		                                    			modalDataProfileCS.profile = $linq(modal.profiles).firstOrDefault(undefined, "x => x.id == " + dataProfileCS.profile.id);
		                                    		}
		                                    	});
		                                    });
	                                    } else {
		                                    modal.cronAlerts.push({ msg: 'global.commonAdmin.modal.cron.alertReportNotReady', type: 'info', link: function(){ modal.openModalCronSchedule() }, labelLink: 'global.commonAdmin.modal.cron.schedule' })
	                                    }
	                                });

	                                if (cronSchedule.id) {
		                                modalCron.additionalButton = {
		                                        label: 'global.commonAdmin.modal.cron.notScheduleExecution',
		                                        click: function () {
		                                            RestService.delete('./api/query/cronSchedule/' + cronSchedule.id)
		                                            .then(function(data) {
		                                            	$scope.$broadcast('cronScheduleReport', undefined);
		                                            	modalCron.close();
		                                            }).catch(function (error) {
		                                            	console.log(error);
		                                            });
		                                       	}
		                                    }
	                                }
	                                AnnexaFormlyFactory.showModal('modalReportCron', modalCron, $scope.scheduleCronReport, false, undefined, 'global.commonAdmin.modal.cron.scheduleExecution');
	                            }
                                modal.cronAlerts = [];
                                RestService.findByUrl('./api/query/findByObjectTypeAndObjectId?objectType=REPORT&objectId=' + id).then(function(response) {
                                    if(response && response.data){
                                    	modal.data.cronSchedule = JSOG.decode(response.data);
                                    	modal.cronAlerts.push({ msg: 'global.commonAdmin.modal.cron.alertReportReady', type: 'success', link: function(){ modal.openModalCronSchedule() }, labelLink: 'global.commonAdmin.modal.cron.configure' });
                                    }else{
                                    	modal.cronAlerts.push({ msg: 'global.commonAdmin.modal.cron.alertReportNotReady', type: 'info', link: function(){ modal.openModalCronSchedule() }, labelLink: 'global.commonAdmin.modal.cron.schedule' });
                                    }
                                    $scope.modalReport = modal;
                                    AnnexaModalFactory.showModal('modalReportEdit', modal);
                                }).catch(function (response) {
                                });
                            });
                    }
                };

                $scope.scheduleCronReport = function(){
                    if (this.annexaFormly.form.$valid) {
                    	var self = this;
                    	var model = this.annexaFormly.model;
                    	
                        var profiles = [];
                        if (model.row2.sentByMail_1) {
                        	_.forEach(model.row2.profiles, function(item) {
                        		if (item.id) {
                        			profiles.push({ profile: { id: item.id } });
                        		} else {
                        			profiles.push({ profile: { id: item } });
                        		}
                            });
                        }
                        var emailsTo = "";
                        var emailsCc = "";
                        var emailsCco = "";
                        var emailsBody = "";
                        if (model.row2.sentByMail_1) {
                        	var emailsTOField = $linq(this.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsTO'");
                            if(emailsTOField && emailsTOField.templateOptions && emailsTOField.templateOptions.labels ){
	                            _.forEach(emailsTOField.templateOptions.labels, function(item) {
	                        		emailsTo = emailsTo +((emailsTo && emailsTo !== "")?";":"")+ item;
	                            });
                            }
                            var emailsCCField = $linq(this.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCC'");
                            if(emailsCCField && emailsCCField.templateOptions && emailsCCField.templateOptions.labels ){
	                            _.forEach(emailsCCField.templateOptions.labels, function(item) {
	                            	emailsCc = emailsCc +((emailsCc && emailsCc !== "")?";":"")+ item;
	                            });
                            }
                            var emailsCCOField = $linq(this.annexaFormly.fields[2].fieldGroup).firstOrDefault(undefined, "x => x.key == 'emailsCCO'");
                            if(emailsCCOField && emailsCCOField.templateOptions && emailsCCOField.templateOptions.labels ){
	                            _.forEach(emailsCCOField.templateOptions.labels, function(item) {
	                            	emailsCco = emailsCco +((emailsCco && emailsCco !== "")?";":"")+ item;
	                            });
                            }
                        	if(model.row2MailBody && model.row2MailBody.body){
                        		emailsBody = model.row2MailBody.body;
                        	}
                        }
                        if (!model.row3.sentByFTP_1) {
                        	model.row3.host = undefined;
                        	model.row3.port = undefined;
                        	model.row3.userFtp = undefined;
                        	model.row3.passwordFtp = undefined;
                        	model.row3.folder = undefined;
                        }
                        
                    	var cronSchedule = {
                    			id: this.cronScheduleId,
                    			objectId: this.objectId,
                    			cronExpression: this.annexaFormly.fields[0].fieldGroup[0].data.cronExpression,
                    			profiles: profiles,
                    			emailsTo: ((emailsTo && emailsTo !== "")?emailsTo:undefined),
                    			emailsCc: ((emailsCc && emailsCc !== "")?emailsCc:undefined),
                    			emailsCco: ((emailsCco && emailsCco !== "")?emailsCco:undefined),
                    			emailBody: ((emailsBody && emailsBody !== "")?emailsBody:undefined),
                    			host: model.row3.host,
                    			port: model.row3.port,
                    			userFtp: model.row3.userFtp,
                    			passwordFtp: model.row3.passwordFtp,
                    			folder: model.row3.folder,
                    			objectType: 'REPORT'
                            };

                    	if (cronSchedule.id) {
                            RestService.update('./api/query/cronSchedule/' + cronSchedule.id, cronSchedule)
		                        .then(function(data) {
		                        	$scope.$broadcast('cronScheduleReport', data);
		                        	self.close();
		                        }).catch(function (error) {
		                        	console.log(error);
	                        });
                    	} else {
                            RestService.insert('./api/query/cronSchedule', cronSchedule)
		                        .then(function(data) {
		                        	$scope.$broadcast('cronScheduleReport', data);
		                        	self.close();
		                        }).catch(function(error) {
		                            console.log(error);
	                        });                    		
                    	}
                    }
                };

                $scope.executeReport = function(){
                    if (this.annexaFormly.form.$valid) {
                        var self = this;
                        for (var i = 1; i < this.annexaFormly.fields[1].fieldGroup.length; i++) {
                            var datepickerFields = $linq(this.annexaFormly.fields[1].fieldGroup[i].fieldGroup).where("x => x.type == 'annexaDatepickerRow'").toArray();
                            for (var j = 0; j < datepickerFields.length; j++) {
                                var modelDatepickerField = this.annexaFormly.model.additionalFilter[this.annexaFormly.fields[1].fieldGroup[i].key][datepickerFields[j].key];
                                if (modelDatepickerField) {
                                    var dateUTC = new Date(Date.UTC(modelDatepickerField.getFullYear(),modelDatepickerField.getMonth(),modelDatepickerField.getDate(),00,00,00));
                                    this.annexaFormly.model.additionalFilter[this.annexaFormly.fields[1].fieldGroup[i].key][datepickerFields[j].key] = dateUTC;
                                }
                            }
                        }
                        var modelAdditionalFilter = this.annexaFormly.model.additionalFilter;
                        if (modelAdditionalFilter.commonParams) {
                            var indexCommonParams = $linq(this.annexaFormly.fields[1].fieldGroup).indexOf("x => x.key == 'commonParams'");
                            for (var i = indexCommonParams; i < this.annexaFormly.fields[1].fieldGroup.length; i++) { //recorremos las consultas
                                for (var j = 1; j < this.annexaFormly.fields[1].fieldGroup[indexCommonParams].fieldGroup.length; j++) { //recorremos los campos comunes
                                    if (modelAdditionalFilter.commonParams[this.annexaFormly.fields[1].fieldGroup[indexCommonParams].fieldGroup[j].key]) {
                                        //si se ha informado el campo común entonces se informará su valor en cada uno de los campos de las consultas
                                        modelAdditionalFilter[this.annexaFormly.fields[1].fieldGroup[i].key][this.annexaFormly.fields[1].fieldGroup[indexCommonParams].fieldGroup[j].key] =
                                            modelAdditionalFilter.commonParams[this.annexaFormly.fields[1].fieldGroup[indexCommonParams].fieldGroup[j].key];
                                    }
                                }
                            }
                        }
                        modelAdditionalFilter.langColumn = $scope.languageColumn;
                        modelAdditionalFilter.loggedUser = $rootScope.LoggedUser.id;
                        QueryFactory.generateReportQuery(self.extra, modelAdditionalFilter, self);
                    }
                };

                $scope.downloadFileReport = function(report) {
                    $rootScope.loading(true);

                    var href = './api/report/download?id=' + report.id;

                    $http.get(href, {responseType: 'arraybuffer'})
                        .success(function (data, status, headers) {
                            var retDownload = {
                                file: undefined,
                                fileName: undefined,
                                linkElement: undefined,
                                clickEvent: undefined
                            };
                            headers = headers();

                            var contentType = headers['content-type'];
                            retDownload.file = new Blob([data], {type: contentType});
                            retDownload.fileName = report.fileName;

                            try {
                                if (window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                                } else {
                                    retDownload.linkElement = document.createElement('a');
                                    var url = window.URL.createObjectURL(retDownload.file);
                                    retDownload.linkElement.setAttribute('href', url);
                                    retDownload.linkElement.setAttribute("download", retDownload.fileName);
                                    retDownload.clickEvent = new MouseEvent("click", {
                                        "view": window,
                                        "bubbles": true,
                                        "cancelable": false
                                    });
                                    retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                                }
                                $rootScope.loading(false);
                            } catch (e) {
                                $rootScope.loading(false);
                            }
                        }).error(function (error) {
                            $rootScope.loading(false);
                        });
                };

                $scope.replaceFileReportModal = function(report, callerModal) {
                    var replaceFileReport = function() {
                        if(this.annexaFormly.form.$valid) {
                            $rootScope.loading(true);
                            var self = this;
                            var data = this.annexaFormly.model.modal_body;

                            RestService.update('./api/report/' + report.id + '/file', JSON.stringify(data.file[0]))
                                .then(function(data) {
                                    callerModal.data.fileName = data.fileName;
                                    $rootScope.loading(false);
                                    self.close();
                                }).catch(function(error) {
                                $rootScope.loading(false);
                                console.log(error);
                            });
                        }
                    };


                    var modal = globalModals.uploadFileBase64;
                    modal.title = 'global.literals.replace';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    AnnexaFormlyFactory.showModal('modalUploadFile', modal, replaceFileReport, false);
                };

                $scope.updateReport = function(val, prop) {
                    var self = $scope.modalReport;
                    var report = angular.copy($scope.modalReport.data);
                    self.alerts = [];
                    if (prop == 'profiles') {
                        report[prop] = [];
                        _.forEach(val, function (item) {
                            report[prop].push({profile: {id: item.id}});
                        });
                    } else if(prop != 'language1' && prop != 'language2' && prop != 'language3') {
                        report[prop] = val;
                    }

                    RestService.update('./api/report/' + report.id, report)
                        .then(function(data) {
                            self.extra.reloadInternalData(true);
                        }).catch(function (error) {
                            console.log(error);
                        });

                };

                $scope.saveNewReport = function(){
                    if (this.annexaFormly.form.$valid) {
                        var self = this;
                        var model = this.annexaFormly.model.modal_body.column1;
                        var profiles = [];
                        _.forEach(model.profiles, function(item) {
                            profiles.push({ profile: { id: item } });
                        });

                        var report = {
                            language1: model.language1,
                            language2: model.language2,
                            language3: model.language3,
                            description: model.description,
                            file: JSON.stringify(model.file[0]),
                            joinCommonParams: model.joinCommonParams != undefined ? model.joinCommonParams : false,
                            queries: [],
                            profiles: profiles
                        };

                        angular.forEach(this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.reportQueries, function (value, key) {
                            var field = {
                                query: value.query,
                                viewOrder:value.viewOrder,
                                alias: value.alias
                            };

                            report.queries.push(field);
                        });

                        RestService.insert('./api/report', report)
                            .then(function(data) {
                                self.extra.reloadInternalData(true);
                                self.close();
                            }).catch(function(error) {
                                self.alerts.length = 0;
                                if (error && error.data)
                                    self.alerts.push({ msg: ErrorFactory.getErrorMessage('query', 'new', error.data) });
                            });
                    }
                };
            }]);