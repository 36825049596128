/**
 * Created by osirvent on 19/09/2016.
 */
angular
    .module('annexaApp')
    .controller('TramTemplatesController',['$scope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HeaderService', '$rootScope', '$state', 'CommonAdminModals', 'AnnexaFormlyFactory', 'Upload', '$timeout', 'AdminFactory', 'RestService', 'HelperService', 'AnnexaModalFactory', 'CommonAdminService', 'CommonService', 'globalModals', 'GlobalDataFactory', '$http', 'DialogsFactory', function ($scope, $filter, TableFilter, DTColumnBuilder, Language, HeaderService, $rootScope, $state, CommonAdminModals, AnnexaFormlyFactory, Upload, $timeout, AdminFactory, RestService, HelperService, AnnexaModalFactory, CommonAdminService, CommonService, globalModals, GlobalDataFactory, $http, DialogsFactory) {
        $rootScope.loading(false);
        $scope.languageColumn = Language.getActiveColumn();
        $scope.cancelEditIsPublic = false;
        $scope.modelTramTemplates;

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');
        $scope.tfilter.addElement('procedure', 'select', 1, 'global.literals.procedure', 3, HelperService.addAllSelect(angular.copy(GlobalDataFactory.procedures),Language.getActiveColumn(), $scope.app.language), true, Language.getActiveColumn(), false).setFilterCallAux(true);
        $scope.tfilter.addElement('documentType', 'select', 2, 'global.literals.docType', 3, HelperService.addAllSelect(angular.copy(GlobalDataFactory.documentTypes),Language.getActiveColumn(), $scope.app.language), true, Language.getActiveColumn(), false);

        $scope.dtDefTramTemplates = new TableData('tram','TramTemplate','./api/common/byfiler','rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>','name',[[1, 'asc']]);

        var tramTemplateNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
        var tramTemplateIsPublicColumn = new BooleanColumn($filter, 'global.literals.isPublic', 'public');
        var tramTemplateProcedureColumn = new DatabaseTranslatedListColumn($filter, 'global.literals.procedures', Language.getActiveColumn(), 'procedures');
        var docTemplateDocumentTypeColumn = new DatabaseTranslatedColumn($filter, 'global.literals.docType',Language.getActiveColumn());
        var tramTemplateActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                         new ActionButton('global.literals.see','openTramTemplate([data])','fa-eye'),
                                                         [new ActionButton('global.literals.remove','deleteTramTemplate([data])')]);

        var getFilterCallTramTemplate = function(){
            var filterCallTramTemplate= $scope.tfilter.getFilterCallAux();
            filterCallTramTemplate.langColumn = Language.getActiveColumn();
            return filterCallTramTemplate;
        };


        $scope.tableTramTemplates =  {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn(tramTemplateNameColumn.getColumn()).withTitle(tramTemplateNameColumn.getTitle()),
                DTColumnBuilder.newColumn(tramTemplateIsPublicColumn.getColumn()).withOption('width','80px').withTitle(tramTemplateIsPublicColumn.getTitle()).renderWith(tramTemplateIsPublicColumn.getRender),
                DTColumnBuilder.newColumn(tramTemplateProcedureColumn.getColumn()).withTitle(tramTemplateProcedureColumn.getTitle()).notSortable().renderWith(tramTemplateProcedureColumn.getRender),
                DTColumnBuilder.newColumn('documentType.' + docTemplateDocumentTypeColumn.getColumn()).withTitle(docTemplateDocumentTypeColumn.getTitle()).renderWith(docTemplateDocumentTypeColumn.getRender),
                DTColumnBuilder.newColumn('id').withOption('width', '130px').withOption('className', 'text-center').withTitle(tramTemplateActionColumn.getTitle()).notSortable().renderWith(tramTemplateActionColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: getFilterCallTramTemplate(),
            reloadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = getFilterCallTramTemplate();
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.tram_templates') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-tramTemplates','fa fa-arrow-down','global.commonAdmin.literals.jsonTagsDescriptor',undefined,'jsonTagsDescriptor'),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-tramTemplates','fa fa-plus','global.commonAdmin.newTemplate',undefined,'newTramTemplate')                    
                ];
            }
        });

        HeaderService.changeState($state.current)

        $scope.saveTramTemplate = function ($modal) {
        	$modal.alerts = [];
            if($modal.annexaFormly.form.$valid) {
                var data = $modal.annexaFormly.model.modal_body;
                var haveError = false;
                var file = undefined;
                
                var model = {
                    language1: data.language1,
                    language2: 'NoTranslated',
                    language3: 'NoTranslated',
                    public: data.isPublic_true || false,
                    procedures: [],
                    presetSignCircuits: [],
                    editCircuit: data.editCircuit_true || false,
                    allowThirds: data.allowThirds_false || false,
                    documentType:undefined,
                    profiles: [],
                    templateSignCircuit: [],
                    notHaveDossierCustomField:data.notHaveDossierCustomField_false || false,
                    notHaveDossierTransactionCustomField:data.notHaveDossierTransactionCustomField_false || false,
                    notHaveDocumentCustomField:data.notHaveDocumentCustomField_false || false
                };

                if(data.language2) {
                    model.language2 = data.language2;
                }

                if(data.language3) {
                    model.language3 = data.language3;
                }

                if(data.file) {
                	file = data.file;
                }

                if(data.procedures) {
                    _.forEach(data.procedures, function(item) {
                    	model.procedures.push( { id: item } );
                    });
                }
                if(data.documentType){
                	model.documentType = {id: data.documentType}
                }
                if(data.profiles){
                    _.forEach(data.profiles, function(item) {
                    	model.profiles.push( { profile: { id: item } });
                    });
                }
                
                var acceptedFileType = true;
                var fileField = undefined;
                var modalBodyFields = $linq($modal.annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'modal_body'");
                if(modalBodyFields) {
                    fileField = $linq(modalBodyFields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'file'");
                }

                if(fileField && fileField.data && fileField.data.accept && model.file) {
                    acceptedFileType = fileField.data.accept.includes(model.file.type);
                }
                
                var conditionedSignCircuitListField = $linq($modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
                if(conditionedSignCircuitListField) {
                	model.templateSignCircuit = conditionedSignCircuitListField.data.conditionedSignCircuitList;
                }
                
                if(acceptedFileType && !haveError) {
                	$scope.uploadTemplate(model, $modal, file);
                } else if(!acceptedFileType) {
                    DialogsFactory.error($filter('translate')('global.literals.documentTypeNotAccepted'));
                }
            }

        };

        $scope.uploadTemplate = function(model, $modal, file) {
            AdminFactory.addTramTemplate(model, file)
                .then(function (data) {
                    $modal.close();
                    $scope.tableTramTemplates.reloadInternalData(true);
                    $timeout(function () {
                    });
                }).catch(function (error) {
            });
        } ;

        $scope.newTramTemplate = function () {
            var modal = angular.copy(CommonAdminModals.tramTemplateNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = { isPublic_true: true, allowThirds: false, notHaveDossierCustomField_false: false, notHaveDossierTransactionCustomField_false: false, notHaveDocumentCustomField_false: false};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.labelProp = Language.getActiveColumn();
            modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = GlobalDataFactory.documentTypes;
            modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.options = [ { label: $filter('translate')('global.literals.isPublic'), value: true }];
            modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.labelProp = Language.getActiveColumn();
            modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.options = GlobalDataFactory.procedures;
            modal.annexaFormly.fields[0].fieldGroup[6].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.tramTemplate.allowThirds'), value: false }];
            modal.annexaFormly.fields[0].fieldGroup[8].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDossierCustomField'), value: false }];
            modal.annexaFormly.fields[0].fieldGroup[9].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDossierTransactionCustomField'), value: false }];
            modal.annexaFormly.fields[0].fieldGroup[10].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDocumentCustomField'), value: false }];
            var conditionedSignCircuitListField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
            if(conditionedSignCircuitListField) {
                conditionedSignCircuitListField.data.conditionedSignCircuitList = [];
            }
            
            AnnexaFormlyFactory.showModal('modalTramTemplate', modal, $scope.saveTramTemplate, false);
        }

        $scope.xmlTagsDescriptor = function() {
            $rootScope.loading(true);

            AdminFactory.getTramFieldsMetadataXML(window)
                .then(function (linkElement) {
                    if(window && window.navigator && window.navigator.msSaveOrOpenBlob) {
						try {
		                    window.navigator.msSaveOrOpenBlob(linkElement, 'annexa.fields.xml');
		                } catch (e) {
		                    console.log(e.message);
		                }	                
 	                } else {
		                var clickEvent = new MouseEvent("click", {
	                        "view": window,
	                        "bubbles": true,
	                        "cancelable": false
	                    });
	
	                    $rootScope.loading(false);
	                    linkElement.dispatchEvent(clickEvent);
					}
                }).catch(function (error) {
                    $rootScope.loading(false);
            });
        }

        $scope.jsonTagsDescriptor = function() {
            $rootScope.loading(true);

            AdminFactory.getTramFieldsMetadataXML(window,true)
                .then(function (linkElement) {
                    if(window && window.navigator && window.navigator.msSaveOrOpenBlob) {
						try {
		                    window.navigator.msSaveOrOpenBlob(linkElement, 'annexa.fields.json');
		                } catch (e) {
		                    console.log(e.message);
		                }	                
 	                } else {
			            var clickEvent = new MouseEvent("click", {
	                        "view": window,
	                        "bubbles": true,
	                        "cancelable": false
	                    });
	
	                    $rootScope.loading(false);
	                    linkElement.dispatchEvent(clickEvent);
					}
                }).catch(function (error) {
                $rootScope.loading(false);
            });
        }

        $scope.updateTramTemplate = function (val, prop) {
            $scope.cancelEditIsPublic = false;

            var model = angular.copy($scope.modelTramTemplates.data);
            if(prop == "procedures") {
                this.alerts = [];
                model[prop] = val;
                model['public'] = false;
                this.data['public'] = false;
            } else if(prop == "public") {
                if((model.procedures != null && !val) || val) {
                    this.alerts = [];
                    model[prop] = val;
                    if(val) {
                        this.data['procedures'] = null;
                        model['procedures'] = null;
                    }
                } else {
                    $scope.cancelEditIsPublic = true;
                    this.alerts.push( { msg: $filter('translate')('global.commonAdmin.literals.errorEditIsPublic') });
                    return "";
                }
            } else if(prop == 'language1' || prop == 'language2' || prop == 'language3') {
              model = val;
            } else if(prop == 'documentType'){
            	if(val && val.id){
            		model[prop] = {"id":val.id}
            	}else {
            		model[prop] = undefined;	
            	}
            } else{
                model[prop] = val;
            }

            if(model.documentType && !model.documentType.id){
            	model.documentType = undefined;
            }
            
            var ps = angular.copy(model.profiles);
            if(prop == 'profiles'){
            	model[prop] = [];
                _.forEach(val, function(item) {
                	model[prop].push( { profile: { id: item.id } });
                });
            } else if(model.profiles && model.profiles.length > 0){
            	model.profiles = [];
            	_.forEach(ps, function(item) {
            		model.profiles.push( { profile: { id: item.id } });
                });
            }
            
            AdminFactory.updateTramTemplate(model)
                .then(function (data) {
                	model.profiles = ps;
                    $scope.tableTramTemplates.reloadInternalData(false);
                }).catch(function (error) {
                	model.profiles = ps;
            });
        }

        $scope.replaceDocument = function () {
            if(this.annexaFormly.form.$valid) {
                $rootScope.loading(true);
                var self = this;
                var data = this.annexaFormly.model.modal_body;

                var send = {
                    idTemplate: this.template.id,
                    file: data.file
                };
                
                var acceptedFileType = true;
                var fileField = undefined;
                var modalBodyFields = $linq(this.annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'modal_body'");
                if(modalBodyFields) {
                    fileField = $linq(modalBodyFields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'file'");
                }

                if(fileField && fileField.data && fileField.data.accept) {
                    acceptedFileType = fileField.data.accept.includes(data.file.type);
                }

                if(acceptedFileType) {

                    data.file.upload = Upload.upload({
                        url: './api/tram/updateTramTemplateDocument',
                        data: send
                    });
                    
	                data.file.upload
	                    .then(function (data) {
	                        if(data.data) {
	                            var updatedTemplate = JSOG.decode(data.data);
	                            self.callerModal.data.fileName = updatedTemplate.fileName;
	                            self.callerModal.data.fileTypeDoc = updatedTemplate.fileTypeDoc;
	                            self.callerModal.data.idDocumentManager = updatedTemplate.idDocumentManager;
	                            self.callerModal.data.sizeDoc = updatedTemplate.sizeDoc;
	                            $rootScope.loading(false);
	                            self.close();
	                        }
	                    }).catch(function (error) {
	                        $rootScope.loading(false);
	                });
                } else {
                    DialogsFactory.error($filter('translate')('global.literals.documentTypeNotAccepted'));
                    $rootScope.loading(false);
                }
            }
        }

        $scope.replaceDocumentModal = function (template, callerModal) {
            var modal = globalModals.uploadFile;
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.template = template;
            modal.callerModal = callerModal;
            modal.annexaFormly.fields[0].fieldGroup[0].data.accept = '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\'';
            AnnexaFormlyFactory.showModal('modalUploadFile', modal, $scope.replaceDocument, false);
        }

        $scope.editOnline = function (idDocumentManager) {
            CommonService.getEditOnlineUrl(idDocumentManager).then(function(eou) {
                if(eou != null && eou != undefined && eou != ''){
                    window.location.href=eou;
                }
            }).catch(function(){
            });
        }

        $scope.download = function(idDocumentManager){
        	$rootScope.loading(true);
            $http.get("./api/repodocs/downloadAlfDoc?id="+idDocumentManager, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    var retDownload = {
                        file: undefined,
                        fileName: undefined,
                        linkElement: undefined,
                        clickEvent: undefined
                    }
                    headers = headers();
                    var contentType = headers['content-type'];
                    retDownload.file = new Blob([data], {type: contentType});
                    retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
                    try {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                        } else {
                            retDownload.linkElement = document.createElement('a');
                            var url = window.URL.createObjectURL(retDownload.file);
                            retDownload.linkElement.setAttribute('href', url);
                            retDownload.linkElement.setAttribute("download", retDownload.fileName);
                            retDownload.clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                        }
                        $rootScope.loading(false);
                    } catch (e) {
                        $rootScope.loading(false);
                    }
                }).error(function (error) {
                    $rootScope.loading(false);
            });
        }
        $scope.printIsPublic = function (model) {
            if($scope.cancelEditIsPublic) {
                return $filter('translate')('global.literals.templateIsPublic');
            } else {
                if (model) {
                    return $filter('translate')('global.literals.templateIsPublic');
                } else {
                    return $filter('translate')('global.literals.tempalteNoIsPublic');
                }
            }
        }

        $scope.printPresetSignCircuits = function (model) {
            var presetSignCircuits = "";

            angular.forEach(model, function (val, key) {
                if(presetSignCircuits != "") {
                    presetSignCircuits += ", ";
                }

                presetSignCircuits += val[$scope.languageColumn];
            })

            return presetSignCircuits;
        }

        $scope.printEditPresetSignCircuit = function (model) {
            if(model) {
                return $filter('translate')('global.literals.editCircuit');
            } else {
                return $filter('translate')('global.literals.editCircuitNo');
            }
        }

        $scope.printAllowThirds = function (model) {
            if(model) {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.allowThirds');
            } else {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.noAllowThirds');
            }
        }
        
        $scope.printNotHaveDocumentCustomField = function (model) {
            if(model) {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDocumentCustomField');
            } else {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.haveDocumentCustomField');
            }
        }
        
        $scope.printNotHaveDossierTransactionCustomField = function (model) {
            if(model) {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDossierTransactionCustomField');
            } else {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.haveDossierTransactionCustomField');
            }
        }
        
        $scope.printNotHaveDossierCustomField = function (model) {
            if(model) {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDossierCustomField');
            } else {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.haveDossierCustomField');
            }
        }

        $scope.openTramTemplate = function(id){
            if(id){
                RestService.findOne('TramTemplate', id)
                    .then(function(data) {
                        var modal = angular.copy(CommonAdminModals.tramTemplateEdit);
                        modal.data = JSOG.decode(data.data);
                        if(!modal.data.documentType){
                        	modal.data.documentType = {id:""};
                        }
                        _.forEach(modal.data.templateSignCircuit, function (value, key) {
                            value.tramTemplate = {id: modal.data.id};
                        });
                        modal.data.conditionedSignCircuitList = modal.data.templateSignCircuit;
                        modal.data.isEdit = true;
                        modal.data.tramTemplateId = modal.data.id;
                        modal.languageColumn = Language.getActiveColumn();
                        modal.updateTramTemplate = $scope.updateTramTemplate;
                        modal.replaceDocumentModal = $scope.replaceDocumentModal;
                        modal.editOnline = $scope.editOnline;
                        modal.download = $scope.download;
                        modal.printIsPublic = $scope.printIsPublic;
                        modal.procedures = GlobalDataFactory.procedures;
                        modal.presetSignCircuits = GlobalDataFactory.presetSignCircuit;
                        modal.printPresetSignCircuits = $scope.printPresetSignCircuits;
                        modal.printEditPresetSignCircuit = $scope.printEditPresetSignCircuit;
                        modal.printAllowThirds = $scope.printAllowThirds;
                        modal.printNotHaveDocumentCustomField = $scope.printNotHaveDocumentCustomField;
                        modal.printNotHaveDossierTransactionCustomField = $scope.printNotHaveDossierTransactionCustomField;
                        modal.printNotHaveDossierCustomField = $scope.printNotHaveDossierCustomField;
                        var documentTypes = angular.copy(GlobalDataFactory.documentTypes);
                        documentTypes.unshift( {id:"", language1:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty')} );
                        modal.documentTypes = documentTypes;
                        
                        modal.data.profiles = $linq(modal.data.profiles).select("x => x.profile").toArray();
                        modal.printProfiles = function (model) {
                            var perfiles = '';
                            angular.forEach(model, function (val, key) {
                                if(perfiles != '') {
                                    perfiles += ', ';
                                }
                                perfiles += val[$scope.languageColumn];
                            });

                            return perfiles;
                        }
                        
                        var selectableOptions = [];
                        _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                            var haveProfile = false;
                            if(modal.data.profiles) {
                                haveProfile = modal.data.profiles.some(function (profile) {
                                      return profile.id === value.id;
                                    });
                            }
                            if(value.expiryDate == null || haveProfile) {
                                selectableOptions.push(value);
                            }
                        });
                        
                        modal.profiles = selectableOptions;
                        $scope.modelTramTemplates = modal;
                        
                        AnnexaModalFactory.showModal('modalTramTemplateEdit', modal);
                    });
            }
        }
        $scope.doSearchFilter=function(queued) {
            $scope.tableTramTemplates.reloadInternalData(true);
        }

        $scope.deleteTramTemplate = function (id) {
            CommonAdminService.deleteTramTemplate(id)
                .then(function(data) {
                    $scope.tableTramTemplates.reloadInternalData(true);
                }).catch(function (error) {
            });
        }
    }]);