angular
    .module('annexaApp')
        .controller('EmgdeMetadataLocationSupportController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'RestService', 'Language', 'HelperService', 'ErrorFactory','AdminFactory', 'DialogsFactory', 'GlobalDataFactory', 'QuerysModals', 'QueryFactory', 'CustomFieldFactory', 'CacheFactory',
            function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, RestService, Language, HelperService, ErrorFactory, AdminFactory, DialogsFactory, GlobalDataFactory, QuerysModals, QueryFactory, CustomFieldFactory, CacheFactory) {
                $scope.showLoadingdiv = false;
                $scope.languageColumn = Language.getActiveColumn();
                $scope.tfilter = new TableFilter();
                $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');
                
                $scope.filter = [
                	{ id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
                	{ id: 'code', type: 'text', order: 3, label: 'global.literals.code'}
                ]
                $scope.additionalColumns = [
                	{ id: 'code', title: $filter('translate')('global.literals.code')}
                ]
                
                $scope.newEmgdeMetadataLocationSupport = angular.copy(CommonAdminModals.emgdeMetadataLocationSupportNew);
                
                $scope.viewElement = function (id) {
                    if(id) {
                    	RestService.findOne('EmgdeMetadataLocationSupport', id).then(function(data) {
                    		 var modal = angular.copy(CommonAdminModals.emgdeMetadataLocationSupportEdit);
                             modal.data = JSOG.decode(data.data);
                             modal.alerts = [];
                             modal.languageColumn = Language.getActiveColumn();
                             modal.updateEmgdeMetadataLocationSupport = $scope.updateEmgdeMetadataLocationSupport;
                    		AnnexaModalFactory.showModal('modalEmgdeMetadataLocationSupportEdit', modal);
                    	});
                    }
                };
                
                $scope.saveEmgdeMetadataLocationSupport = function(){
        	 		if (this.annexaFormly.form.$valid) {
        	 			var self = this;
                    	var data = this.annexaFormly.model.modal_body;
                    	var sendData = {
                    		code:((data.code)?data.code:undefined),
                    		language1:((data.language1)?data.language1:undefined),
                    		language2:((data.language2)?data.language2:undefined),
                    		language3:((data.language3)?data.language3:undefined)
                    	}
                    	RestService.insert('./api/emgdeMetadataLocationSupport', sendData).then(function (dataGet) {
                        	$rootScope.$broadcast('annexaAdminBaseReloadTable');
                        	self.close();
                        	if(GlobalDataFactory.emgdeListsSupports){
                            	GlobalDataFactory.emgdeListsSupports.length = 0;
                            }else{
                            	GlobalDataFactory.emgdeListsSupports = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('emgdeListsSupports')){
                            	CacheFactory.get('globalDataCache').remove('emgdeListsSupports')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }).catch(function (error) {
                        	self.alerts.push({ msg: $filter('translate')('global.commonAdmin.emgdeMetadataLocationSupport.errorCreate') });
                        });
                    }
                };
        	   
                $scope.updateEmgdeMetadataLocationSupport = function(val, prop) {
                	var emgdeMetadataLocationSupport = angular.copy(this.data);
                	var self = this;
                	self.alerts = [];
                	if(_.contains(['code'], prop)) {
                		emgdeMetadataLocationSupport[prop] = val;
                    }
                	RestService.update('./api/emgdeMetadataLocationSupport/'+emgdeMetadataLocationSupport.id, emgdeMetadataLocationSupport).then(function (dataAux) {
                		$rootScope.$broadcast('annexaAdminBaseReloadTable');
                		if(GlobalDataFactory.emgdeListsSupports){
                        	GlobalDataFactory.emgdeListsSupports.length = 0;
                        }else{
                        	GlobalDataFactory.emgdeListsSupports = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('emgdeListsSupports')){
                        	CacheFactory.get('globalDataCache').remove('emgdeListsSupports')
                        }
                        GlobalDataFactory.loadGlobalData();
                    }).catch(function (error) {
                    	self.alerts.push({ msg: $filter('translate')('global.commonAdmin.emgdeMetadataLocationSupport.errorCreate') });
                    });
                };
    }]);