angular
    .module('annexaApp')
        .controller('RelatedDossierRelationController',['$scope', '$http', '$rootScope', 'CommonAdminModals', 'Language', '$filter', 'RestService', 'AnnexaModalFactory', 'AdminFactory', 'GlobalDataFactory', 'CacheFactory', 'globalModals', 'AnnexaFormlyFactory', 
        	function ($scope, $http, $rootScope, CommonAdminModals, Language, $filter, RestService, AnnexaModalFactory, AdminFactory, GlobalDataFactory, CacheFactory, globalModals, AnnexaFormlyFactory) {
    	 	$scope.newRelatedDossierRelation = CommonAdminModals.relatedDossierRelationNew;
    	
    	 	$scope.saveRelatedDossierRelation = function(){
    	 		if (this.annexaFormly.form.$valid) {
                	var self = this;
                	var data = this.annexaFormly.model.modal_body;
                	var sendData = {
                			language1:((data && data.language1)?data.language1:'NotTranslated'),
                			language2:((data && data.language2)?data.language2:'NotTranslated'),
                			language3:((data && data.language3)?data.language3:'NotTranslated'),
                			inverseLanguage1:((data && data.inverseLanguage1)?data.inverseLanguage1:'NotTranslated'),
                			inverseLanguage2:((data && data.inverseLanguage2)?data.inverseLanguage2:'NotTranslated'),
                			inverseLanguage3:((data && data.inverseLanguage3)?data.inverseLanguage3:'NotTranslated'),
							parent:((data && data.parent_true)?data.parent_true:false)
                	}
                    RestService.insert('./api/related_dossier_relation', sendData).then(function (dataGet) {
                    	$rootScope.$broadcast('annexaAdminBaseReloadTable');
						if(GlobalDataFactory.relatedDossierRelations){
	                    	GlobalDataFactory.relatedDossierRelations.length = 0;
	                    }else{
	                    	GlobalDataFactory.relatedDossierRelations = [];
	                    }
	                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('RelatedDossierRelation')){
	                    	CacheFactory.get('globalDataCache').remove('RelatedDossierRelation')
	                    }
	                    GlobalDataFactory.loadGlobalData();
                    	self.close();
                    }).catch(function (error) {
                    	self.alerts.push({ msg: $filter('translate')('global.relatedDossierRelation.errorCreate') });
                    });
                }
            };
            
            $scope.filter = [
            	{ id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
            	{ id: 'inverseL'+Language.getActiveColumn().substring(1), type: 'text', order: 2, label: 'global.relatedDossierRelation.inverseLanguage' },
				{ id: 'parent', type: 'checkbox', order: 3, label: 'global.literals.relatedDossierRelationParent'},
			]

            $scope.additionalColumns = [
                { id: 'inverseL'+Language.getActiveColumn().substring(1), title: $filter('translate')('global.relatedDossierRelation.inverseLanguage')},
				{ id: 'parent', width: '10%', column: new BooleanColumn($filter, 'global.literals.relatedDossierRelationParent')}
            ]

            $scope.viewElement = function (id) {
                if(id) {
                	RestService.findOne('RelatedDossierRelation', id).then(function(data) {
                        var modal = angular.copy(CommonAdminModals.relatedDossierRelationEdit);
                        modal.data = JSOG.decode(data.data);
                        modal.alerts = [];
                        modal.languageColumn = Language.getActiveColumn();;
                        modal.inverseLanguageColumn = "inverseL"+modal.languageColumn.substring(1);
                        modal.printInverse = function(){
                            if(modal.data && modal.data[ modal.inverseLanguageColumn]){
                                return modal.data[modal.inverseLanguageColumn];
                            }
                            return '';
                        }
						modal.printParent = function(){
                            if(modal.data && modal.data.parent){
                                return $filter('translate')('global.literals.relatedDossierRelationParent')
                            }else{
								return $filter('translate')('global.literals.relatedDossierRelationParentNo');	
							}
                        }
                        modal.updateRelatedDossierRelationNew = $scope.updateRelatedDossierRelation;
                        AnnexaModalFactory.showModal('modalRelatedDossierRelationEdit', modal);
                    });
                }
            };
    	   
            $scope.updateRelatedDossierRelation = function(val, prop) {
            	var relatedDossierRelation = angular.copy(this.data);
				if(prop === 'parent'){
					relatedDossierRelation[prop] = val;	
				}
            	var self = this;
            	self.alerts = [];
            	RestService.update('./api/related_dossier_relation/'+relatedDossierRelation.id, relatedDossierRelation).then(function (dataGetDocument) {
                	$rootScope.$broadcast('annexaAdminBaseReloadTable');
					if(GlobalDataFactory.relatedDossierRelations){
                    	GlobalDataFactory.relatedDossierRelations.length = 0;
                    }else{
                    	GlobalDataFactory.relatedDossierRelations = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('RelatedDossierRelation')){
                    	CacheFactory.get('globalDataCache').remove('RelatedDossierRelation')
                    }
                    GlobalDataFactory.loadGlobalData();
               }).catch(function (error) {
                	self.alerts.push({ msg: $filter('translate')('global.relatedDossierRelation.errorCreate') });
                });
            };
            
        }]);