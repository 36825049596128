/**
 * Created by dpardell on 29/04/2016.
 */
angular
    .module('annexaApp')
    .controller('PresetSignCircuitsController',['$rootScope', '$scope', '$http', '$state', '$filter', 'TableFilter','DTColumnBuilder', 'DTOptionsBuilder','apiAdmin','CommonAdminService','HeaderService','RestService', 'Language', 'CommonAdminModals', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'AdminFactory', '$timeout', 'HelperService', 'GlobalDataFactory', 'CacheFactory', 'DialogsFactory',
    function ($rootScope, $scope, $http, $state, $filter, TableFilter, DTColumnBuilder, DTOptionsBuilder,apiAdmin,CommonAdminService,HeaderService,RestService, Language, CommonAdminModals, AnnexaFormlyFactory, AnnexaModalFactory, AdminFactory, $timeout, HelperService, GlobalDataFactory, CacheFactory, DialogsFactory){
        $scope.showLoadingdiv = false;

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.presetSignCircuits') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton',undefined,'#admin-presetSignCircuit','fa fa-plus','global.commonAdmin.newPresetSignCircuit',undefined,'openNewPresetSignCircuit')
                ];
            }
        });
        HeaderService.changeState($state.current);

        $scope.languageColumn = Language.getActiveColumn();
        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');

        $scope.dtDefPresetSignCircuits = new TableData('sign','PresetSignCircuit','./api/sign/byfilter').setSort([[1, 'asc']]).setSortName('name');

        var nameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
        var dateColumn = new DateColumn($filter,'global.commonAdmin.datatable.createDate');
        var presetSignCircuitActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
            new ActionButton('global.literals.see', 'openPresetSignCircuit([data])', 'fa-eye'),
            [new ActionButton('global.literals.remove', 'deletePresetSignCircuit([data])')]);


        $scope.tablePresetSignCircuits = {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn(nameColumn.getColumn()).withTitle(nameColumn.getTitle()).renderWith(nameColumn.getRender),
                DTColumnBuilder.newColumn('createdDate').renderWith(dateColumn.getRender).withTitle(dateColumn.getTitle()),
                DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(presetSignCircuitActionsColumn.getTitle()).renderWith(presetSignCircuitActionsColumn.getRender).notSortable()
            ],
            filterCall: {},
            filterCallAux : {},
            reloadInternalData: function(resetPaging){
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = $scope.tfilter.getFilterCallAux();
                this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                this.filterCallAux.langColumn = $scope.languageColumn;
                this.table.reloadData(function callbackInstance(json) {},resetPaging);
            }
        };

        $scope.doSearchFilter=function(queued) {
            $scope.tablePresetSignCircuits.reloadInternalData(true);
        };

        $scope.openPresetSignCircuit = function (id){
            if(id) {
                RestService.findOne('PresetSignCircuit', id).then(function(data) {
                    var modal = angular.copy(CommonAdminModals.presetSignCircuitEdit);
                    var saveActionFunction = function(){
                    	CommonAdminService.insertOrUpdatePresetSignCircuit(modal.data).then(function (dataUpdated) {
                    		modal.data = JSOG.decode(dataUpdated);
                            angular.forEach(modal.data.presetSignActions,function(value, key){
                            	if(!value.paralelConditionedAction && value.signActionType && value.signActionType.code != 'THIRDSIGN' && value.signActionType.code != 'AUTOSIGN_ORGAN') {
                            		var aux = HelperService.findFirstElementWhere(AdminFactory.signatoryTypes, 'id', value.signatoryType.id, -1);
                                    if(aux != -1){
                                        value.signatoryType = aux;
                                    }
                                    var aux2 = HelperService.findFirstElementWhere(AdminFactory.signActionTypes, 'id', value.signActionType.id, -1);
                                    if(aux != -1){
                                        value.signActionType = aux2;
                                    }
                            	}
                            	if(value.paralelConditionedAction && value.paralelSignAction) {
                            		value.paralelSignAction = $linq(value.paralelSignAction).where("x => x.deleted == false").toArray();
                            	}
                                if(value.user){
                                    var name = value.user.name;
                                    if(value.user.surename1){ name = name + " "+value.user.surename1};
                                    if(value.user.surename2){name = name + " "+value.user.surename2};
                                    value.userSelect = { id: value.user.id, object: {id:value.user.id, name: value.user.name, surename1:value.user.surename1, surename2:value.user.surename2}, value: name };
                                }
                            });
                            if(modal.data.sendAlertProfile){
                            	modal.data.profilesAux = $linq(modal.data.presetSignCircuitAlertProfiles).select("x => x.profile").toArray();
        						modal.data.profiles = $linq(modal.data.presetSignCircuitAlertProfiles).select("x => x.profile").toArray();
                            }
                            var presetSignActionDefinition = new PresetSignActionsDefinition('', $rootScope.LoggedUser.id, $scope.languageColumn, true, modal.data.presetSignActions, AdminFactory.signatoryTypes, AdminFactory.signActionTypes, saveActionFunction, removeActionFunction);
                            modal.presetSignActionDefinition = presetSignActionDefinition;
                            if(GlobalDataFactory.presetSignCircuit){
                            	GlobalDataFactory.presetSignCircuit.length = 0;
                            }else{
                            	GlobalDataFactory.presetSignCircuit = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                            }
                            GlobalDataFactory.loadGlobalData();
                            modal.presetSignActionDefinitionOriginal = angular.copy(presetSignActionDefinition);
                        }).catch(function (error) {
                        	modal.presetSignActionDefinition = angular.copy(modal.presetSignActionDefinitionOriginal);
                        	DialogsFactory.error($filter('translate')('global.commonAdmin.errors.errorEditPresetSignCircuit'), $filter('translate')('DIALOGS_ERROR_MSG'));
                        });
                    };

                    var removeActionFunction = function(){
                        CommonAdminService.insertOrUpdatePresetSignCircuit(modal.data).then(function (dataUpdated) {
                        	var indexActionSplice = -1;
                        	modal.data = JSOG.decode(dataUpdated);
                            angular.forEach(modal.data.presetSignActions,function(value, key){
                            	if(!value.paralelConditionedAction && value.signActionType && value.signActionType.code != 'THIRDSIGN' && value.signActionType.code != 'AUTOSIGN_ORGAN') {
	                                var aux = HelperService.findFirstElementWhere(AdminFactory.signatoryTypes, 'id', value.signatoryType.id, -1);
	                                if(aux != -1){
	                                    value.signatoryType = aux;
	                                }
	                                var aux2 = HelperService.findFirstElementWhere(AdminFactory.signActionTypes, 'id', value.signActionType.id, -1);
	                                if(aux != -1){
	                                    value.signActionType = aux2;
	                                }
                            	}
                                if(value.user){
                                    var name = value.user.name;
                                    if(value.user.surename1){ name = name + " "+value.user.surename1};
                                    if(value.user.surename2){name = name + " "+value.user.surename2};
                                    value.userSelect = { id: value.user.id, object: {id:value.user.id, name: value.user.name, surename1:value.user.surename1, surename2:value.user.surename2}, value: name };
                                }
                                if(value.deleted) {
                                	indexActionSplice = key;
                                }
                            });
                            if(modal.data.sendAlertProfile){
                            	modal.data.profilesAux = $linq(modal.data.presetSignCircuitAlertProfiles).select("x => x.profile").toArray();
        						modal.data.profiles = $linq(modal.data.presetSignCircuitAlertProfiles).select("x => x.profile").toArray();
                            }
                            var presetSignActionDefinition = new PresetSignActionsDefinition('', $rootScope.LoggedUser.id, $scope.languageColumn, true, modal.data.presetSignActions, AdminFactory.signatoryTypes, AdminFactory.signActionTypes, saveActionFunction, removeActionFunction);
                            modal.presetSignActionDefinition = presetSignActionDefinition;
                            if(indexActionSplice != -1) {
                            	modal.presetSignActionDefinition.presetSignActions.splice(indexActionSplice, 1);
                            }
                            if(GlobalDataFactory.presetSignCircuit){
                            	GlobalDataFactory.presetSignCircuit.length = 0;
                            }else{
                            	GlobalDataFactory.presetSignCircuit = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                            }
                            GlobalDataFactory.loadGlobalData();
                            modal.presetSignActionDefinitionOriginal = angular.copy(presetSignActionDefinition);
                        }).catch(function (error) {
                        	modal.presetSignActionDefinition = angular.copy(modal.presetSignActionDefinitionOriginal);
                        	DialogsFactory.error($filter('translate')('global.commonAdmin.errors.errorDeletePresetSignCircuit'), $filter('translate')('DIALOGS_ERROR_MSG'));
                        });
                    };

                    var updatePresetSignCircuit = function(val, prop){
                        var valid = true;
						if(prop != 'language1' && prop != 'language2' && prop != 'language3' && prop != 'signatureWithoutOrder' && prop != 'sendAlertProfile' && prop != 'profiles'){
                            this.data[prop] = val;
                        }else if(prop == 'signatureWithoutOrder'){
							var valprop = ((val.id === false || val.id === true)?val.id:false);
							if(modal.data && modal.data.presetSignActions){
								_.forEach(modal.data.presetSignActions, function(valueAct){
									if(valprop && (!valueAct.signActionType || !valueAct.signActionType.signatureWithoutOrder)){
										valid = false;
									}
								});
							}
							if(valid){
								this.data[prop] = valprop;
							}
						}else if(prop == 'sendAlertProfile') {
							var valprop = ((val.id === false || val.id === true)?val.id:false);
							this.data[prop] = valprop;
						}else if(prop == 'profiles') {
							modal.data.presetSignCircuitAlertProfiles = []
							_.forEach(val, function(pro){
								modal.data.presetSignCircuitAlertProfiles.push({
									profile: {
										id: pro.id
									}
								});
							});
							this.data = modal.data;
							this.data[prop] = val;
						}
						if(valid){
	                        CommonAdminService.insertOrUpdatePresetSignCircuit(this.data).then(function (dataUpdated) {
	                        	if(!dataUpdated.sendAlertProfile) {
	                        		modal.data.presetSignCircuitAlertProfiles = [];
	                        		modal.data.profiles = [];
	                        		modal.data.profilesAux = [];
	                        	}
	                            if(GlobalDataFactory.presetSignCircuit){
	                            	GlobalDataFactory.presetSignCircuit.length = 0;
	                            }else{
	                            	GlobalDataFactory.presetSignCircuit = [];
	                            }
	                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
	                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
	                            }
	                            GlobalDataFactory.loadGlobalData();
	                        }).catch(function (error) {
	                        	DialogsFactory.error($filter('translate')('global.commonAdmin.errors.errorEditPresetSignCircuit'), $filter('translate')('DIALOGS_ERROR_MSG'));
	                        });
						}else{
							DialogsFactory.error($filter('translate')('global.documents.modal.sendToSign.errorCustomSignActionTypeWithoutOrders'), $filter('translate')('DIALOGS_ERROR_MSG'));
							return '';	                            
						}
                    }

                    modal.data = JSOG.decode(data.data);
                    modal.data.signatureWithoutOrder = false;
					modal.yesNoBoolean = apiAdmin.yesNoBoolean;
					modal.printYesNo = function (value) {
		                if(value.id === false || value.id === true){
							return $filter('translate')(value.description);
						}else{
							var exist = $linq(apiAdmin.yesNoBoolean).firstOrDefault(undefined, "x => x.id == "+((value)?true:false));
							if(exist && exist.description) {
			                    return $filter('translate')(exist.description);
			                } else {
			                    return '';
			                }
						}
		            }
					modal.data.profilesAux = [];
					modal.data.profiles = [];
					if(modal.data.presetSignCircuitAlertProfiles && modal.data.presetSignCircuitAlertProfiles.length > 0) {
						modal.data.profilesAux = $linq(modal.data.presetSignCircuitAlertProfiles).select("x => x.profile").toArray();
						modal.data.profiles = $linq(modal.data.presetSignCircuitAlertProfiles).select("x => x.profile").toArray();
					}
					modal.printProfiles = function(modelValue){
                    	var profiles = '';

                    	if(modelValue && modelValue.length > 0){
                    		_.forEach(modelValue, function(value){
                    			if(profiles != '') {
                    				profiles += ', ';
                                }
                    			profiles += value[modal.languageColumn];
                    		});
                    	}
                    	return profiles;
                    }
					var selectableOptions = [];
                    _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                        var haveProfile = false;
                        if(modal.data.profiles) {
                            haveProfile = modal.data.profiles.some(function (profile) {
                                  return profile.id === value.id;
                                });
                        }
                        if(value.expiryDate == null || haveProfile) {
                            selectableOptions.push(value);
                        }
                    });
                    modal.profiles = selectableOptions;
                    angular.forEach(modal.data.presetSignActions,function(value, key){
                    	if(!value.paralelConditionedAction && value.signActionType && value.signActionType.code != 'THIRDSIGN' && value.signActionType.code != 'AUTOSIGN_ORGAN') {
                    		var aux = HelperService.findFirstElementWhere(AdminFactory.signatoryTypes, 'id', value.signatoryType.id, -1);
                            if(aux != -1){
                                value.signatoryType = aux;
                            }
                            var aux2 = HelperService.findFirstElementWhere(AdminFactory.signActionTypes, 'id', value.signActionType.id, -1);
                            if(aux != -1){
                                value.signActionType = aux2;
                            }
                    	}
                        if(value.user){
                            var name = value.user.name;
                            if(value.user.surename1){ name = name + " "+value.user.surename1};
                            if(value.user.surename2){name = name + " "+value.user.surename2};
                            value.userSelect = { id: value.user.id, object: {id:value.user.id, name: value.user.name, surename1:value.user.surename1, surename2:value.user.surename2}, value: name };
                        }
                    });
                    var presetSignActionDefinition = new PresetSignActionsDefinition('', $rootScope.LoggedUser.id, $scope.languageColumn, true, modal.data.presetSignActions, AdminFactory.signatoryTypes, AdminFactory.signActionTypes, saveActionFunction, removeActionFunction);
                    presetSignActionDefinition.signatureWithoutOrder = ((modal.data && modal.data.signatureWithoutOrder)?modal.data.signatureWithoutOrder:false);
					modal.languageColumn = Language.getActiveColumn();
					modal.signatureWithoutOrderColumn = 'description';
					modal.sendAlertProfileColumn = 'description';
                    modal.extra = $scope.tableDocumentType;
                    modal.presetSignActionDefinition = presetSignActionDefinition;
                    modal.presetSignActionDefinitionOriginal = angular.copy(presetSignActionDefinition);
                    modal.updatePresetSignCircuit = updatePresetSignCircuit;
                    modal.callCloseFunction = $scope.doSearchFilter;
                    AnnexaModalFactory.showModal('modaPresetSignCircuitEdit', modal);
                })
            }
        };

        $scope.openNewPresetSignCircuit = function (){
            var saveNewPresetSignCircuit = function(){
            	modal.alerts = [];
                form.form.$setSubmitted();
                if (form.form.$valid) {
                    var valid = true;
                    angular.forEach(form.model.presetSignActions, function(value, key){
                    	if(value.actionOrder && value.signActionType && value.maxDurationDays && value.signActionType.code == 'THIRDSIGN'){
                    		if(!value.third || !value.thirdAddress){
                                valid = false;
                            }else if(form.model.signatureWithoutOrder && !value.signActionType.signatureWithoutOrder){
								valid = false;
							}
                    	}else if(value.actionOrder && value.signActionType && value.maxDurationDays && value.signActionType.code == 'AUTOSIGN_ORGAN'){
                    		if(!value.diligenceStampType) {
                    			valid = false;
                    		}else if(form.model.signatureWithoutOrder && !value.signActionType.signatureWithoutOrder){
								valid = false;
							}
                    		if(valid) {
                    			value.diligenceStampType = { id : value.diligenceStampType.id};
                    			if(value.diligenceStampType.canChange) {
                        			value.customText = value.subject;
                    			} else {
                        			value.customText = value.subjectAux;
                    			}
                    		}
                    	}else if(value.actionOrder && value.signatoryType && value.signActionType && value.maxDurationDays){
                        	if(!value.actionLevel && !value.user){
                                valid = false;
							}else if(form.model.signatureWithoutOrder && !value.signActionType.signatureWithoutOrder){
	 							valid = false;
							}
                        }else if (value.paralelConditionedAction) {
                        	if(!value.paralelSignAction || value.paralelSignAction.length == 0) {
                        		valid = false;
                        	}
                        }else{
                            valid = false;
                        }
                    });
                    
                    if(form.model.sendAlertProfile && (!form.model.profiles || form.model.profiles.length == 0)){
                    	valid = false;
                    	modal.alerts.push({ msg: 'global.documents.modal.sendToSign.errorAlertProfiles' })
                    }
                    form.model.presetSignCircuitAlertProfiles = []
                    if(form.model.sendAlertProfile && form.model.profiles && form.model.profiles.length > 0) {
                    	angular.forEach(form.model.profiles, function(value, key){
                    		form.model.presetSignCircuitAlertProfiles.push({
								profile: {
									id: value
								}
							});
                    	});
                    }
                    
                    if(valid && form.model.presetSignActions.length > 0){
                        CommonAdminService.insertOrUpdatePresetSignCircuit(form.model).then(function(data) {
                            modal.extra.reloadInternalData(true);
                            if(GlobalDataFactory.presetSignCircuit){
                            	GlobalDataFactory.presetSignCircuit.length = 0;
                            }else{
                            	GlobalDataFactory.presetSignCircuit = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                            }
                            GlobalDataFactory.loadGlobalData();
                            modal.close();
                        }).catch(function(){
                            var b=0;
                        });
                    }else if(form.model.presetSignActions.length == 0) {
                    	modal.alerts.push({ msg: 'global.documents.modal.sendToSign.errorCustomEmpty' })
                    }
                }
            };

            var savePreset = function(){
                $timeout(function () {
                    angular.element('#smPresetForm' +modal.id).trigger('click');
                })
            };


			var getProfileValue = function(value){
				var prof = '';
	            if(vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfilesAux && vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfilesAux.length > 0){
	                _.forEach(vm.dossierTabsComponent.dossierComponent.dossier.dossierResponsibleProfilesAux, function(value){
	                    if(prof){
	                        prof += ", "+value[vm.languageColumn];
	                    }else{
	                        prof += value[vm.languageColumn];
	                    }
	                })
	            }
	            return prof;
			}
			var allProfiles = $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray();
            
            var modal = angular.copy(CommonAdminModals.presetSignCircuitNew);
            var data = {row: true,informed: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
            var form = new AnnexaFormly();
            var field1 = form.createField('language1','annexaInputLanguage','col-sm-12',new AnnexaFormlyFieldLanguageTemplateOptions('language','','global.literals.name','horitzontal',true,false,true, $rootScope.app.languagedef),data);
            form.fields.push(field1);
			var field2 = form.createField('sendAlertProfile','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.documents.modal.sendToSign.alertProfilesendCircuit','id','description',apiAdmin.yesNoBoolean,true),data);
			field2.templateOptions.onSelected = function(item, options) {
	            var $rootScope = angular.element(document.body).injector().get('$rootScope');
	            $rootScope.$broadcast('sendAlertProfileSelected', { item: item });
	        }
			form.fields.push(field2);
			form.fields.push(
					form.createField(
                    'profiles', 'annexaMultipleSelectRow', 'col-sm-12',
                    new AnnexaFormlyFieldSelectTemplateOptions('global.literals.profiles', 'id',
                        Language.getActiveColumn(), allProfiles, false),
                    data)
			);
            form.model = {presetSignActions:[] };
            var presetSignActionDefinition = new PresetSignActionsDefinition('', $rootScope.LoggedUser.id, $scope.languageColumn, true, form.model.presetSignActions, AdminFactory.signatoryTypes, AdminFactory.signActionTypes);
            presetSignActionDefinition.signatureWithoutOrder = false;
			form.addSubmitCallback(saveNewPresetSignCircuit);
            modal.savePreset = saveNewPresetSignCircuit;
            modal.form = form;
            modal.id = 'modalPresetSignCircuitNew'+new Date().getTime();
            modal.presetSignActionDefinition = presetSignActionDefinition;
            modal.extra = $scope.tablePresetSignCircuits;
            modal.noFooter = false;
            modal.annexaFormly = form;
            modal.submitModal = modal.annexaFormly.onSubmit;
            modal.alerts = [];
            AnnexaModalFactory.showModal(modal.id, modal);
        };

        $scope.deletePresetSignCircuit = function (id){
            CommonAdminService.deletePresetSignCircuit(id).then(function (dataUpdate) {
                $scope.tablePresetSignCircuits.reloadInternalData(true);
                if(GlobalDataFactory.presetSignCircuit){
                	GlobalDataFactory.presetSignCircuit.length = 0;
                }else{
                	GlobalDataFactory.presetSignCircuit = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
                }
                GlobalDataFactory.loadGlobalData();
            }).catch(function (error) {
				var errorMsg = 'global.errors.unknownDelete';
				if(error && error.message){
					if(error.message.toUpperCase() == 'Circuit used in document type'.toUpperCase() || error.message.toUpperCase() == 'Circuit used in template'.toUpperCase() || error.message.toUpperCase() == 'Circuit used in sec template'.toUpperCase() || error.message.toUpperCase() == 'Circuit used in tram template'.toUpperCase()){
						errorMsg = 'global.errors.deleted_related_entities';
					}
				}
				DialogsFactory.error($filter('translate')(errorMsg), $filter('translate')('DIALOGS_ERROR_MSG'));
            });
        };
    }]);