angular
    .module('annexaApp')
    .controller('PublishSiteController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'DialogsFactory', 'CommonService', 'globalModals', '$http', 
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory,DialogsFactory, CommonService, globalModals, $http) {

    	$scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' }
        ];
    	
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.publishSiteNew);
            AnnexaFormlyFactory.showModal('modalNewPublishSite', modal, $scope.savePublishSite, false);
        }

        $scope.savePublishSite = function (publishSite) {
            var modal = this;

            var model = {
                language1: publishSite.annexaFormly.model.modal_body.language1,
                language2: publishSite.annexaFormly.model.modal_body.language2 ? publishSite.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: publishSite.annexaFormly.model.modal_body.language3 ? publishSite.annexaFormly.model.modal_body.language3 : 'NotTranslated',
            }

            RestService.insert('./api/reg/publish_site', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    if(GlobalDataFactory.publishSite){
                    	GlobalDataFactory.publishSite.length = 0;
                    }else{
                    	GlobalDataFactory.publishSite = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PublishSite')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PublishSite')
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.close();
                }).catch(function (error) {
                	modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
                })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('PublishSite', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);
                    var modal = CommonAdminModals.editPublishSite;
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updatePublishSite = $scope.updatePublishSite;
                    AnnexaModalFactory.showModal('modalPublishSiteEdit', modal);
                });
        }
        
        $scope.updatePublishSite = function(val, prop) {
			var self = this;
			var statusesAux = this.data.statuses;
            var modelAux = angular.copy(this.data);
			var model = angular.copy(this.data);
			
            RestService.update('./api/reg/publish_site/' + model.id, model)
                .then(function (data) {
                   	self.data = data;
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if(GlobalDataFactory.publishSite){
                    	GlobalDataFactory.publishSite.length = 0;
                    }else{
                    	GlobalDataFactory.publishSite = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PublishSite')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PublishSite')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
					self.data = modelAux;
					self.alerts.push({ msg: $filter('translate')('global.errors.unknownUpdate') })
			});
        }
    }])