/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('TemplateAdminController', ['$scope', '$state', '$filter', 'DTColumnBuilder', 'CommonAdminService', 'apiAdmin', '$rootScope', 'TableFilter','HeaderService', 'Language', 'AdminFactory', 'CommonAdminModals', 'AnnexaFormlyFactory', 'CommonService', '$timeout', 'Upload', 'RestService', 'AnnexaModalFactory', 'globalModals', 'GlobalDataFactory', 'DialogsFactory', '$http', 'HelperService', 'CacheFactory', 
    function ($scope, $state, $filter, DTColumnBuilder, CommonAdminService, apiAdmin, $rootScope, TableFilter, HeaderService, Language, AdminFactory, CommonAdminModals, AnnexaFormlyFactory, CommonService, $timeout, Upload, RestService, AnnexaModalFactory, globalModals, GlobalDataFactory, DialogsFactory, $http, HelperService, CacheFactory) {
        $rootScope.loading(false);
        $scope.languageColumn = Language.getActiveColumn();
        $scope.templateTree = CommonService.getTreeData(AdminFactory.templateTypes, $scope.languageColumn);
        $scope.archiveClassificationsTree = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, $scope.languageColumn);
        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement('name', 'text', 0, 'global.literals.name');
        $scope.tfilter.addElement('description', 'text', 1, 'global.literals.description');
        $scope.tfilter.addElement('templateType', 'select-tree', 2, 'global.literals.type',undefined, AdminFactory.templateTypes,undefined,undefined,false,undefined, $scope.languageColumn);
        $scope.tfilter.addElement('documentType', 'select', 3, 'global.literals.docType', 3, HelperService.addAllSelect(angular.copy(GlobalDataFactory.documentTypes),Language.getActiveColumn(), $scope.app.language), true, Language.getActiveColumn(), false);

        $scope.dtDefTemplates = new TableData('doc', 'Template', './api/doc/byfilter', 'rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>', 'name', [[1,'asc']]);

        var docTemplateTypeColumn = new DatabaseTranslatedColumn($filter, 'global.literals.type',Language.getActiveColumn());
        var docTemplateDocumentTypeColumn = new DatabaseTranslatedColumn($filter, 'global.literals.docType',Language.getActiveColumn());
        var docTemplateActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                        new ActionButton('global.literals.see','openDocTemplate([data])','fa-eye'),
                                                        [new ActionButton('global.literals.remove','deleteDocTemplate([data])')]);

        $scope.tableTemplates={
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn('name').withTitle($filter('translate')('global.literals.name')),
                DTColumnBuilder.newColumn('templateType.' + docTemplateTypeColumn.getColumn()).withTitle(docTemplateTypeColumn.getTitle()).renderWith(docTemplateTypeColumn.getRender),
                DTColumnBuilder.newColumn('description').withTitle($filter('translate')('global.literals.description')),
                DTColumnBuilder.newColumn('documentType.' + docTemplateDocumentTypeColumn.getColumn()).withTitle(docTemplateDocumentTypeColumn.getTitle()).renderWith(docTemplateDocumentTypeColumn.getRender),
                DTColumnBuilder.newColumn('id').withOption('width', '120px').withOption('className', 'text-center').withTitle(docTemplateActionColumn.getTitle()).notSortable().renderWith(docTemplateActionColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux:{ langColumn: Language.getActiveColumn() },
            reloadInternalData:function(resetPaging){
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux =  { langColumn: Language.getActiveColumn() };
                this.table.reloadData(function callbackInstance(json) {}, resetPaging);
            }
        };
        $scope.modelDocTemplate;

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.templates') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-doc-templates','fa fa-arrow-down','global.commonAdmin.literals.jsonTagsDescriptor',undefined,'jsonTagsDescriptor'),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-doc-templates','fa fa-plus','global.commonAdmin.newTemplate',undefined,'newDocTemplate')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.xmlTagsDescriptor = function() {
            $rootScope.loading(true);

            AdminFactory.getTramFieldsMetadataXML(window)
                .then(function (linkElement) {
					if(window && window.navigator && window.navigator.msSaveOrOpenBlob) {
						try {
		                    window.navigator.msSaveOrOpenBlob(linkElement, 'annexa.fields.xml');
		                } catch (e) {
		                    console.log(e.message);
		                }	                
 	                } else {
	                    var clickEvent = new MouseEvent("click", {
	                        "view": window,
	                        "bubbles": true,
	                        "cancelable": false
	                    });
	
	                    $rootScope.loading(false);
	                    linkElement.dispatchEvent(clickEvent);
	                }
                }).catch(function (error) {
                $rootScope.loading(false);
            });
        }

        $scope.jsonTagsDescriptor = function() {
            $rootScope.loading(true);

            AdminFactory.getTramFieldsMetadataXML(window,true)
                .then(function (linkElement) {
                    if(window && window.navigator && window.navigator.msSaveOrOpenBlob) {
						try {
		                    window.navigator.msSaveOrOpenBlob(linkElement, 'annexa.fields.json');
		                } catch (e) {
		                    console.log(e.message);
		                }	                
 	                } else {
		                var clickEvent = new MouseEvent("click", {
	                        "view": window,
	                        "bubbles": true,
	                        "cancelable": false
	                    });
	
	                    $rootScope.loading(false);
	                    linkElement.dispatchEvent(clickEvent);
					}
                }).catch(function (error) {
                $rootScope.loading(false);
            });
        }

        $scope.saveDocTemplate = function ($modal) {
            if ($modal.annexaFormly.form.$valid) {
                var data = $modal.annexaFormly.model;

                var haveError = false;
                var model = {};
                var file = undefined;
                
                if (data.row1) {
                    if (data.row1.templateType) {
                        model.templateType = {id: data.row1.templateType.$selected.id};
                    }
                    if (data.row1.documentType) {
                        model.documentType = {id: data.row1.documentType};
                    }
                }

                if (data.row2) {
                    if (data.row2.name) {
                        model.name = data.row2.name;
                    }
                    if (data.row2.file) {
                    	file = data.row2.file;
                    }
                }

                if (data.row3) {
                    if (data.row3.description) {
                        model.description = data.row3.description;
                    }
                    if (data.row3.archiveClassification && data.row3.archiveClassification.$selected && data.row3.archiveClassification.$selected.id) {
                        model.archiveClassification = {id: data.row3.archiveClassification.$selected.id};
                    }
                }

                var conditionedSignCircuitListField = $linq($modal.annexaFormly.fields[3].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
                if(conditionedSignCircuitListField) {
                	model.templateSignCircuit = conditionedSignCircuitListField.data.conditionedSignCircuitList;
                }
                
                
                if (data.row5) {
                	if(data.row5.profiles) {
	                	var profiles = [];
	                    if(data.row5.profiles){
	                    	model.profiles = [];
		                    _.forEach(data.row5.profiles, function(item) {
		                    	model.profiles.push({ profile: { id: item } });
		                    });
	                	}
                	}
                }
                if(data.row6){
                	model.notHaveDossierCustomField = data.row6.notHaveDossierCustomField_false || false;
                	model.notHaveDossierTransactionCustomField = data.row6.notHaveDossierTransactionCustomField_false || false;
                	model.notHaveDocumentCustomField = data.row6.notHaveDocumentCustomField_false || false;
                }
                var acceptedFileType = true;
                var fileField = undefined;
                var row2Fields = $linq($modal.annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'row2'");
                if(row2Fields) {
                	fileField = $linq(row2Fields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'file'");
                }
                if(fileField && fileField.data && fileField.data.accept && file) {
                	acceptedFileType = fileField.data.accept.includes(file.type);
                }

                if(acceptedFileType && !haveError) {
                	$scope.uploadTemplate(model, file, $modal);
                } else if(!acceptedFileType) {
                    DialogsFactory.error($filter('translate')('global.literals.documentTypeNotAccepted'));
                }
            }
        }
        
        $scope.uploadTemplate = function(model, file, $modal) {
        	RestService.insertMultiPartData('./api/doc/template/with_files', model, file)
                .then(function (data) {
                    $modal.close();
                    if($modal && $modal.extra && $modal.extra.table) {
                        $modal.extra.table.reloadInternalData(true);
                    }
                    if(GlobalDataFactory.templates){
                    	GlobalDataFactory.templates.length = 0;
                    }else{
                    	GlobalDataFactory.templates = [];
                    }
                    if(GlobalDataFactory.templateTree){
                    	GlobalDataFactory.templateTree.length = 0;
                    }else{
                    	GlobalDataFactory.templateTree = [];
                    }
                    if(GlobalDataFactory.templateTypes){
                    	GlobalDataFactory.templateTypes.length = 0;
                    }else{
                    	GlobalDataFactory.templateTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Template')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Template')
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=TemplateType')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=TemplateType')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function(error) {
            });
        }

        $scope.updateDocTemplate = function(val, prop) {
            var selfAux = $scope.modelDocTemplate;
            var model = angular.copy($scope.modelDocTemplate.data);

            model.templateType = { id: model.templateType.model.$selected.id };
            if(prop == 'templateType' || prop == 'archiveClassification') {
                model[prop] = { id: val.$selected.id };
            } else if(prop == 'documentType') {
            	if(val && val.id){
            		model[prop] = { id: val.id };
            	}else {
            		model[prop] = undefined;	
            	}
            } else {
                model[prop] = val;
            }
            
            if(prop == 'profiles'){
            	model[prop] = [];
                _.forEach(val, function(item) {
                	model[prop].push( { profile: { id: item.id } });
                });
            } else if(model.profiles && model.profiles.length > 0){
            	var profiles = [];
            	_.forEach(model.profiles, function(item) {
            		profiles.push( { profile: { id: item.id } });
                });
            	model.profiles = profiles;
            }

            if(model.archiveClassification && model.archiveClassification.model){
                if(model.archiveClassification.model.$selected && model.archiveClassification.model.$selected.id){
                    model.archiveClassification = {id:model.archiveClassification.model.$selected.id};
                }else{
                    model.archiveClassification = undefined;
                }
            }
            if(model.templateType && model.templateType.model){
                if(model.templateType.model.$selected && model.templateType.model.$selected.id){
                    model.templateType = {id:model.templateType.model.$selected.id};
                }else{
                    model.templateType = undefined;
                }
            }
            if(model.documentType && !model.documentType.id){
            	model.documentType = undefined;
            }
            
            AdminFactory.updateDocTemplate(model)
                .then(function (data) {
                	if(GlobalDataFactory.templates){
                    	GlobalDataFactory.templates.length = 0;
                    }else{
                    	GlobalDataFactory.templates = [];
                    }
                    if(GlobalDataFactory.templateTree){
                    	GlobalDataFactory.templateTree.length = 0;
                    }else{
                    	GlobalDataFactory.templateTree = [];
                    }
                    if(GlobalDataFactory.templateTypes){
                    	GlobalDataFactory.templateTypes.length = 0;
                    }else{
                    	GlobalDataFactory.templateTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Template')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Template')
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=TemplateType')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=TemplateType')
                    }
                    GlobalDataFactory.loadGlobalData();
                    if(selfAux && selfAux.extra && selfAux.extra.table) {
                        selfAux.extra.table.reloadInternalData(false);
                    }
                }).catch(function (error) {
            });
        }

        $scope.printPresetSignCircuits = function (model) {
            var presetSignCircuits = "";

            angular.forEach(model, function (val, key) {
                if(presetSignCircuits != "") {
                    presetSignCircuits += ", ";
                }

                presetSignCircuits += val[$scope.languageColumn];
            })

            return presetSignCircuits;
        }

        $scope.printEditPresetSignCircuit = function (model) {
            if(model) {
                return $filter('translate')('global.literals.editCircuit');
            } else {
                return $filter('translate')('global.literals.editCircuitNo');
            }
        }

        $scope.replaceDocumentModal = function(template, callerModal) {
            var replaceDocument = function() {
                if(this.annexaFormly.form.$valid) {
                    $rootScope.loading(true)
                    var self = this;
                    var data = this.annexaFormly.model.modal_body;

                    var acceptedFileType = true;
                    var fileField = undefined;
                    var modalBodyFields = $linq(modal.annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'modal_body'");
                    if(modalBodyFields) {
                    	fileField = $linq(modalBodyFields.fieldGroup).firstOrDefault(undefined,"x => x.key == 'file'");
                    }

                    if(fileField && fileField.data && fileField.data.accept) {
                    	acceptedFileType = fileField.data.accept.includes(data.file.type);
                    }
                    
                    if(acceptedFileType) {
                    	RestService.updateMultiPartData('./api/doc/template/' + template.id + '/file', undefined, data.file)
                        .then(function (data) {
                        	if(data) {
                                callerModal.data.fileName = data.fileName;
                                callerModal.data.fileTypeDoc = data.fileTypeDoc;
                                callerModal.data.idDocumentManager = data.idDocumentManager;
                                callerModal.data.sizeDoc = data.sizeDoc;
                                if(GlobalDataFactory.templates){
                                	GlobalDataFactory.templates.length = 0;
                                }else{
                                	GlobalDataFactory.templates = [];
                                }
                                if(GlobalDataFactory.templateTree){
                                	GlobalDataFactory.templateTree.length = 0;
                                }else{
                                	GlobalDataFactory.templateTree = [];
                                }
                                if(GlobalDataFactory.templateTypes){
                                	GlobalDataFactory.templateTypes.length = 0;
                                }else{
                                	GlobalDataFactory.templateTypes = [];
                                }
                                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Template')){
                                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Template')
                                }
                                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=TemplateType')){
                                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=TemplateType')
                                }
                                GlobalDataFactory.loadGlobalData();
                                $rootScope.loading(false);
                                self.close();
                            }
                        }).catch(function(error) {
                        	$rootScope.loading(false);
                            console.log(error);
                        });
                    } else {
                    	$rootScope.loading(false);
                        DialogsFactory.error($filter('translate')('global.literals.documentTypeNotAccepted'));
                    }
                    
                 }
            }


            var modal = angular.copy(globalModals.uploadFile);
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.annexaFormly.fields[0].fieldGroup[0].data.accept = '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\'';
            AnnexaFormlyFactory.showModal('modalUploadFile', modal, replaceDocument, false);
        }

        $scope.editOnline = function (idDocumentManager) {
            CommonService.getEditOnlineUrl(idDocumentManager).then(function(eou) {
                if(eou != null && eou != undefined && eou != ''){
                    window.location.href=eou;
                }
            }).catch(function(){
            });
        }

        $scope.download = function(idDocumentManager){
        	$rootScope.loading(true);
            $http.get("./api/repodocs/downloadAlfDoc?id="+idDocumentManager, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    var retDownload = {
                        file: undefined,
                        fileName: undefined,
                        linkElement: undefined,
                        clickEvent: undefined
                    }
                    headers = headers();
                    var contentType = headers['content-type'];
                    retDownload.file = new Blob([data], {type: contentType});
                    retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
                    try {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                        } else {
                            retDownload.linkElement = document.createElement('a');
                            var url = window.URL.createObjectURL(retDownload.file);
                            retDownload.linkElement.setAttribute('href', url);
                            retDownload.linkElement.setAttribute("download", retDownload.fileName);
                            retDownload.clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                        }
                        $rootScope.loading(false);
                    } catch (e) {
                        $rootScope.loading(false);
                    }
                }).error(function (error) {
                    $rootScope.loading(false);
            });
        }
        $scope.printNotHaveDocumentCustomField = function (model) {
            if(model) {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDocumentCustomField');
            } else {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.haveDocumentCustomField');
            }
        }
        
        $scope.printNotHaveDossierTransactionCustomField = function (model) {
            if(model) {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDossierTransactionCustomField');
            } else {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.haveDossierTransactionCustomField');
            }
        }
        
        $scope.printNotHaveDossierCustomField = function (model) {
            if(model) {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDossierCustomField');
            } else {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.haveDossierCustomField');
            }
        }
        $scope.openDocTemplate = function (id) {
            if(id) {
                RestService.findOne('Template', id)
                    .then(function(data) {
                        var modal = angular.copy(CommonAdminModals.docTemplateEdit);
                        modal.data = JSOG.decode(data.data);
                        if(!modal.data.archiveClassification){
                            modal.data.archiveClassification = {};
                        }
                        if(!modal.data.documentType){
                        	modal.data.documentType = {id:""};
                        }
                        _.forEach(modal.data.templateSignCircuit, function (value, key) {
                        	value.template = {id: modal.data.id};
                        });
                        modal.data.conditionedSignCircuitList = modal.data.templateSignCircuit;
                        modal.data.isEdit = true;
                        modal.data.templateId = modal.data.id;
                        modal.languageColumn = Language.getActiveColumn();
                        modal.updateDocTemplate = $scope.updateDocTemplate;
                        modal.templateTypes = AdminFactory.templateTypes;
                        var documentTypes = angular.copy(GlobalDataFactory.documentTypes);
                        documentTypes.unshift( {id:"", language1:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty')} );
                        modal.documentTypes = documentTypes;
                        modal.archiveClassifications = GlobalDataFactory.archiveClassifications;
                        modal.presetSignCircuits = GlobalDataFactory.presetSignCircuit;
                        modal.printPresetSignCircuits = $scope.printPresetSignCircuits;
                        modal.printEditPresetSignCircuit = $scope.printEditPresetSignCircuit;
                        modal.replaceDocumentModal = $scope.replaceDocumentModal;
                        modal.editOnline = $scope.editOnline;
                        modal.download = $scope.download;
                        modal.extra = {table: $scope.tableTemplates};
                        modal.printNotHaveDocumentCustomField = $scope.printNotHaveDocumentCustomField;
                        modal.printNotHaveDossierTransactionCustomField = $scope.printNotHaveDossierTransactionCustomField;
                        modal.printNotHaveDossierCustomField = $scope.printNotHaveDossierCustomField;
                        modal.data.profiles = $linq(modal.data.profiles).select("x => x.profile").toArray();
                        modal.printProfiles = function (model) {
                            var perfiles = '';
                            angular.forEach(model, function (val, key) {
                                if(perfiles != '') {
                                    perfiles += ', ';
                                }
                                perfiles += val[$scope.languageColumn];
                            });

                            return perfiles;
                        }
                        
                        var selectableOptions = [];
                        _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                        	var haveProfile = false;
                        	if(modal.data.profiles) {
                        		haveProfile = modal.data.profiles.some(function (profile) {
                        			  return profile.id === value.id;
                        			});
                        	}
                            if(value.expiryDate == null || haveProfile) {
                                selectableOptions.push(value);
                            }
                        });
                        
                        modal.profiles = $linq(selectableOptions).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        $scope.modelDocTemplate = modal;
                        
                        AnnexaModalFactory.showModal('modaDocTemplateEdit', modal);
                    })
            }
        };

        $scope.deleteDocTemplate = function (id) {
        	DialogsFactory.confirm('global.literals.deleteTemplateMessage', 'global.literals.deleteTemplateMessageConfirm')
            .then(function (data) {
            	RestService.delete('./api/doc/template/' + id).then(function (data) {
            		if(GlobalDataFactory.templates){
                    	GlobalDataFactory.templates.length = 0;
                    }else{
                    	GlobalDataFactory.templates = [];
                    }
                    if(GlobalDataFactory.templateTree){
                    	GlobalDataFactory.templateTree.length = 0;
                    }else{
                    	GlobalDataFactory.templateTree = [];
                    }
                    if(GlobalDataFactory.templateTypes){
                    	GlobalDataFactory.templateTypes.length = 0;
                    }else{
                    	GlobalDataFactory.templateTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Template')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Template')
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=TemplateType')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=TemplateType')
                    }
                    GlobalDataFactory.loadGlobalData();
            		$scope.tableTemplates.reloadInternalData(true);
                }).catch(function (error) {
                    console.log(error);
                });
           }).catch(function (data) {
	               //Empty
	       });
        };

        $scope.newDocTemplate = function () {
            var modal = angular.copy(CommonAdminModals.docTemplateNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.row1 =  { templateType: {} };
            modal.annexaFormly.model.row3 =  { archiveClassification: {} };
            modal.annexaFormly.model.row6 =  { notHaveDossierCustomField_false: false, notHaveDossierTransactionCustomField_false: false, notHaveDocumentCustomField_false: false };
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = $scope.templateTree;
            modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.labelProp = $scope.languageColumn;
            modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = GlobalDataFactory.documentTypes;
            modal.annexaFormly.fields[2].fieldGroup[1].templateOptions.options = $scope.archiveClassificationsTree;
            modal.annexaFormly.fields[2].fieldGroup[1].templateOptions.labelProp = $scope.languageColumn;
            var conditionedSignCircuitListField = $linq(modal.annexaFormly.fields[3].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
            if(conditionedSignCircuitListField) {
            	conditionedSignCircuitListField.data.conditionedSignCircuitList = [];
            }
            modal.annexaFormly.fields[5].fieldGroup[0].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDossierCustomField'), value: false }];
            modal.annexaFormly.fields[5].fieldGroup[1].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDossierTransactionCustomField'), value: false }];
            modal.annexaFormly.fields[5].fieldGroup[2].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.tramTemplate.notHaveDocumentCustomField'), value: false }];
            modal.extra = {table: $scope.tableTemplates};
            AnnexaFormlyFactory.showModal('modalDocTemplateNew', modal, $scope.saveDocTemplate, false);
        }

        $scope.doSearchFilter=function() {
            $scope.tableTemplates.reloadInternalData(true);
        }

        $scope.deleteTemplate = function (id){
            var content = {
                id:id,
                removedDate:new Date()
            };
            CommonAdminService.updateObject(apiAdmin.getTemplates,id,content).then(function (dataUpdate) {
            	if(GlobalDataFactory.templates){
                	GlobalDataFactory.templates.length = 0;
                }else{
                	GlobalDataFactory.templates = [];
                }
                if(GlobalDataFactory.templateTree){
                	GlobalDataFactory.templateTree.length = 0;
                }else{
                	GlobalDataFactory.templateTree = [];
                }
                if(GlobalDataFactory.templateTypes){
                	GlobalDataFactory.templateTypes.length = 0;
                }else{
                	GlobalDataFactory.templateTypes = [];
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Template')){
                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Template')
                }
                if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=TemplateType')){
                	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=TemplateType')
                }
                GlobalDataFactory.loadGlobalData();
                $scope.tableTemplates.reloadInternalData(true);
            }).catch(function () {
            });
        }
    }]);
