angular
    .module('annexaApp')
	.controller('IdentificationReferenceTypeController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory',
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory) {

			$scope.filter = [
				{ id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'},
				{ id: 'code', type: 'text', order: 1, label: 'global.thirds.literals.identificationReferenceType', callAux: true }
			];

			$scope.additionalColumns = [
				{ id: 'code', title: $filter('translate')('global.literals.code')}
			];

			$scope.newElement = function() {
				var modal = angular.copy(CommonAdminModals.identificationReferenceTypeNew);
				modal.annexaFormly.model = {};
				modal.annexaFormly.model.modal_body = {};
				modal.annexaFormly.options = {};
				
				AnnexaFormlyFactory.showModal('modalNewIdentificationReferenceType', modal, $scope.saveIdentificationReferenceType, false);
			}

			$scope.saveIdentificationReferenceType = function (identificationReferenceType) {
				var modal = this;
				
				var model = {
					language1: identificationReferenceType.annexaFormly.model.modal_body.language1 ? identificationReferenceType.annexaFormly.model.modal_body.language1 : 'NotTranslated',
					language2: identificationReferenceType.annexaFormly.model.modal_body.language2 ? identificationReferenceType.annexaFormly.model.modal_body.language2 : 'NotTranslated',
					language3: identificationReferenceType.annexaFormly.model.modal_body.language3 ? identificationReferenceType.annexaFormly.model.modal_body.language3 : 'NotTranslated',
					code: identificationReferenceType.annexaFormly.model.modal_body.code ? identificationReferenceType.annexaFormly.model.modal_body.code : undefined
				}
				
				RestService.insert('./api/thirds/identification_reference_type', model).then(function () {
					$rootScope.$broadcast('annexaAdminBaseReloadTable'); 
					modal.close();
				}).catch(function (error) {
					modal.alerts.push({ msg: $filter('translate')('global.errors.errorIdentificationReferenceType')});
				})
			}

			$scope.viewElement = function (id) {
				RestService.findOne('IdentificationReferenceType', id).then(function(data) {
					var decodeddata = JSOG.decode(data.data);
					
					var modal = angular.copy(CommonAdminModals.identificationReferenceTypeEdit);
					modal.alerts = [];
					modal.data = decodeddata;
					modal.languageColumn = Language.getActiveColumn();
					modal.updateIdentificationReferenceType = $scope.updateIdentificationReferenceType;
					
					AnnexaModalFactory.showModal('modalIdentificationReferenceTypeEdit', modal);
				});
			}

			$scope.updateIdentificationReferenceType = function(val, prop) {
				var self = this;
				var model = angular.copy(this.data);
				
				if (_.contains(['code'], prop)) {
					model[prop] = val;
				} else {
					model = val;
				}
				
				RestService.update('./api/thirds/identification_reference_type/' + model.id, model).then(function (data) {
					$rootScope.$broadcast('annexaAdminBaseReloadTable');
				}).catch(function (error) {
					DialogsFactory.error($filter('translate')('global.errors.errorIdentificationReferenceType'));
				});
			}
	}])