/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('DocumentTypesController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'apiAdmin', 'CustomFieldFactory', 'HelperService', 'CommonService', 'ErrorFactory','AdminFactory', 'DialogsFactory', 'GlobalDataFactory', 'CacheFactory',
        function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService,apiAdmin, CustomFieldFactory, HelperService, CommonService, ErrorFactory, AdminFactory, DialogsFactory, GlobalDataFactory, CacheFactory) {
            $scope.showLoadingdiv = false;
            $scope.languageColumn = Language.getActiveColumn();
            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');
            $scope.modelDocTypes;
            $scope.customFields = [];
            CustomFieldFactory.getCustomFields().then(function (data) {
                if (data){
                    var response = JSOG.decode(data);
                    $scope.customFields.length = 0;
                    $scope.customFields.push({id:"-1", value: '', language1:$filter('translate')('global.literals.new'), language2:$filter('translate')('global.literals.new'), language3:$filter('translate')('global.literals.new')});
                    response.customFields = $linq(response.customFields).orderBy("x => x." + $scope.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    angular.forEach(response.customFields, function(value, key){
                        var aux = HelperService.findFirstElementWhere(response.customFieldsDisable, 'id', value.id, -1);
                        if(aux != -1){
                            value.disableElement = true;
                        }
                        $scope.customFields.push(value);
                    });
                }
            });
            $scope.classificationTree = {};
            $scope.classifications = [];
            CommonAdminService.getArchiveClassifications().then(function (data) {
                if (data){
                    var classifications = JSOG.decode(data);
                    $scope.classifications.length = 0;
                    angular.forEach(classifications, function(value, key) {
                        $scope.classifications.push(value);
                    });
                    $scope.classificationTree = CommonService.getTreeData($scope.classifications, $scope.languageColumn);
                }
            })

            $scope.globalPresetSignCircuits = GlobalDataFactory.presetSignCircuit;

            $scope.dtDefDocumentType = new TableData('doc', 'DocumentType', './api/doc/byfilter').setSort([[1, 'asc']]).setSortName('acronym');

            var NameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
            var DocumentTypesActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see', 'openDocumentType([data])', 'fa-eye'),
                [new ActionButton('global.literals.remove', 'deleteDocumentType([data])')]);
            
            $scope.tableDocumentType = {
                dtColumns: [
                    DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn('acronym').withTitle($filter('translate')('global.literals.acronym')),
                    DTColumnBuilder.newColumn(NameColumn.getColumn()).withTitle(NameColumn.getTitle()).renderWith(NameColumn.getRender),
                    DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(DocumentTypesActionsColumn.getTitle()).renderWith(DocumentTypesActionsColumn.getRender).notSortable()
                ],
                filterCall: $scope.tfilter.getFilterCall(),
                filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
                reloadInternalData: function (resetPaging) {
                    this.filterCall = $scope.tfilter.getFilterCall();
                    this.filterCallAux = $scope.tfilter.getFilterCallAux();
                    this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                    this.filterCallAux.langColumn = $scope.languageColumn;
                    this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                }
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.document_types') {
                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-documentType','fa fa-plus','global.commonAdmin.modal.documentTypes.titleNew',undefined,'openNewDocumentType'),
                        new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-documentType','fa fa-upload','global.literals.import',undefined,'importDocumentType')
                    ];
                }
            });


            HeaderService.changeState($state.current);

            $scope.doSearchFilter=function(queued) {
                $scope.tableDocumentType.reloadInternalData(true);
            };

            $scope.deleteDocumentType = function(id) {
                if(id) {
                    RestService.checkDelete('./api/doc/document_type/check_delete/' + id)
                        .then(function(data) {
                            if(data) {
                                RestService.delete('./api/doc/document_type/' + id)
                                    .then(function(data) {
                                    	if(GlobalDataFactory.documentTypes){
                                        	GlobalDataFactory.documentTypes.length = 0;
                                        	GlobalDataFactory.allDocumentTypes.length = 0;
                                        }else{
                                        	GlobalDataFactory.documentTypes = [];
                                        	GlobalDataFactory.allDocumentTypes = [];
                                        }
                                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=DocumentType')){
                                        	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=DocumentType')
                                        }
                                        GlobalDataFactory.loadGlobalData();
                                        $scope.doSearchFilter();
                                    }).catch(function (error) {
                                        console.log(error);
                                })
                            }
                        }).catch(function(error) {
                            DialogsFactory.error(ErrorFactory.getErrorMessage('documentType', 'checkDelete', error.data), $filter('translate')('DIALOGS_ERROR_MSG'))
                    });

                }
            };

            // formulario de nuevo tipo de documento
            $scope.openNewDocumentType = function () {
                var modal = angular.copy(CommonAdminModals.documentTypesNew);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {column1:{presetSignCircuits:[], documentFileAttachment:'NOT_REQUIRED', documentTemplateSelection:'NOT_REQUIRED', documentPhysicalSelection:'NOT_REQUIRED', style:'blue-A100', archiveClassification:{}, canChangeToThisType_1: true}, column2:{customFields:[]}};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false};
                var archiveClassificationForm = $linq(modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'archiveClassification'");
                if(archiveClassificationForm) {
                	archiveClassificationForm.templateOptions.options = $scope.classificationTree;
                    archiveClassificationForm.templateOptions.labelProp =  $scope.languageColumn;
                }
                var presetSignCircuitsForm = $linq(modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'presetSignCircuits'");
                if(presetSignCircuitsForm) {
                	presetSignCircuitsForm.templateOptions.options = $scope.globalPresetSignCircuits;
                	presetSignCircuitsForm.templateOptions.labelProp = $scope.languageColumn;
                }
                modal.extra = $scope.tableDocumentType;
                AnnexaFormlyFactory.showModal('modalDocumentType', modal, $scope.saveNewDocumentType, false, undefined);
            }
            // formulario que abre un tipo de documento que el usuario a dado a VER

            $scope.openDocumentType = function (id) {
                if(id) {
                    RestService.findOne('DocumentType', id)
                        .then(function(data) {
                            var modal = angular.copy(CommonAdminModals.documentTypesEdit);
                            var customFieldsType = [];
                            var customFieldsList = [];
                            var customsOk = [];
                            modal.alerts = [];
                            modal.data = JSOG.decode(data.data);
							modal.data.documentFileAttachmentId = $scope.printRadio(modal.data.documentFileAttachment);
                            modal.data.documentTemplateSelectionId = $scope.printRadio(modal.data.documentTemplateSelection);
                            modal.data.documentPhysicalSelectionId = $scope.printRadio(modal.data.documentPhysicalSelection);
                            modal.data.styleId = $scope.printColorRadio(modal.data.style);
                            modal.classifications = $scope.classifications;
                            if(!modal.data.archiveClassification){
                                modal.data.archiveClassification = {};
                            }
                            modal.data.customFields = $linq(modal.data.customFields).orderBy("x => x.viewOrder").toArray();
                            var calculateRequiredStringGroups = function(groups){
                        		if(groups && groups.length > 0){
                        			_.forEach(groups, function(group){
                        				group.requiredString = 'OPTIONAL';
                                        if(group.required){
                                        	group.requiredString = 'REQUIRED';
                                        }else if(group.requiredForEndDossier){
                                        	group.requiredString = 'REQUIRED_TO_END';
                                        }else if(group.noEditable) {
                                        	group.requiredString = 'NO_EDITABLE';
                                        }
                                        if(group.groups && group.groups.length > 0){
                                        	calculateRequiredStringGroups(group.groups);
                                        }
                        			});
                        		}
                        	}
                            angular.forEach(modal.data.customFields, function (value, key) {
                                var customField = angular.copy(value);
                                customField.requiredString = 'OPTIONAL';
                                if(value.required){
                                    customField.requiredString = 'REQUIRED';
                                }else if(value.noEditable) {
                                    customField.requiredString = 'NO_EDITABLE';
                                }
                                var aux = HelperService.findFirstElementWhere($scope.customFields, 'id', value.customField.id, -1);
                                if(aux != -1 && aux.disableElement){
                                    customField.customField.disableElement = true;
                                }
                                calculateRequiredStringGroups(customField.groups);
                                customFieldsType.push(customField);
                                customsOk.push(value.customField.id);
                            });
                            var getRelatedDocumentTypes = function(){
                            	var documentTypesRel = [];
                            	_.forEach(modal.data.allowGenerateFromContentDocumentTypes, function(item){
                            		var dts = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+item.documentTyperelatedJSONId);
                            		if(dts){
                            			documentTypesRel.push(dts);
                            		}
                            	});
                            	return documentTypesRel;
                            }
                            modal.data.documentTypesRelated = ((modal.data.allowGenerateFromContentDocumentTypes)?getRelatedDocumentTypes():[]);
                            if(customsOk && customsOk.length > 0) {
                                customFieldsList = $linq($scope.customFields).except(customsOk, "(x, y) => x == y.id ").toArray();
                            }else{
                                customFieldsList = angular.copy($scope.customFields);
                            }

                            modal.languageColumn = Language.getActiveColumn();
                            modal.canChangeTypeColumn = "description";
							modal.canChangeTypes = apiAdmin.yesNoBooleanNull;
            				modal.presetSignCircuits = $scope.globalPresetSignCircuits;
                            modal.printPresetSignCircuits = $scope.printPresetSignCircuits;
                            modal.printEditPresetSignCircuit = $scope.printEditPresetSignCircuit;
                            modal.printRadio = $scope.printRadio;
                            modal.printColorRadio = $scope.printColorRadio;
                            modal.customFieldsType = customFieldsType;
                            modal.printIncludeRegisterEntry = $scope.printIncludeRegisterEntry;
                            modal.printIncludeNotification = $scope.printIncludeNotification;

                            modal.attachForms = [
                                {id:'1', description: $filter("translate")('global.literals.NOT_REQUIRED'), value:"NOT_REQUIRED"},
                                {id:'2', description: $filter("translate")('global.literals.REQUIRED'), value:"REQUIRED"},
                                {id:'3', description: $filter("translate")('global.literals.OPTIONAL'), value:"OPTIONAL"},
                                {id:'4', description: $filter("translate")('global.literals.DOCUMENT_OR_TEMPLATE'), value:"DOCUMENT_OR_TEMPLATE"}
                            ];

                            modal.colorForms = [
                                {id:'1', description: 'blue-A100', value:"blue-A100"},
                                {id:'2', description: 'brown-200', value:"brown-200"},
                                {id:'3', description:'blue-grey-200', value:"blue-grey-200"},
                                {id:'4', description: 'green-A200', value:"green-A200"}
                            ];

                            modal.documentEniTipoDocumental = [
                                {id:'1', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD01'), value: "TD01"},
                                {id:'2', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD02'), value: "TD02"},
                                {id:'3', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD03'), value: "TD03"},
                                {id:'4', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD04'), value: "TD04"},
                                {id:'5', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD05'), value: "TD05"},
                                {id:'6', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD06'), value: "TD06"},
                                {id:'7', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD07'), value: "TD07"},
                                {id:'8', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD08'), value: "TD08"},
                                {id:'9', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD09'), value: "TD09"},
                                {id:'10', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD10'), value: "TD10"},
                                {id:'11', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD11'), value: "TD11"},
                                {id:'12', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD12'), value: "TD12"},
                                {id:'13', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD13'), value: "TD13"},
                                {id:'14', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD14'), value: "TD14"},
                                {id:'15', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD15'), value: "TD15"},
                                {id:'16', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD16'), value: "TD16"},
                                {id:'17', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD17'), value: "TD17"},
                                {id:'18', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD18'), value: "TD18"},
                                {id:'19', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD19'), value: "TD19"},
                                {id:'20', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD20'), value: "TD20"},
                                {id:'51', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD51'), value: "TD51"},
                                {id:'52', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD52'), value: "TD52"},
                                {id:'53', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD53'), value: "TD53"},
                                {id:'54', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD54'), value: "TD54"},
                                {id:'55', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD55'), value: "TD55"},
                                {id:'56', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD56'), value: "TD56"},
                                {id:'57', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD57'), value: "TD57"},
                                {id:'58', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD58'), value: "TD58"},
                                {id:'59', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD59'), value: "TD59"},
                                {id:'60', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD60'), value: "TD60"},
                                {id:'61', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD61'), value: "TD61"},
                                {id:'62', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD62'), value: "TD62"},
                                {id:'63', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD63'), value: "TD63"},
                                {id:'64', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD64'), value: "TD64"},
                                {id:'65', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD65'), value: "TD65"},
                                {id:'66', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD66'), value: "TD66"},
                                {id:'67', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD67'), value: "TD67"},
                                {id:'68', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD68'), value: "TD68"},
                                {id:'69', description: $filter('translate')('global.commonAdmin.modal.eniTipoDocumental.TD69'), value: "TD69"},
                                {id:'99', description: $filter("translate")('global.commonAdmin.modal.eniTipoDocumental.TD99'), value: "TD99"}
                            ];
                            if(!modal.data.tipoDocumental){
                                modal.data.documentEniTipoDocumental = {};
                            }else{
                                var detp = $linq(modal.documentEniTipoDocumental).where("x => x.value == '" + modal.data.tipoDocumental + "'").toArray();
                                if(detp && detp.length > 0) {
                                    modal.data.documentEniTipoDocumental = detp[0];
                                }else{
                                    modal.data.documentEniTipoDocumental = {};
                                }
                            }
                            modal.extra = $scope.tableDocumentType;


                            // iteramos sobre el objeto que nos hemos pasado para pillar el id de profile
                            modal.data.profiles = $linq(modal.data.profiles).select("x => x.profile").toArray();
                            modal.data.profilesTotalAcces = $linq(modal.data.profilesTotalAcces).select("x => x.profile").toArray();

                            // añadimos esto que viene de config.constants.commonAdmin.js la llamada de print-function
                            // con esto haremos que en los perfiles aparezcan los que hay en BD
                            modal.printProfiles = function (model) {
                                var perfiles = '';
                                angular.forEach(model, function (val, key) {
                                    if(perfiles != '') {
                                        perfiles += ', ';
                                    }
                                    perfiles += val[$scope.languageColumn];
                                });

                                return perfiles;
                            }
                            modal.printDocumentTypes = function (model) {
                                var ddocumentTypes = '';
                                angular.forEach(model, function (val, key) {
                                    if(ddocumentTypes != '') {
                                    	ddocumentTypes += ', ';
                                    }
                                    ddocumentTypes += val[$scope.languageColumn];
                                });

                                return ddocumentTypes;
                            }
                            
                            var filterProfiles = function(profiles) {
                            	var selectableOptions = [];
                                _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                                	var haveProfile = false;
                                	if(profiles) {
                                		haveProfile = profiles.some(function (profile) {
                                			  return profile.id === value.id;
                                			});
                                	}
                                    if(value.expiryDate == null || haveProfile) {
                                        selectableOptions.push(value);
                                    }
                                });
                                return $linq(selectableOptions).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                            }

                            // en modal.profiles sera lo que salga cuando editamos los perfiles.
                            modal.profiles = filterProfiles(modal.data.profiles);
                            modal.profilesTotalAcces = filterProfiles(modal.data.profilesTotalAcces);
                            modal.documentTypes = ((GlobalDataFactory.documentTypes)?$linq(GlobalDataFactory.documentTypes).where("x => x.id != "+modal.data.id).toArray():[]);

                            modal.updateDocumentType = $scope.updateDocumentType;
                            modal.tipoDocumentValue = 'description';
							modal.printCanChangeType = function (model) {
				                if(model) {
				                    return $filter('translate')('global.literals.canChangeType');
				                } else {
				                    return $filter('translate')('global.literals.canChangeTypeNo');
				                }
				            };
							modal.printCanChangeToThisType = function (model) {
				                if(model) {
				                    return $filter('translate')('global.literals.canChangeToThisType');
				                } else {
				                    return $filter('translate')('global.literals.canChangeToThisTypeNo');
				                }
				            };
							modal.printValidateContent = function (model) {
				                if(model) {
				                    return $filter('translate')('global.literals.validateDocumentContent');
				                } else {
				                    return $filter('translate')('global.literals.validateDocumentContentNo');
				                }
				            };
                            $scope.modelDocTemplate = modal;
                            AnnexaModalFactory.showModal('modaDocumentTypeEdit', modal);
                        })
                }
            };

            $scope.printPresetSignCircuits = function (model) {
                var presetSignCircuits = "";

                angular.forEach(model, function (val, key) {
                    if(presetSignCircuits != "") {
                        presetSignCircuits += ", ";
                    }

                    presetSignCircuits += val[$scope.languageColumn];
                })

                return presetSignCircuits;
            }

            $scope.printEditPresetSignCircuit = function (model) {
                if(model) {
                    return $filter('translate')('global.literals.editCircuit');
                } else {
                    return $filter('translate')('global.literals.editCircuitNo');
                }
            }
            $scope.printIncludeRegisterEntry = function (model) {
                if(model) {
                    return $filter('translate')('global.literals.includeRegisterEntry');
                } else {
                    return $filter('translate')('global.literals.includeRegisterEntryNo');
                }
            }
            $scope.printIncludeNotification = function (model) {
                if(model) {
                    return $filter('translate')('global.literals.includeNotification');
                } else {
                    return $filter('translate')('global.literals.includeNotificationNo');
                }
            }
            $scope.printColorRadio = function (model, isID) {
                var radio = "";
                if(model == 1){
                    if(isID){radio = 'blue-A100';}else{radio = 'blue-A100';}
                }else if(model == 2){
                    if(isID){radio ='brown-200';}else{radio ='brown-200';}
                }else if(model == 3){
                    if(isID){radio =  'blue-grey-200';}else{radio =  'blue-grey-200';}
                }else if(model == 4) {
                    if(isID){radio ='green-A200';}else{radio ='green-A200';}
                }else if(model == 'blue-A100'){
                    radio = 1;
                }else if(model == 'brown-200'){
                    radio = 2;
                }else if(model == 'blue-grey-200'){
                    radio = 3;
                }else if(model == 'green-A200'){
                    radio = 4;
                }
                return radio;
            };
            $scope.printRadio = function (model, isID) {
                var radio = "";
                if(model == 1){
                    if(isID){radio = 'NOT_REQUIRED';}else{radio = $filter("translate")('global.literals.NOT_REQUIRED');}
                }else if(model == 2){
                    if(isID){radio = 'REQUIRED';}else {radio = $filter("translate")('global.literals.REQUIRED');}
                }else if(model == 3){
                    if(isID){radio = 'OPTIONAL';}else {radio = $filter("translate")('global.literals.OPTIONAL');}
                }else if(model == 4){
                    if(isID){radio = 'DOCUMENT_OR_TEMPLATE';}else{radio = $filter("translate")('global.literals.DOCUMENT_OR_TEMPLATE');}
                }else if(model == 'NOT_REQUIRED'){
                    radio = 1;
                }else if(model == 'REQUIRED'){
                    radio = 2;
                }else if(model == 'OPTIONAL'){
                    radio = 3;
                }else if(model == 'DOCUMENT_OR_TEMPLATE'){
                    radio = 4;
                }
                return radio;
            };

            $scope.updateDocumentType = function(val, prop) {
                var self = $scope.modelDocTemplate;
                var docType = angular.copy($scope.modelDocTemplate.data);

                //miramos los perfiles y los perfiles con acceso total
                if(prop != 'profiles'){
                	var profiles = [];
                	_.forEach(docType.profiles, function(item) {
                        profiles.push( { profile: { id: item.id } });
                    });
                	docType.profiles = profiles;
                }
                if(prop != 'profilesTotalAcces'){
                	var profilesTotalAcces = [];
                	_.forEach(docType.profilesTotalAcces, function(item) {
                        profilesTotalAcces.push( { profile: { id: item.id } });
                    });
                	docType.profilesTotalAcces = profilesTotalAcces;
                }
                if(prop != 'allowGenerateFromContentDocumentTypes'){
                	var allowGenerateFromContentDocumentTypes = [];
                	_.forEach(docType.documentTypesRelated, function(item) {
                		allowGenerateFromContentDocumentTypes.push( { documentTypeRelated: { id: item.id } });
                    });
                	docType.allowGenerateFromContentDocumentTypes = allowGenerateFromContentDocumentTypes;
                }
                if(prop != 'language1' && prop != 'language2' && prop != 'language3' && prop != 'archiveClassification' && prop != 'documentEniTipoDocumental'  && prop != 'profiles' && prop != 'profilesTotalAcces'  && prop != 'allowGenerateFromContentDocumentTypes'){
                    docType[prop] = val;
                }else if(prop == 'archiveClassification'){
                    if(val.$selected && val.$selected.id){
                        docType[prop] = {id:val.$selected.id};
                    }else{
                        docType[prop] = {};
                    }
                }else if(prop == 'documentEniTipoDocumental'){
                    if(val.value){
                        docType['tipoDocumental'] = val.value;
                    }else{
                        docType['tipoDocumental'] = '';
                    }
                } else if (prop == 'profiles' || prop == 'profilesTotalAcces') {
                    docType[prop] = [];
                    _.forEach(val, function(item) {
                        docType[prop].push( { profile: { id: item.id } });
                    });
                }else if(prop == 'allowGenerateFromContentDocumentTypes'){
                	docType[prop] = [];
                    _.forEach(val, function(item) {
                        docType[prop].push( { documentTypeRelated: { id: item.id } });
                    });
                }
                
                
                if(docType.archiveClassification && docType.archiveClassification.model){
                    if(docType.archiveClassification.model.$selected && docType.archiveClassification.model.$selected.id){
                        docType.archiveClassification = {id:docType.archiveClassification.model.$selected.id};
                    }else{
                        docType.archiveClassification = undefined;
                    }
                }
                docType.documentFileAttachment = $scope.printRadio(docType.documentFileAttachmentId,true);
                docType.documentTemplateSelection = $scope.printRadio( docType.documentTemplateSelectionId,true);
                docType.documentPhysicalSelection = $scope.printRadio( docType.documentPhysicalSelectionId,true);
                docType.style = $scope.printColorRadio(docType.styleId,true);
                $scope.modelDocTemplate.data.documentFileAttachment = $scope.printRadio(docType.documentFileAttachmentId,true);
                $scope.modelDocTemplate.data.documentTemplateSelection = $scope.printRadio( docType.documentTemplateSelectionId,true);
                $scope.modelDocTemplate.data.documentPhysicalSelection = $scope.printRadio( docType.documentPhysicalSelectionId,true);
                $scope.modelDocTemplate.data.style = $scope.printColorRadio(docType.styleId,true);
                docType.documentFileAttachmentId = undefined;
                docType.documentTemplateSelectionId = undefined;
                docType.documentPhysicalSelectionId = undefined;
                docType.styleId = undefined;
                CommonAdminService.updateDocumentType(docType).then(function (data) {
                    self.extra.reloadInternalData(false);
                    if(GlobalDataFactory.documentTypes){
                    	GlobalDataFactory.documentTypes.length = 0;
                    	GlobalDataFactory.allDocumentTypes.length = 0;
                    }else{
                    	GlobalDataFactory.documentTypes = [];
                    	GlobalDataFactory.allDocumentTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=DocumentType')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=DocumentType')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
                	self.alerts.push({ msg: ErrorFactory.getErrorMessage('documentType', 'create', error.data) });
                });
            }

            // Cuando rellenamos el formulario y guardamos
            $scope.saveNewDocumentType = function(){
                if (this.annexaFormly.form.$valid) {
                    var self = this;
                    var model = this.annexaFormly.model.modal_body.column1;

                    // Añadimos los perfiles al modal cuando creamos un formulario
                    var profiles = [];
                    if(model.profiles){
	                    _.forEach(model.profiles, function(item) {
	                        profiles.push({ profile: { id: item } });
	                    });
                	}
                    var profilesTotalAcces = [];
                	if(model.profilesTotalAcces){
	                    _.forEach(model.profilesTotalAcces, function(item) {
	                    	profilesTotalAcces.push({ profile: { id: item } });
	                    });
            		}
                	 // Añadimos los perfiles al modal cuando creamos un formulario
                    var allowGenerateFromContentDocumentTypes = [];
                    if(model.documentTypesRelated){
	                    _.forEach(model.documentTypesRelated, function(item) {
	                    	allowGenerateFromContentDocumentTypes.push({ documentTypeRelated: { id: item } });
	                    });
                	}
                    if(!model.includeRegisterEntry_1) {
                    	model.includeRegisterEntry_1 = false;
                    }
                    if(!model.includeNotification_1) {
                    	model.includeNotification_1 = false;
                    }
					if(!model.canChangeType_1) {
                    	model.canChangeType_1 = false;
                    }
					if(!model.canChangeToThisType_1) {
                    	model.canChangeToThisType_1 = false;
                    }
					if(!model.validateDocumentContent_1) {
                    	model.validateDocumentContent_1 = false;
                    }

                    var documentType = {
                        acronym: model.acronym,
                        documentFileAttachment: model.documentFileAttachment,
                        documentTemplateSelection: model.documentTemplateSelection,
						documentPhysicalSelection: model.documentPhysicalSelection,
                        editPresetSignCircuit: model.editPresetSignCircuit_1,
                        language1: model.language1,
                        language2: model.language2,
                        language3: model.language3,
                        style: model.style,
                        presetSignCircuits: [],
                        customFields: [],
                        profiles: profiles,
                        profilesTotalAcces: profilesTotalAcces   ,
                        allowGenerateFromContentDocumentTypes: allowGenerateFromContentDocumentTypes,
                        includeRegisterEntry: model.includeRegisterEntry_1,
                        includeNotification: model.includeNotification_1,
						canChangeType: model.canChangeType_1,
						canChangeToThisType: model.canChangeToThisType_1,
						validateContent: model.validateDocumentContent_1
                    };

                    if(model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id){
                        documentType.archiveClassification = { id: model.archiveClassification.$selected.id };
                    }

                    documentType.tipoDocumental = model.documentEniTipoDocumental;


                    angular.forEach(model.presetSignCircuits,function(value, key) {
                        documentType.presetSignCircuits.push({id:value});
                    });

                    angular.forEach(this.annexaFormly.fields[0].fieldGroup[1].fieldGroup[0].data.customFields, function (value, key) {
                        documentType.customFields.push(value);
                    });
                    RestService.insert('./api/doc/document_type', documentType)
                        .then(function(data) {
                            self.extra.reloadInternalData(true);
                            if(GlobalDataFactory.documentTypes){
                            	GlobalDataFactory.documentTypes.length = 0;
                            	GlobalDataFactory.allDocumentTypes.length = 0;
                            }else{
                            	GlobalDataFactory.documentTypes = [];
                            	GlobalDataFactory.allDocumentTypes = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=DocumentType')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=DocumentType')
                            }
                            GlobalDataFactory.loadGlobalData();
                            self.close();
                        }).catch(function(error) {
                            console.log(error);
                            self.alerts.push({ msg: ErrorFactory.getErrorMessage('documentType', 'create', error.data) });
                    });
                }
            }

            $scope.updateCustomField = function(customField){
                RestService.update('./api/doc/document_type/custom_field/' + customField.id, customField)
                    .then(function (data) {
                        //Empty
                    }).catch(function(error) {
                        console.log(error);
                });
            }

            $scope.createModalCustomField = function(documentTypeCustomField, customFields){
                var editable = documentTypeCustomField.customField.disableElement;

                var model = {
                    documentType: documentTypeCustomField.documentType,
                    customField: documentTypeCustomField.customField,
                    noEditable: documentTypeCustomField.noEditable,
                    required: documentTypeCustomField.required,
                    viewOrder: documentTypeCustomField.viewOrder,
                    value: documentTypeCustomField.value,
                    descriptionLanguage1: documentTypeCustomField.descriptionLanguage1,
					descriptionLanguage2: documentTypeCustomField.descriptionLanguage2,
					descriptionLanguage3: documentTypeCustomField.descriptionLanguage3,
					nameLanguage1: documentTypeCustomField.nameLanguage1,
                    nameLanguage2: documentTypeCustomField.nameLanguage2,
                    nameLanguage3: documentTypeCustomField.nameLanguage3
                };

                RestService.insert('./api/doc/document_type/custom_field', model)
                    .then(function(data) {
                        documentTypeCustomField = JSOG.decode(data);
                        documentTypeCustomField.requiredString = "OPTIONAL";
                        documentTypeCustomField.customField.disableElement = editable;
                        if(customFields) {
                            customFields.push(documentTypeCustomField);
                        }
                    }).catch(function(error) {
                        console.log(error);
                });
            };

            $scope.removeCustomFieldsFuntion = function(customField, customFields){
                RestService.delete('./api/doc/document_type/custom_field/' + customField.id)
                    .then(function(data) {
                        //Empty
                    }).catch(function(error) {
                        console.log(error);
                });
            };
            
            $scope.importDocumentType = function () {  
            	AdminFactory.importDataAdmin($scope, "DOCUMENT_TYPE", $scope.tableDocumentType);
            }
            
        }]);