angular
    .module('annexaApp')
    .controller('AddressTypeController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin',
    		function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin) {
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.addressTypeNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewAddressType', modal, $scope.saveAddressType, false);
        }

        $scope.saveAddressType = function (addressType) {
            var modal = this;

            var model = {
                language1: addressType.annexaFormly.model.modal_body.language1 ? addressType.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                language2: addressType.annexaFormly.model.modal_body.language2 ? addressType.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: addressType.annexaFormly.model.modal_body.language3 ? addressType.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                code: addressType.annexaFormly.model.modal_body.code,
                postalGroup: addressType.annexaFormly.model.modal_body.postalGroup,
                cadastralReferenceGroup: addressType.annexaFormly.model.modal_body.cadastralReferenceGroup,
                coordinatesGroup: addressType.annexaFormly.model.modal_body.coordinatesGroup,
                validateNumbers: ((addressType.annexaFormly.model.modal_body.validateNumbers_true)?true:false),
                permissions:[]
            }
            var permissionsField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'permissions'");
            if(permissionsField && permissionsField.fieldGroup && permissionsField.fieldGroup.length > 0 && permissionsField.fieldGroup[0].data && permissionsField.fieldGroup[0].data.permissions){
	            model.permissions = permissionsField.fieldGroup[0].data.permissions;
            }

            RestService.insert('./api/territory/address_type', model).then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    if(GlobalDataFactory.addressTypes){
                    	GlobalDataFactory.addressTypes.length = 0;
                    }else{
                    	GlobalDataFactory.addressTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('AddressType')){
                    	CacheFactory.get('globalDataCache').remove('AddressType');
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.close();
                }).catch(function () {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('AddressType', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = CommonAdminModals.addressTypeEdit;
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.printValidateNumbers = function(modelValue){
                    	if(modelValue == true || (modelValue && modelValue.id == true) ) {
                            return $filter('translate')('global.commonAdmin.modal.addressType.validateNumbers');
                        } else {
                            return $filter('translate')('global.commonAdmin.modal.addressType.noValidateNumbers');
                        }
                    }
                    
                    modal.printGroups = function(modelValue){
                    	if(modelValue && !modelValue.id && _.contains(['REQUIRED', 'OPTIONAL', 'NOT_REQUIRED'], modelValue)) {
                    		return $filter('translate')('global.literals.'+((modelValue == 'NOT_REQUIRED')?'DISABLED':modelValue));
                    	}else if(modelValue && modelValue.id && _.contains(['REQUIRED', 'OPTIONAL', 'NOT_REQUIRED'], modelValue.id)){
                    		return $filter('translate')('global.literals.'+((modelValue.id == 'NOT_REQUIRED')?'DISABLED':modelValue.id));
                    	}else{
                    		return '';
                    	}
                    }
                    modal.showAddressTypeList = angular.copy(apiAdmin.showAddressTypeList); 
                    modal.descriptionColumn = "description";
                    modal.updateAddressType = $scope.updateAddressType;

                    AnnexaModalFactory.showModal('modalTaskOriginEdit', modal);
                });
        }

        $scope.updateAddressType = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);

            if ( _.contains(['code', 'validateNumbers'], prop)) {
                model[prop] = val;
            } else if( _.contains(['postalGroup', 'cadastralReferenceGroup', 'coordinatesGroup'], prop)){
            	model[prop] = ((val && val.id)?val.id:undefined);
            } else {
                model = val;
            }
            
            RestService.update('./api/territory/address_type/' + model.id, model)
                .then(function (data) {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if( _.contains(['postalGroup', 'cadastralReferenceGroup', 'coordinatesGroup'], prop)){
                    	self.data[prop] = ((val && val.id)?val.id:undefined);
                    }
                    if(GlobalDataFactory.addressTypes){
                    	GlobalDataFactory.addressTypes.length = 0;
                    }else{
                    	GlobalDataFactory.addressTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('AddressType')){
                    	CacheFactory.get('globalDataCache').remove('AddressType');
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function () {
                    //Empty
            });
        }
        
    }])
    .controller('CountryController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory',
    		function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory) {
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.countryNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewCountry', modal, $scope.saveCountry, false);
        }

        $scope.filter = [
        	{id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'},
        	{ id: 'codeISO2', type: 'text', order: 1, label: 'global.literals.codeISO2' },
            { id: 'codeISO3', type: 'text', order: 2, label: 'global.literals.codeISO3' },
            { id: 'dir3', type: 'text', order: 3, label: 'global.literals.dir3' }
        ]

        $scope.additionalColumns = [
        	{ id: 'codeISO2', title: $filter('translate')('global.literals.codeISO2')},
        	{ id: 'codeISO3', title: $filter('translate')('global.literals.codeISO3')},
        	{ id: 'dir3', title: $filter('translate')('global.literals.dir3')}
        ]

        
        $scope.saveCountry = function (country) {
            var modal = this;

            var model = {
                language1: country.annexaFormly.model.modal_body.language1 ? country.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                language2: country.annexaFormly.model.modal_body.language2 ? country.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: country.annexaFormly.model.modal_body.language3 ? country.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                codeISO2: country.annexaFormly.model.modal_body.codeISO2 ? country.annexaFormly.model.modal_body.codeISO2 : undefined,
                codeISO3: country.annexaFormly.model.modal_body.codeISO3 ? country.annexaFormly.model.modal_body.codeISO3 : undefined,
                dir3: country.annexaFormly.model.modal_body.dir3 ? country.annexaFormly.model.modal_body.dir3 : undefined
            }

            RestService.insert('./api/territory/street_country', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    modal.close();
                }).catch(function (error) {
            		if(error && error.data && error.data.message && error.data.message.indexOf('codeISO2'.toLowerCase()) > 0 && error.data.message.indexOf('org.hibernate.exception.ConstraintViolationException') > 0){
            			modal.alerts.push({ msg: $filter('translate')('global.errors.errorCodeIso2')});
            		}else if(error && error.data && error.data.message && error.data.message.indexOf('codeISO3'.toLowerCase()) > 0 && error.data.message.indexOf('org.hibernate.exception.ConstraintViolationException') > 0){
                     	modal.alerts.push({ msg: $filter('translate')('global.errors.errorCodeIso3')});
            		}else if(error && error.data && error.data.message && error.data.message.indexOf('dir3'.toLowerCase()) > 0 && error.data.message.indexOf('org.hibernate.exception.ConstraintViolationException') > 0){
            				modal.alerts.push({ msg: $filter('translate')('global.errors.errorDir3')});
            		}else{
            			modal.alerts.push({ msg: $filter('translate')('global.errors.errorCountry')});
            		}
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('StreetCountry', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = angular.copy(CommonAdminModals.countryEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateCountry = $scope.updateCountry;

                    AnnexaModalFactory.showModal('modalCountryEdit', modal);
                });
        }

        $scope.updateCountry = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);
            if ( _.contains(['codeISO2', 'codeISO3', 'dir3'], prop)) {
                model[prop] = val;
            } else {
                model = val;
            }
            
            RestService.update('./api/territory/street_country/' + model.id, model).then(function (data) {
            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
            }).catch(function (error) {
            	if ( _.contains(['codeISO2', 'codeISO3', 'dir3'], prop)) {
            		if(error && error.data && error.data.message && error.data.message.indexOf(prop.toLowerCase()) > 0 && error.data.message.indexOf('org.hibernate.exception.ConstraintViolationException') > 0){
            			if('codeISO2' == prop){
            				DialogsFactory.error($filter('translate')('global.errors.errorCodeIso2'));
            			}else if('codeISO3' == prop){
            				DialogsFactory.error($filter('translate')('global.errors.errorCodeIso3'));
            			}else if('dir3' == prop){
            				DialogsFactory.error($filter('translate')('global.errors.errorDir3'));
            			}else{
            				DialogsFactory.error($filter('translate')('global.errors.errorCountry'));
            			}
            		}else{
            			DialogsFactory.error($filter('translate')('global.errors.errorCountry'));
            		}
            	}else{
            		DialogsFactory.error($filter('translate')('global.errors.errorCountry'));
            	}
            });
        }
        
    }])
    .controller('StreetTypeController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory',
    		function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory) {
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.streetTypeNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewStreetType', modal, $scope.saveStreetType, false);
        }

        $scope.filter = [
        	{id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'},
        	{ id: 'code', type: 'text', order: 1, label: 'global.literals.code' }
        ]

        $scope.additionalColumns = [
        	{ id: 'code', title: $filter('translate')('global.literals.code')},
        ]
        
        $scope.saveStreetType = function (streetType) {
            var modal = this;

            var model = {
                language1: streetType.annexaFormly.model.modal_body.language1 ? streetType.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                language2: streetType.annexaFormly.model.modal_body.language2 ? streetType.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: streetType.annexaFormly.model.modal_body.language3 ? streetType.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                code: streetType.annexaFormly.model.modal_body.code ? streetType.annexaFormly.model.modal_body.code : undefined
            }

            RestService.insert('./api/territory/street_street_type', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    modal.close();
                }).catch(function (error) {
            		if(error && error.data && error.data.message && error.data.message.indexOf('code'.toLowerCase()) > 0 && error.data.message.indexOf('org.hibernate.exception.ConstraintViolationException') > 0){
            			modal.alerts.push({ msg: $filter('translate')('global.errors.errorCodeStreetType')});
            		}else{
            			modal.alerts.push({ msg: $filter('translate')('global.errors.errorStreetType')});
            		}
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('StreetStreetType', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = angular.copy(CommonAdminModals.streetTypeEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateStreetType = $scope.updateStreetType;

                    AnnexaModalFactory.showModal('modalStreetTypeEdit', modal);
                });
        }

        $scope.updateStreetType = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);
            if ( _.contains(['code'], prop)) {
                model[prop] = val;
            } else {
                model = val;
            }
            
            RestService.update('./api/territory/street_street_type/' + model.id, model).then(function (data) {
            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
            }).catch(function (error) {
            	if ( _.contains(['code'], prop)) {
            		if(error && error.data && error.data.message && error.data.message.indexOf(prop.toLowerCase()) > 0 && error.data.message.indexOf('org.hibernate.exception.ConstraintViolationException') > 0){
            			if('code' == prop){
            				DialogsFactory.error($filter('translate')('global.errors.errorCodeStreetType'));
            			}else{
            				DialogsFactory.error($filter('translate')('global.errors.errorStreetType'));
            			}
            		}else{
            			DialogsFactory.error($filter('translate')('global.errors.errorStreetType'));
            		}
            	}else{
            		DialogsFactory.error($filter('translate')('global.errors.errorStreetType'));
            	}
            });
        }
        
    }])
    .controller('PseudoViaController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory',
    		function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory) {
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.pseudoViaNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewPseudoVia', modal, $scope.savePseudoVia, false);
        }

        $scope.savePseudoVia = function (pseudoVia) {
            var modal = this;

            var model = {
                language1: pseudoVia.annexaFormly.model.modal_body.language1 ? pseudoVia.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                language2: pseudoVia.annexaFormly.model.modal_body.language2 ? pseudoVia.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: pseudoVia.annexaFormly.model.modal_body.language3 ? pseudoVia.annexaFormly.model.modal_body.language3 : 'NotTranslated'
            }

            RestService.insert('./api/territory/pseudo_via', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    modal.close();
                }).catch(function (error) {
            		modal.alerts.push({ msg: $filter('translate')('global.errors.errorPseudoVia')});
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('PseudoVia', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = angular.copy(CommonAdminModals.pseudoViaEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updatePseudoVia = $scope.updatePseudoVia;

                    AnnexaModalFactory.showModal('modalPseudoViaEdit', modal);
                });
        }

        $scope.updatePseudoVia = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);
            model = val;
            
            RestService.update('./api/territory/pseudo_via/' + model.id, model).then(function (data) {
            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
            }).catch(function (error) {
           		DialogsFactory.error($filter('translate')('global.errors.errorPseudoVia'));
            });
        }
        
    }])
    .controller('StateController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory', 'TerritoryFactory',
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory, TerritoryFactory) {
    	$scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.stateNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            var defaultCountry = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_country && $rootScope.app.configuration.default_country.value)?$rootScope.app.configuration.default_country.value: '');
            if(defaultCountry){
                modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
            }
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewState', modal, $scope.saveState, false);
        }
    	
    	$scope.filter = [
        	{id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'},
        	{ id: 'country', type: 'text', order: 1, label: 'global.literals.country', callAux: true },
            { id: 'code', type: 'text', order: 2, label: 'global.literals.code' },
        ]

        $scope.additionalColumns = [
        	{ id: 'country.'+Language.getActiveColumn(), title: $filter('translate')('global.literals.country')},
        	{ id: 'code', title: $filter('translate')('global.literals.code')},
        ]
    	
        $scope.saveState = function (state) {
            var modal = this;

            var model = {
                language1: state.annexaFormly.model.modal_body.language1 ? state.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                language2: state.annexaFormly.model.modal_body.language2 ? state.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: state.annexaFormly.model.modal_body.language3 ? state.annexaFormly.model.modal_body.language3 : 'NotTranslated',
           		code: state.annexaFormly.model.modal_body.code ? state.annexaFormly.model.modal_body.code : undefined,
           		country: ((state.annexaFormly.model.modal_body.country && state.annexaFormly.model.modal_body.country.id) ? {id:state.annexaFormly.model.modal_body.country.id} : undefined)
            }

            RestService.insert('./api/territory/street_state', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    modal.close();
                }).catch(function (error) {
                	if(error && error.data && error.data.message && error.data.message.indexOf('code'.toLowerCase()) > 0 && error.data.message.indexOf('org.hibernate.exception.ConstraintViolationException') > 0){
            			modal.alerts.push({ msg: $filter('translate')('global.errors.errorCodeState')});
            		}else{
            			modal.alerts.push({ msg: $filter('translate')('global.errors.errorState')});
            		}
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('StreetState', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = angular.copy(CommonAdminModals.stateEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.countries = angular.copy(TerritoryFactory.countries);
                    modal.printcountries = function(modelValue){
                    	if(modelValue && modelValue[modal.languageColumn]) {
                    		return modelValue[modal.languageColumn];
                    	}else{
                    		return '';
                    	}
                    }
                    modal.updateState = $scope.updateState;

                    AnnexaModalFactory.showModal('modalStateEdit', modal);
                });
        }

        $scope.updateState = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);
            if ( _.contains(['code'], prop)) {
                model[prop] = val;
            } else if( _.contains(['country'], prop)){
            	model[prop] = ((val && val.id)?{id:val.id}:undefined); 
            } else {
                model = val;
            }
            
            RestService.update('./api/territory/street_state/' + model.id, model).then(function (data) {
            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
            }).catch(function (error) {
            	if ( _.contains(['code'], prop)) {
            		if(error && error.data && error.data.message && error.data.message.indexOf(prop.toLowerCase()) > 0 && error.data.message.indexOf('org.hibernate.exception.ConstraintViolationException') > 0){
            			if('code' == prop){
            				DialogsFactory.error($filter('translate')('global.errors.errorCodeState'));
            			}else{
            				DialogsFactory.error($filter('translate')('global.errors.errorState'));
            			}
            		}else{
            			DialogsFactory.error($filter('translate')('global.errors.errorState'));
            		}
            	}else{
            		DialogsFactory.error($filter('translate')('global.errors.errorState'));
            	}
            });
        }
        
    }])
    .controller('CityController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory', 'TerritoryFactory',
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory, TerritoryFactory) {
    	$scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.cityNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            var defaultCountry = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_country && $rootScope.app.configuration.default_country.value)?$rootScope.app.configuration.default_country.value: '');
            if(defaultCountry){
                modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
            }
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewCity', modal, $scope.saveCity, false);
        }

    	$scope.filter = [
            { id: 'name', type: 'text', order: 0, label: 'global.literals.name' },
            { id: 'country', type: 'text', order: 2, label: 'global.literals.country', callAux: true},
            { id: 'state', type: 'text', order: 3, label: 'global.literals.state', callAux: true },
            { id: 'code', type: 'text', order: 4, label: 'global.literals.code' },
            { id: 'dir3', type: 'text', order: 5, label: 'global.literals.dir3' }
        ]

        $scope.additionalColumns = [
        	{ id: 'name', title: $filter('translate')('global.literals.name')},
        	{ id: 'state.country.'+Language.getActiveColumn(), title: $filter('translate')('global.literals.country')},
        	{ id: 'state.'+Language.getActiveColumn(), title: $filter('translate')('global.literals.state')},
        	{ id: 'code', title: $filter('translate')('global.literals.code')},
        	{ id: 'dir3', title: $filter('translate')('global.literals.dir3')}
        ]
    	
        $scope.saveCity = function (city) {
            var modal = this;

            var model = {
                name: city.annexaFormly.model.modal_body.name ? city.annexaFormly.model.modal_body.name : '',
                state: ((city.annexaFormly.model.modal_body.state && city.annexaFormly.model.modal_body.state.id)? {id:city.annexaFormly.model.modal_body.state.id} : undefined),
           		code: city.annexaFormly.model.modal_body.code ? city.annexaFormly.model.modal_body.code : undefined,
           		dir3: city.annexaFormly.model.modal_body.dir3 ? city.annexaFormly.model.modal_body.dir3 : undefined,
           		pseudoVies: [],
				territorialGroups:[]
            }
            var pseudoViesField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'pseudoVies'");
            if(pseudoViesField && pseudoViesField.fieldGroup && pseudoViesField.fieldGroup.length > 0 && pseudoViesField.fieldGroup[0].data && pseudoViesField.fieldGroup[0].data.pseudoVies){
	            model.pseudoVies = pseudoViesField.fieldGroup[0].data.pseudoVies;
            }
			var territorialGroupsField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'territorialGroups'");
            if(territorialGroupsField && territorialGroupsField.fieldGroup && territorialGroupsField.fieldGroup.length > 0 && territorialGroupsField.fieldGroup[0].data && territorialGroupsField.fieldGroup[0].data.territorialGroups){
	            model.territorialGroups = [];
				_.forEach(territorialGroupsField.fieldGroup[0].data.territorialGroups, function(tg){
					if(tg.value && tg.value.id){
						model.territorialGroups.push({territorialGroup: {id:tg.value.id}});
					}
				});
				
            }

            RestService.insert('./api/territory/street_city', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    modal.close();
                }).catch(function (error) {
        			modal.alerts.push({ msg: $filter('translate')('global.errors.errorCity')});
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('StreetCity', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = angular.copy(CommonAdminModals.cityEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.data.country = ((modal.data.state && modal.data.state.country)?modal.data.state.country:undefined);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.countries = angular.copy(TerritoryFactory.countries);
                    modal.states = angular.copy(TerritoryFactory.states);
                    modal.pseudoVies = angular.copy(TerritoryFactory.pseudoVies);
                    modal.applicableTo = 'TOWNS';
					modal.openModalUpdateParents = function() {
                        var modalUpdate = angular.copy(CommonAdminModals.updateCityState);
                        modalUpdate.annexaFormly.model = {};
                        modalUpdate.annexaFormly.model.modal_body = modal.data;
                        modalUpdate.annexaFormly.options = {};
                        var saveModalUpdateParents = function(){
                        	var modalUpdateAux = this;
                            var model = angular.copy(modalUpdateAux.annexaFormly.model.modal_body);
                            model.state = ((model.state && model.state.id)?{id:model.state.id}:undefined);
                            model.country = ((model.country && model.country.id)?{id:model.country.id}:undefined);
                        	RestService.update('./api/territory/street_city/' + model.id, model).then(function (data) {
                        		modalUpdateAux.close();
                        		modal.close();
            	            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
            	            	$scope.viewElement(model.id);
            	            }).catch(function (error) {
            	        		DialogsFactory.error($filter('translate')('global.errors.errorStreet'));
            	            });
                        }
                        AnnexaFormlyFactory.showModal('modalEditCityState', modalUpdate, saveModalUpdateParents, false);
                    }
                    
                    modal.printcountriesAndStates = function(modelValue){
                    	if(modelValue && modelValue[modal.languageColumn]) {
                    		return modelValue[modal.languageColumn];
                    	}else{
                    		return '';
                    	}
                    }
                    modal.updateCity = $scope.updateCity;

                    AnnexaModalFactory.showModal('modalCityEdit', modal);
                });
        }

        $scope.updateCity = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);
            if ( _.contains(['code','name', 'dir3'], prop)) {
                model[prop] = val;
	            
	            RestService.update('./api/territory/street_city/' + model.id, model).then(function (data) {
	            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
	            }).catch(function (error) {
	        		DialogsFactory.error($filter('translate')('global.errors.errorCity'));
	            });
            }
        }
        
    }])
    .controller('StreetController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory', 'TerritoryFactory',
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory, TerritoryFactory) {
    	$scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.streetNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            var defaultCountry = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_country && $rootScope.app.configuration.default_country.value)?$rootScope.app.configuration.default_country.value: '');
            if(defaultCountry){
                modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
            }
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewStreet', modal, $scope.saveStreet, false);
        }

    	$scope.filter = [
            { id: 'name', type: 'text', order: 0, label: 'global.literals.name' },
            { id: 'country', type: 'text', order: 1, label: 'global.literals.country', callAux: true},
            { id: 'state', type: 'text', order: 2, label: 'global.literals.state', callAux: true },
            { id: 'city', type: 'text', order: 3, label: 'global.literals.city', callAux: true },
            { id: 'streetType', type: 'text', order: 4, label: 'global.thirds.literals.streetType', callAux: true },
            { id: 'code', type: 'text', order: 5, label: 'global.literals.code' },
        ]

        $scope.additionalColumns = [
        	{ id: 'name', title: $filter('translate')('global.literals.name')},
        	{ id: 'particles', title: $filter('translate')('global.literals.particles')},
        	{ id: 'city.state.country.'+Language.getActiveColumn(), title: $filter('translate')('global.literals.country')},
        	{ id: 'city.state.'+Language.getActiveColumn(), title: $filter('translate')('global.literals.state')},
        	{ id: 'city.name', title: $filter('translate')('global.literals.city')},
        	{ id: 'streetType.'+Language.getActiveColumn(), title: $filter('translate')('global.thirds.literals.streetType')},
        	{ id: 'code', title: $filter('translate')('global.literals.code')}
        ]

        $scope.saveStreet = function (street) {
            var modal = this;

            var model = {
                name: street.annexaFormly.model.modal_body.name ? street.annexaFormly.model.modal_body.name : '',
                particles: street.annexaFormly.model.modal_body.particles ? street.annexaFormly.model.modal_body.particles : '',
          		code: street.annexaFormly.model.modal_body.code ? street.annexaFormly.model.modal_body.code : undefined,
  				city: ((street.annexaFormly.model.modal_body.city && street.annexaFormly.model.modal_body.city.id) ? {id:street.annexaFormly.model.modal_body.city.id} : undefined),
           		streetType: ((street.annexaFormly.model.modal_body.streetType && street.annexaFormly.model.modal_body.streetType.id) ? {id:street.annexaFormly.model.modal_body.streetType.id} : undefined),
           		numbers: []
            }		
	        var numbersField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'numbers'");
	        if(numbersField && numbersField.fieldGroup && numbersField.fieldGroup.length > 0 && numbersField.fieldGroup[0].data && numbersField.fieldGroup[0].data.numbers){
	            model.numbers = numbersField.fieldGroup[0].data.numbers;
	        }


            RestService.insert('./api/territory/street_street', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    modal.close();
                }).catch(function (error) {
                	if(error && error.data && error.data.message == "Exist number"){
                		modal.alerts.push({ msg: $filter('translate')('global.errors.errorStreetNumber')});
                	}else{
                		modal.alerts.push({ msg: $filter('translate')('global.errors.errorStreet')});
                	}
            })
        }

        $scope.viewElement = function (id) {
        	TerritoryFactory.getStreet(id)
                .then(function(decodeddata) {
                    var modal = angular.copy(CommonAdminModals.streetEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.data.state = ((modal.data.city && modal.data.city.state)?modal.data.city.state:undefined);
                    modal.data.country = ((modal.data.city && modal.data.city.state && modal.data.city.state.country)?modal.data.city.state.country:undefined);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.nameColumn = "name";
                    modal.countries = angular.copy(TerritoryFactory.countries);
                    modal.states = angular.copy(TerritoryFactory.states);
                    modal.streetTypes = angular.copy(TerritoryFactory.streetTypes);
                    modal.printCities = function(modelValue){
                    	if(modelValue && modelValue.name) {
                    		return modelValue.name;
                    	}else{
                    		return '';
                    	}
                    }
                    modal.openModalUpdateParents = function() {
                        var modalUpdate = angular.copy(CommonAdminModals.updateStreetCity);
                        modalUpdate.annexaFormly.model = {};
                        modalUpdate.annexaFormly.model.modal_body = modal.data;
                        modalUpdate.annexaFormly.options = {};

                        var saveModalUpdateParents = function(){
                        	var modalUpdateAux = this;
                            var model = angular.copy(modalUpdateAux.annexaFormly.model.modal_body);
                            model.city = ((model.city && model.city.id)?{id:model.city.id}:undefined);
                            model.state = ((model.state && model.state.id)?{id:model.state.id}:undefined);
                            model.country = ((model.country && model.country.id)?{id:model.country.id}:undefined);
                        	RestService.update('./api/territory/street_street/' + model.id, model).then(function (data) {
                        		modalUpdateAux.close();
                        		modal.close();
            	            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
            	            	$scope.viewElement(model.id);
            	            }).catch(function (error) {
            	        		DialogsFactory.error($filter('translate')('global.errors.errorStreet'));
            	            });
                        }
                        
                        AnnexaFormlyFactory.showModal('modalEditStreetCity', modalUpdate, saveModalUpdateParents, false);
                    }
                    
                    modal.printcountriesAndStatesAndStreetTypes = function(modelValue){
                    	if(modelValue && modelValue[modal.languageColumn]) {
                    		return modelValue[modal.languageColumn];
                    	}else{
                    		return '';
                    	}
                    }
                    modal.updateStreet = $scope.updateStreet;

                    AnnexaModalFactory.showModal('modalStreetEdit', modal);
                });
        }

        $scope.updateStreet = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);
            if( _.contains(['code','name','particles','streetType'], prop)){
	            if ( _.contains(['code','name','particles'], prop)) {
	                model[prop] = val;
	            } else if( _.contains(['streetType'], prop)){
	            	model[prop] = ((val && val.id)?{id:val.id}:undefined); 
	            }
	            if (model.city.log)
	            	model.city.log = undefined;
	            RestService.update('./api/territory/street_street/' + model.id, model).then(function (data) {
	            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
	            }).catch(function (error) {
	        		DialogsFactory.error($filter('translate')('global.errors.errorStreet'));
	            });
            }
        }
        
    }])
	.controller('TerritorialGroupTypeController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory',
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory) {
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.territorialGroupTypeNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewTerritorialGroupType', modal, $scope.saveTerritorialGroupType, false);
        }

        $scope.saveTerritorialGroupType = function (territorialGroupType) {
            var modal = this;

            var model = {
                language1: territorialGroupType.annexaFormly.model.modal_body.language1 ? territorialGroupType.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                language2: territorialGroupType.annexaFormly.model.modal_body.language2 ? territorialGroupType.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: territorialGroupType.annexaFormly.model.modal_body.language3 ? territorialGroupType.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                applicableTo: territorialGroupType.annexaFormly.model.modal_body.applicableTo ? territorialGroupType.annexaFormly.model.modal_body.applicableTo : undefined
            }

            RestService.insert('./api/territory/territorial_group_type', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    modal.close();
                }).catch(function (error) {
            		modal.alerts.push({ msg: $filter('translate')('global.errors.errorTerritorialGroupType')});
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('TerritorialGroupType', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = angular.copy(CommonAdminModals.territorialGroupTypeEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.applicableTo = angular.copy(GlobalDataFactory.applicableTo);
                    modal.printApplicableTo = function(modelValue){
                    	if(modelValue) {
                    		return (modelValue[modal.languageColumn]?$filter('translate')(modelValue[modal.languageColumn]):$filter('translate')($linq(modal.applicableTo).firstOrDefault(undefined, "x => x.id == '" + modelValue + "'")[modal.languageColumn]));
                    	}else{
                    		return '';
                    	}
                    }
                    modal.updateTerritorialGroupType = $scope.updateTerritorialGroupType;

                    AnnexaModalFactory.showModal('modalTerritorialGroupTypeEdit', modal);
                });
        }

        $scope.updateTerritorialGroupType = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);
            if(prop == 'applicableTo'){
            	model[prop] = ((val && val.id) ? val.id : undefined); 
            } else {
                model = val;
            }
            
            if (model.applicableTo && model.applicableTo.id) {
            	model.applicableTo = model.applicableTo.id;
            }
            
            RestService.update('./api/territory/territorial_group_type/' + model.id, model).then(function (data) {
            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
            }).catch(function (error) {
           		DialogsFactory.error($filter('translate')('global.errors.errorTerritorialGroupType'));
            });
        }
        
    }])
	.controller('TerritorialGroupController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory', 'TerritoryFactory',
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory, TerritoryFactory) {
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.territorialGroupNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewTerritorialGroup', modal, $scope.saveTerritorialGroup, false);
        }

        $scope.saveTerritorialGroup = function (territorialGroup) {
            var modal = this;

            var model = {
                language1: territorialGroup.annexaFormly.model.modal_body.language1 ? territorialGroup.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                language2: territorialGroup.annexaFormly.model.modal_body.language2 ? territorialGroup.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: territorialGroup.annexaFormly.model.modal_body.language3 ? territorialGroup.annexaFormly.model.modal_body.language3 : 'NotTranslated',
				territorialGroupType: ((territorialGroup.annexaFormly.model.modal_body.territorialGroupType && territorialGroup.annexaFormly.model.modal_body.territorialGroupType.id) ? {id:territorialGroup.annexaFormly.model.modal_body.territorialGroupType.id} : undefined)
            }

            RestService.insert('./api/territory/territorial_group', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    modal.close();
                }).catch(function (error) {
            		modal.alerts.push({ msg: $filter('translate')('global.errors.errorTerritorialGroup')});
            })
        }

		$scope.filter = [
        	{id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'},
        	{ id: 'territorialGroupType', type: 'text', order: 1, label: 'global.thirds.literals.territorialGroupType', callAux: true }
        ]

		$scope.additionalColumns = [
        	{ id: 'territorialGroupType.'+Language.getActiveColumn(), title: $filter('translate')('global.thirds.literals.territorialGroupType')}
        ]
    	
        $scope.viewElement = function (id) {
            RestService.findOne('TerritorialGroup', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = angular.copy(CommonAdminModals.territorialGroupEdit);
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.territorialGroupTypes = angular.copy(TerritoryFactory.territorialGroupTypes);
                    modal.printTerritorialGroupType = function(modelValue){
                    	if(modelValue && modelValue[modal.languageColumn]) {
                    		return modelValue[modal.languageColumn];
                    	}else{
                    		return '';
                    	}
                    }
                    modal.updateTerritorialGroup = $scope.updateTerritorialGroup;

                    AnnexaModalFactory.showModal('modalTerritorialGroupEdit', modal);
                });
        }

        $scope.updateTerritorialGroup = function(val, prop) {
        	var self = this;
            var model = angular.copy(this.data);
            if(_.contains(['territorialGroupType'], prop)){
            	model[prop] = ((val && val.id)?{id:val.id}:undefined); 
            } else {
                model = val;
            }
            
            RestService.update('./api/territory/territorial_group/' + model.id, model).then(function (data) {
            	$rootScope.$broadcast('annexaAdminBaseReloadTable');
            }).catch(function (error) {
           		if(error && error.data && error.data.message && error.data.message == 'Have related street city territorial groups'){
					DialogsFactory.error($filter('translate')('global.errors.checkUpdateTerritorialGroup'));
				}else{
					DialogsFactory.error($filter('translate')('global.errors.errorTerritorialGroup'));	
				}
            });
        }
        
    }])