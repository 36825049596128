angular
    .module('annexaApp')
	.controller('GenderController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'DialogsFactory',
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory, apiAdmin, DialogsFactory) {

			$scope.filter = [
				{ id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'}
			];

			$scope.newElement = function() {
				var modal = angular.copy(CommonAdminModals.genderNew);
				modal.annexaFormly.model = {};
				modal.annexaFormly.model.modal_body = {};
				modal.annexaFormly.options = {};
				
				AnnexaFormlyFactory.showModal('modalNewGender', modal, $scope.saveGender, false);
			}

			$scope.saveGender = function (gender) {
				var modal = this;
				
				var model = {
					language1: gender.annexaFormly.model.modal_body.language1 ? gender.annexaFormly.model.modal_body.language1 : 'NotTranslated',
					language2: gender.annexaFormly.model.modal_body.language2 ? gender.annexaFormly.model.modal_body.language2 : 'NotTranslated',
					language3: gender.annexaFormly.model.modal_body.language3 ? gender.annexaFormly.model.modal_body.language3 : 'NotTranslated'
				}
				
				RestService.insert('./api/thirds/gender', model).then(function () {
					$rootScope.$broadcast('annexaAdminBaseReloadTable'); 
					modal.close();
				}).catch(function (error) {
					modal.alerts.push({ msg: $filter('translate')('global.errors.errorGender')});
				})
			}

			$scope.viewElement = function (id) {
				RestService.findOne('Gender', id).then(function(data) {
					var decodeddata = JSOG.decode(data.data);
					
					var modal = angular.copy(CommonAdminModals.genderEdit);
					modal.alerts = [];
					modal.data = decodeddata;
					modal.languageColumn = Language.getActiveColumn();
					modal.updateGender = $scope.updateGender;
					
					AnnexaModalFactory.showModal('modalGenderEdit', modal);
				});
			}

			$scope.updateGender = function(val, prop) {
				var self = this;
				var model = angular.copy(this.data);
				
				RestService.update('./api/thirds/gender/' + model.id, model).then(function (data) {
					$rootScope.$broadcast('annexaAdminBaseReloadTable');
				}).catch(function (error) {
					DialogsFactory.error($filter('translate')('global.errors.errorGender'));
				});
			}
	}])