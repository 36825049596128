angular
    .module('annexaApp')
    .controller('RemittancesTypesController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'DialogsFactory', 'CommonService', 'globalModals', '$http', 
    	function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory,DialogsFactory, CommonService, globalModals, $http) {

    	$scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
            { id: 'code', type: 'text', order: 2, label: 'global.literals.code' }
        ];

        $scope.additionalColumns = [
            { id: 'code', width: '100px', title: $filter('translate')('global.literals.code') }
        ];
    	
        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.remittanceNew);
            AnnexaFormlyFactory.showModal('modalNewRemittanceType', modal, $scope.saveRemittanceType, false);
        }

        $scope.saveRemittanceType = function (remittanceType) {
            var modal = this;

            var model = {
                language1: remittanceType.annexaFormly.model.modal_body.language1,
                language2: remittanceType.annexaFormly.model.modal_body.language2 ? remittanceType.annexaFormly.model.modal_body.language2 : 'NotTranslated',
                language3: remittanceType.annexaFormly.model.modal_body.language3 ? remittanceType.annexaFormly.model.modal_body.language3 : 'NotTranslated',
                code: remittanceType.annexaFormly.model.modal_body.code
            }

            if (remittanceType.annexaFormly.model.modal_body.idDocumentManagerNotificationsListDocument && remittanceType.annexaFormly.model.modal_body.idDocumentManagerNotificationsListDocument.length > 0) {
                model.idDocumentManagerNotificationsListDocument = JSON.stringify(remittanceType.annexaFormly.model.modal_body.idDocumentManagerNotificationsListDocument[0]);
            }
            
            if (remittanceType.annexaFormly.model.modal_body.idDocumentManagerNotificationsListElementDocument && remittanceType.annexaFormly.model.modal_body.idDocumentManagerNotificationsListElementDocument.length > 0) {
                model.idDocumentManagerNotificationsListElementDocument = JSON.stringify(remittanceType.annexaFormly.model.modal_body.idDocumentManagerNotificationsListElementDocument[0]);
            }
            
            if (remittanceType.annexaFormly.model.modal_body.idDocumentManagerWindowInfo && remittanceType.annexaFormly.model.modal_body.idDocumentManagerWindowInfo.length > 0) {
                model.idDocumentManagerWindowInfo = JSON.stringify(remittanceType.annexaFormly.model.modal_body.idDocumentManagerWindowInfo[0]);
            }

            RestService.insert('./api/reg/remittance_type', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    if(GlobalDataFactory.remittanceTypes){
                    	GlobalDataFactory.remittanceTypes.length = 0;
                    }else{
                    	GlobalDataFactory.remittanceTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RemittanceType')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=RemittanceType')
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.close();
                }).catch(function (error) {
                	modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
                })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('RemittanceType', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);
                    var modal = CommonAdminModals.remittanceTypeEdit;
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateRemittanceType = $scope.updateRemittanceType;
                    modal.notificationsListDocument = "notificationsListDocument";
                    modal.notificationsListElementDocument = "notificationsListElementDocument";
                    modal.windowInfo = "windowInfo";
                    modal.replaceDocumentModal = $scope.replaceDocumentModal;
                    modal.editOnline = $scope.editOnline;
                    modal.download = $scope.download;
                    $scope.$on('newDocumentAdded', function(event, args) {
	               		 if(args.item) {
	               			 modal.data.nameNotificationsListDocument = args.item.nameNotificationsListDocument;
	               			 modal.data.idDocumentManagerNotificationsListDocument = args.item.idDocumentManagerNotificationsListDocument;
	               			 modal.data.nameNotificationsListElementDocument = args.item.nameNotificationsListElementDocument;
	               			 modal.data.idDocumentManagerNotificationsListElementDocument = args.item.idDocumentManagerNotificationsListElementDocument;
	               			 modal.data.nameWindowInfo = args.item.nameWindowInfo;
	               			 modal.data.idDocumentManagerWindowInfo = args.item.idDocumentManagerWindowInfo;
	               		 }
                    });
                    AnnexaModalFactory.showModal('modalRemittanceTypeEdit', modal);
                });
        }

        $scope.replaceDocumentModal = function(template, callerModal, typeFile) {
            var replaceDocument = function() {
                if(this.annexaFormly.form.$valid) {
                    $rootScope.loading(true)
                    var self = this;
                    var data = this.annexaFormly.model.modal_body;

                    RestService.update('./api/reg/remittance_type/' + template.id + '/' +typeFile+ '/file', JSON.stringify(data.file[0]))
                        .then(function(data) {
                        	$rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                            if(GlobalDataFactory.remittanceTypes){
                            	GlobalDataFactory.remittanceTypes.length = 0;
                            }else{
                            	GlobalDataFactory.remittanceTypes = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RemittanceType')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=RemittanceType')
                            }
                            GlobalDataFactory.loadGlobalData();
                            $rootScope.loading(false);
                            self.close();
                            $rootScope.$broadcast('newDocumentAdded', { item: JSOG.decode(data) });
                        }).catch(function(error) {
                            $rootScope.loading(false);
                            console.log(error);
                        })
                }
            }

            var modal = globalModals.uploadFileBase64;
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            AnnexaFormlyFactory.showModal('modalUploadFile', modal, replaceDocument, false);
        }
        
        $scope.editOnline = function (idDocumentManager) {
            CommonService.getEditOnlineUrl(idDocumentManager).then(function(eou) {
                if(eou != null && eou != undefined && eou != ''){
                    window.location.href=eou;
                }
            }).catch(function(){});
        }
        
        $scope.download = function(idDocumentManager){
        	$rootScope.loading(true);
            $http.get("./api/repodocs/downloadAlfDoc?id="+idDocumentManager, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    var retDownload = {
                        file: undefined,
                        fileName: undefined,
                        linkElement: undefined,
                        clickEvent: undefined
                    }
                    headers = headers();
                    var contentType = headers['content-type'];
                    retDownload.file = new Blob([data], {type: contentType});
                    retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
                    try {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                        } else {
                            retDownload.linkElement = document.createElement('a');
                            var url = window.URL.createObjectURL(retDownload.file);
                            retDownload.linkElement.setAttribute('href', url);
                            retDownload.linkElement.setAttribute("download", retDownload.fileName);
                            retDownload.clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                        }
                        $rootScope.loading(false);
                    } catch (e) {
                        $rootScope.loading(false);
                    }
                }).error(function (error) {
                    $rootScope.loading(false);
            });
        }
        
        $scope.updateRemittanceType = function(val, prop) {
			var self = this;
			var statusesAux = this.data.statuses;
            var modelAux = angular.copy(this.data);
			var model = angular.copy(this.data);
			if(prop == 'code') {
                model.code = val;
            } else {
                model = val;
            }
			
            RestService.update('./api/reg/remittance_type/' + model.id, model)
                .then(function (data) {
                   	self.data = data;
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if(GlobalDataFactory.remittanceTypes){
                    	GlobalDataFactory.remittanceTypes.length = 0;
                    }else{
                    	GlobalDataFactory.remittanceTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=RemittanceType')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=RemittanceType')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
					self.data = modelAux;
					self.alerts.push({ msg: $filter('translate')('global.errors.unknownUpdate') })
			});
        }
    }])